<template>
  <div class="d-flex flex-column ga-2">
    <div 
      v-for="(o,i) in options"
      :key="i"
      class="border-md border-surface-light rounded pa-3 border-opacity-100 position-relative"
      style="cursor: pointer;"
      :class="{
        'border-surface-light': value !== o.value,
        [`border-${selectedColor}`]: value === o.value,
      }"
      @click="value = o.value"
    >
      <slot :option="o"></slot>
      <v-chip v-if="o.chip" :color="selectedColor" rounded="md" variant="flat" class="position-absolute top-0 right-0 mr-n3 mt-n4" density="compact">
        {{ o.chip }}
      </v-chip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  modelValue: null,
  options: {
    type: Array,
    default: () => []
  },
  selectedColor: {
    type: String,
    default: 'primary'
  }
})

const emit = defineEmits(['update:modelValue'])

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

</script>