import { defineStore } from 'pinia';
import { computed, ref, shallowRef, watch } from 'vue';
import { usePortfolioStore } from './portfolioStore';
import { i18n } from '@/plugins/i18n';
import { useRouter } from 'vue-router';
import { useAuthStore } from './authStore';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { useLazyFetch } from '@/utils/reactivity.utils';
import vuetify from '@/plugins/vuetify';

const { t, locale } = i18n.global;

export const useAppStore = defineStore('app', () => {
  const authStore = useAuthStore()
  const theme = ref(authStore.getUserPreference('theme') || window.localStorage.getItem('theme') || 'MainTheme')

  const portfoliosMenuState = ref(false);
  const toolsMenuState = ref(false);
  const hideBalances = ref(false);

  const notice = ref<{
    visible: boolean,
    type: 'info' | 'success' | 'warning' | 'error',
    message: string,
    timeout?: number,
    title?: string,
    cb?: () => void,
    button?: { label: string }
  }>({
    visible: false,
    type: 'info',
    message: '',
    timeout: undefined,
    title: undefined,
    cb: undefined,
    button: undefined
  })

  const portfolioStore = usePortfolioStore()
  const router = useRouter()

  const translations = computed(() => {  
    return {
      dashboard: t('portfolio.dashboard'),
      overview: t('portfolio.overview'),
      settings: t('portfolio.settings'),
      accounts: t('portfolio.accounts'),
      reports: t('portfolio.reports'),
      instruments: t('portfolio.instruments'),
      notes: t('portfolio.notes'),
      tools: t('portfolio.tools'),
    }
  })

  const sendEventToApp = () => {
    window.postMessage(JSON.stringify({
      event: 'settings',
      theme: vuetify.theme.current.value.dark ? 'dark' : 'light',
    }), '*');
  }

  const horizontalItems = computed(() => {  
    const portfolioId = portfolioStore.selectedPortfolioId
  
    if (!portfolioId) {
      return []
    }

    return [
      {
        title: translations.value.dashboard,
        icon: 'mdi-view-dashboard',
        to: {
          name: 'dashboard'
        },
        id: 'portfolio-dashboard'
      },
      {
        title: translations.value.overview,
        icon: 'mdi-briefcase-variant',
        to: {
          name: 'portfolio',
          params: {
            portfolioId: portfolioId
          }
        },
        id: 'portfolio-portfolio'
      },
      {
        title: translations.value.reports,
        icon: 'mdi-chart-areaspline',
        to: {
          name: 'report-dashboard',
          params: {
            portfolioId: portfolioId
          }
        },
        id: 'portfolio-reports'
      },
      {
        title: translations.value.accounts,
        icon: 'mdi-bank',
        to: {
          name: 'portfolio-accounts',
          params: {
            portfolioId: portfolioId
          }
        },
        id: 'portfolio-accounts',
      },
      {
        title: translations.value.instruments,
        icon: 'mdi-clipboard-text-outline',
        to: {
          name: 'portfolio-instruments',
          params: {
            portfolioId: portfolioId
          }
        },
        id: 'portfolio-instruments',
      },
      {
        title: translations.value.tools,
        icon: 'mdi-tools',
        to: '#',
        id: 'portfolio-tools',
        hidden: false,
        items: [{
          title: translations.value.notes,
          icon: 'mdi-note-edit',
          to: {
            name: 'notes',
            params: {
              portfolioId: portfolioId
            }
          },
          id: 'notes'
        }]
      },
      {
        title: translations.value.settings,
        icon: 'mdi-cog',
        to: {
          name: 'portfolio-settings',
          params: {
            portfolioId: portfolioId
          }
        },
        id: 'portfolio-settings',
        hidden: !portfolioStore.selectedPortfolio?.canEdit
      },
    ].filter((item) => !item.hidden)
  });
  
  
  const dynamicTabs = shallowRef(null)

  const hideNotice = () => {
    notice.value.visible = false
  }

  const showNotice = (message: string, type: 'info' | 'success' | 'warning' | 'error' = 'info', opts: any = {}) => {
    notice.value.visible = true
    notice.value.message = message
    notice.value.type = type
    notice.value.timeout = opts.timeout ?? undefined
    notice.value.title = opts.title ?? undefined
    notice.value.cb = opts.cb ?? undefined
    notice.value.button = opts.button ?? undefined
  }

  const setTabs = (tabs: any) => {
    dynamicTabs.value = tabs.value
  }

  const clearTabs = () => {
    dynamicTabs.value = null
  }

  const tabs = computed(() => {
    return dynamicTabs.value ?? horizontalItems.value
  })

  const setTheme = (themeName: string) => {
    window.localStorage.setItem('theme', themeName)
    theme.value = themeName
    authStore.setUserPreference('theme', themeName)
    sendEventToApp()
  }

  const openPortfoliosMenu = () => {
    portfoliosMenuState.value = true
  }

  const openToolsMenu = () => {
    toolsMenuState.value = true
  }

  const hidePortfoliosMenu = () => {
    portfoliosMenuState.value = false
  }

  const siteConfig = useLazyFetch<{alerts:any[]} | null>(
    () => fetchWrapper.get('/api/v1/site/config'), null)

  watch(() => router.currentRoute.value.query.lang, (lang: any) => {
    if (lang && ['et', 'en'].includes(lang) && !authStore.user) {
      locale.value = lang
    }
  }, { immediate: true });

  watch(locale, () => {
    if (localStorage.getItem('locale') !== locale.value) {
      authStore.setUserPreference('locale', locale.value)
    }
    localStorage.setItem('locale', locale.value)
  }, { immediate: true });

  return {
    siteConfig,
    portfoliosMenuState,
    toolsMenuState,
    theme,
    hideBalances,
    tabs,
    notice,
    hideNotice,
    showNotice,
    setTabs,
    clearTabs,
    setTheme,
    openPortfoliosMenu,
    hidePortfoliosMenu,
    openToolsMenu,
    sendEventToApp,
  }
})

window.addEventListener('app-event', function(e: any) {
  useAppStore().sendEventToApp();
});
