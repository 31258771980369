<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useInvestmentStore } from '@/stores/investmentStore';
import { computed } from 'vue';
import { useLoanStore } from '@/stores/loanAccountStore';
import { watch } from 'vue';

const investmentStore = useInvestmentStore();
const loanStore = useLoanStore();

const investment = computed(() => investmentStore.investment);

watch (() => investment.value?.loanHoldingId, async (loanId) => {
  if (loanId) {
    await loanStore.getLoanAccountTransactions(loanId)
  } else {
    loanStore.transactions.value = []
  }
}, { immediate: true })

const createLoan = async () => {
  try {
    await investmentStore.createLoan(investment.value.id);
    await investmentStore.get(investment.value.id, true);
  } catch (_e) {
    // ignore
  }
}
</script>

<template>
  <v-alert type="warning" color="orange-lighten-3" :title="$t('investment_page.loan.create_title')" if="portfolio?.canEdit">
    <div class="d-flex flex-column ga-4">
      <span>{{ $t('investment_page.loan.no_loan') }}</span>
      <v-btn @click="createLoan" variant="flat" color="orange" :size="$vuetify.display.xs ? 'small' : 'default'">
        {{ $t('investment_page.loan.create_loan') }}
      </v-btn>
    </div>
  </v-alert>
</template>