<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { computed } from 'vue';
const appStore = useAppStore();

const notice = computed(() => {
  return appStore.notice;
})

const hideNotice = () => {
  appStore.hideNotice();
}
</script>

<template>
  <v-snackbar
    :color="notice.type"
    variant="flat"
    rounded="md"
    location="top right"
    v-model="notice.visible"
    :close-on-back="false"
    :timeout="notice.timeout"
    :vertical="!!notice.button"
  >
    <div class="text-subtitle-1 pb-2" v-if="notice.title">{{ notice.title }}</div>
    {{ notice.message }}
    <template v-slot:actions v-if="!notice.button">
      <v-btn color="white" variant="text" @click="hideNotice">
        <v-icon size="20">mdi-close</v-icon>
      </v-btn>
    </template>
    <template v-slot:actions v-if="!!notice.button">
      <div class="d-flex justify-end ga-2">
        <v-btn
          color="white"
          variant="text"
          @click="hideNotice"
        >
          {{ $t('label.close') }}
        </v-btn>
        <v-btn
          color="primary"
          variant="flat"
          @click="notice.cb && notice.cb()"
        >
          {{ notice.button.label}}
        </v-btn>
      </div>
    </template>
  </v-snackbar>
</template>