<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { bankCountries } from '~/accounts.config';
import { fieldDesign } from '@/const';

const emit = defineEmits(['update:modelValue', 'update:modelObject']);

const props = defineProps({
  modelValue: null,
  modelObject: null,
  disabled: {
    type: Boolean,
    default: false
  },
  fieldDesign: {
    type: Object,
    default: () => fieldDesign
  },
  label: {
    type: String,
    default: () => 'Country'
  },
  returnObject: {
    type: Boolean,
    default: true
  }
});

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const { t } = useI18n();

const items = computed(() => {
  return Object.values(bankCountries).map((country: any) => {
    return {
      id: country.key,
      name: country.name,
      logo: country.flag,
    }
  });
});

const customFilter = (itemString: string, queryText: string, item: any) => {
  const textOne = item.raw?.name?.toLowerCase() || '';
  const textTwo = item.raw?.id?.toLowerCase() || '';

  return textOne.indexOf(queryText.toLowerCase()) > -1 || textTwo.indexOf(queryText.toLowerCase()) > -1;
}
</script>

<template>
  <v-autocomplete
    v-bind="(fieldDesign as any)"
    v-model="value"
    :items="items"
    item-title="name"
    item-value="id"
    :label="label"
    :return-object="returnObject"
    clearable
    :disabled="disabled"
    :multiple="false"
    :custom-filter="customFilter"
    clear-on-select
  >
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :title="''" density="compact">
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
        <template v-slot:prepend>
          <div v-if="item.raw.logo.startsWith('https')" class="d-flex align-center mr-2" style="width:24px;height:24px;">
            <v-img :src="item.raw.logo" />
          </div>
          <div v-else class="d-flex align-center mr-2" style="width:24px;height:24px;" v-html="item.raw.logo" />
        </template>
      </v-list-item>
    </template>
    <template v-slot:prepend-inner v-if="value">
      <div v-if="value.logo.startsWith('https')" class="d-flex align-center mr-2" style="width:24px;height:24px;">
        <v-img :src="value.logo" />
      </div>
      <div v-else class="d-flex align-center mr-2" style="width:24px;height:24px;" v-html="value.logo" />
    </template>
  </v-autocomplete>
</template>