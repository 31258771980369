<template>
  <DisplayCard
    v-if="isFutureIncomeLoading || futureIncome?.transactions?.length"
    :title="$t('label.future_cashflow')"
    class="overflow-hidden"
    :color="cardColor"
  >
    <template v-slot:actions>
      <div class="d-flex ga-2 align-center" data-html2canvas-ignore="true">
        <v-btn :color="buttonColor" variant="tonal" :to="{
          name: 'future-cashflow-report',
          params: {
            portfolioId: portfolioStore.selectedPortfolioId || 0
          },
          query: {
            period: 'next_6_months',
            interval: 'month',
            selectedInvestmentsIds: selectedInvestmentsIds.join(','),
          }
        }">{{ $t('label.view_report') }}</v-btn>
        <slot name="configuration" />
      </div>
    </template>
    <v-row>
      <v-col cols="12" :md="flavour === 'dashboard' ? 7 : 8">
        <BarChart
          class="mt-2 ml-2"
          :values="chartData"
          :categories="chartCategories"
          :isLoading="isLoading"
          stacking="normal"
          v-if="!isFutureIncomeLoading"
        />
        <div v-else class="ma-4">
          <v-skeleton-loader :boilerplate="!isFutureIncomeLoading" height="280" style="width:100%;" type="ossein" />
        </div>
      </v-col>
      <v-col cols="12" :md="flavour === 'dashboard' ? 5 : 4">
        <v-list v-if="!isFutureIncomeLoading">
          <v-list-item v-for="tx in transactions">
            <template v-slot:prepend>
              <Investment :size="40" :holding="tx.holding" flavour="only-logo" />
            </template>
            <template v-slot:title>
              <Investment v-if="flavour === 'dashboard'" :holding="tx.holding" flavour="name" />
              <span v-else>{{ dateFormatter(tx.dateTransactionAt) }}</span>
            </template>
            <template v-slot:subtitle v-if="flavour === 'dashboard'">
              <div class="d-flex align-center ga-2">
                {{ dateFormatter(tx.dateTransactionAt) }}
              </div>
            </template>
            <template v-slot:append>
              <div class="d-flex flex-column align-end">
                <PriceCell
                  :value="tx.totalValue"
                  :transactionType="tx.transactionType"
                  :currency-code="tx.currencyCode"
                  :maximumFractionDigits="2"
                  is-total-value
                />
                <TransactionTypeCell 
                  :value="tx.transactionType" 
                  :transaction="tx" 
                  is-cashflow 
                  hide-icon
                  :upper-case="false"
                  class="text-body-1"
                />
              </div>
            </template>
          </v-list-item>
        </v-list>
        <div v-else class="ma-4">
          <v-skeleton-loader
            :boilerplate="!isFutureIncomeLoading"
            style="width:100%;"
            type="list-item-avatar, list-item-avatar, list-item-avatar, list-item-avatar"
          />
        </div>
      </v-col>
    </v-row>
  </DisplayCard>
</template>

<script setup lang="ts">
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useFutureCashflowReport } from '@/utils/chart.utils';
import { computed } from 'vue';
import { dateFormatter } from '@/const';
import { useReportStore } from '@/stores/reportStore';
import BarChart from '@/components/BarChart.vue';
import TransactionTypeCell from '@/components/table/TransactionTypeCell.vue';
import PriceCell from '@/components/table/PriceCell.vue';
import { useInvestment } from '@/utils/instrument.utils';
import { HoldingType } from '~/enums';
import { useLazyFetch } from '@/utils/reactivity.utils';

const portfolioStore = usePortfolioStore();
const reportStore = useReportStore();

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  holding: {
    required: false
  },
  cardColor: {
    type: String,
    default: undefined
  },
  buttonColor: {
    type: String,
    default: 'primary'
  },
  flavour: {
    type: String,
    default: 'dashboard'
  }
});

const {
  data: futureIncome,
  loading: isFutureIncomeLoading,
  error
} = useLazyFetch(async () => {
  if (!portfolioStore.selectedPortfolioId) return;
  const params = {
    period: 'next_6_months',
    interval: 'month',
    page: 1,
    pageSize: 7
  };

  let portfolioId = portfolioStore.selectedPortfolioId;
  // @ts-expect-error
  let investmentId = props.holding?.id;

  return !investmentId
    ? reportStore.getFutureFashflowReport(portfolioId, params)
    : reportStore.getFutureFashflowReportOfInvestment(portfolioId, investmentId, params)
}, [])

const { chartData, chartCategories } = useFutureCashflowReport(futureIncome);

const transactions = computed(() => (futureIncome.value.transactions || []).map((tx: any) => {
  const { codeValue, logoValue } = useInvestment(
    computed(() => tx.holding),
    computed(() => undefined),
    computed(() => undefined),
  )

  return {
    ...tx,
    codeValue: codeValue.value,
    logoValue: logoValue.value,
  }
}));

const selectedInvestmentsIds = computed(() => {
  if (!props.holding) {
    return [];
  }
  const type = props.holding.type;
  if (HoldingType.Loan === type) {
    return [`L${props.holding.id}`];
  } else if (HoldingType.Investment === type) {
    return [`I${props.holding.instrumentId}`];
  }
  return []
});
</script>