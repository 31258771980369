import FullLayout from '@/layouts/full/FullLayout.vue';

const TicketRoutes = {
  path: '/ticket',
  meta: {
    requiresAuth: true
  },
  component: FullLayout,
  children: [
    {
      component: () => import('@/views/ticket/Layout.vue'),
      name: 'ticket-layout',
      path: '',
      children: [
        {
          name: 'support',
          path: 'support',
          component: () => import('@/views/ticket/Support.vue'),
          meta: {
            title: 'Support'
          }
        },
        {
          name: 'suggestions',
          path: 'suggestions',
          component: () => import('@/views/ticket/ListSuggestions.vue'),
          meta: {
            title: 'Suggestions'
          }
        },
        {
          name: 'changelog',
          path: 'changelog',
          component: () => import('@/views/ticket/Changelog.vue'),
          meta: {
            title: 'Changelog'
          }
        },
        {
          name: 'help',
          path: 'help',
          // @ts-expect-error
          beforeEnter: (_to, _from, _next) => {
            window.open('https://portfellow.com/help/', '_blank')?.focus();
          }
        },
        {
          name: 'discord',
          path: 'discord',
          // @ts-expect-error
          beforeEnter: (_to, _from, _next) => {
            window.open('https://discord.gg/FNvcq8U2X2', '_blank')?.focus();
          }
        },
      ]
    }
  ]
};

export default TicketRoutes;
