<script setup lang="ts">
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/authStore';
import { onUnmounted, watch } from 'vue';
import { onMounted } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { FeatureFlag } from '~/enums';

const { t } = useI18n();
const appStore = useAppStore();
const authStore = useAuthStore();

const tabs = computed(() => [
  {
    to: {name: 'user-account-settings'},
    title: t('settings_page.profile_tab'),
    description: t('settings_page.profile_tab_description'),
    icon: 'mdi-account-outline',
    visible: true
  },
  {
    to: {name: 'security'},
    title: t('settings_page.security_tab'),
    description: t('settings_page.security_tab_description'),
    icon: 'mdi-lock',
    visible: true
  },
  {
    to: {name: 'permissions'},
    title: t('settings_page.permissions_tab'),
    description: t('settings_page.permissions_tab_description'),
    icon: 'mdi-account-group-outline',
    visible: true
  },
  {
    to: {name: 'plans-and-billing-settings'},
    title: t('settings_page.plans_and_billing_tab'),
    description: t('settings_page.plans_and_billing_tab_description'),
    icon: 'mdi-credit-card-outline',
    visible: true
  },
  {
    to: {name: 'import-links'},
    title: t('settings_page.import_links_tab'),
    description: '',
    icon: 'mdi-link-variant',
    visible: true
  },
  {
    to: {name: 'invite-friends'},
    title: t('settings_page.invite_friends_tab'),
    description: t('settings_page.invite_friends_description'),
    icon: 'mdi-gift',
    visible: authStore.isEnabled(FeatureFlag.InviteFriends)
  },
].filter(tab => tab.visible));

watch(() => tabs.value, () => {
  appStore.setTabs(tabs);
}, { immediate: true });

onUnmounted(() => {
  appStore.clearTabs();
});
</script>

<template>
  <router-view />
</template>
