<template>
  <Dialog :title="$t('account_page.link.confirm_title')" v-model:model-visible="visible" color="primary">
    <div class="text-body-1 d-flex flex-column ga-4">
      <p v-html="$t('account_page.link.confirm_description_1')" />
      <p v-html="$t('account_page.link.confirm_description_2')" />
      <p v-html="$t('account_page.link.confirm_description_3')" />
      <v-alert color="amber-lighten-3" type="warning" dense class="text-body-1">
        <span v-html="$t('account_page.link.confirm_description_4')" />
      </v-alert>
    </div>
    <v-divider class="my-4"></v-divider>
    <div class="d-flex ga-2 justify-space-between">
      <v-btn variant="tonal" color="error" @click="$emit('close')">
        {{ $t('account_page.cancel') }}
      </v-btn>
      <v-btn color="primary" @click="$emit('confirm')" :loading="isLoading">
        {{ $t('account_page.confirm') }}
      </v-btn>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  modelVisible: {
    type: Boolean,
    required: true
  },
  isLoading: {
    type: Boolean,
    required: false
  }
});

const emit = defineEmits(['close', 'update:modelVisible', 'confirm']);

const visible = computed({
  get: () => props.modelVisible,
  set: (value) => {
    emit('update:modelVisible', value)
  }
});
</script>