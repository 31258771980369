import { Static, Type } from '@sinclair/typebox'
import { HoldingType, InstrumentType, PerformancePeriod, ReportGroupingType } from './enums'
import { currencyCodeType } from './common.schema'

export const PerformanceItemDto = Type.Object({
  method: Type.Optional(Type.String()),
  isAnnualized: Type.Boolean(),
  capitalGain: Type.Number({ examples: [1000], default: 0}),
  capitalReturn: Type.Number({default: 0}),
  capitalReturnAnnualized: Type.Number({default: 0}),
  capitalReturnCummulative: Type.Number({default: 0}),
  currencyGain: Type.Number({ examples: [1000], default: 0}),
  currencyReturn: Type.Number({default: 0}),
  currencyReturnAnnualized: Type.Number({default: 0}),
  currencyReturnCummulative: Type.Number({default: 0}),
  cashflowGain: Type.Number({ examples: [1000], default: 0}),
  cashflowReturn: Type.Number({default: 0}),
  cashflowReturnAnnualized: Type.Number({default: 0}),
  cashflowReturnCummulative: Type.Number({default: 0}),
  feesGain: Type.Number({ examples: [1000], default: 0}),
  feesReturn: Type.Number({default: 0}),
  feesReturnAnnualized: Type.Number({default: 0}),
  feesReturnCummulative: Type.Number({default: 0}),
  totalGain: Type.Number({ examples: [1000], default: 0}),
  totalReturn: Type.Number({default: 0}),
  totalReturnAnnualized: Type.Number({default: 0}),
  totalReturnCummulative: Type.Number({default: 0}),
  totalReturnAbsolute: Type.Optional(Type.Number()),
  timeWeightedReturn: Type.Optional(Type.Number()),
})

export const SummaryItem = Type.Object({
  id: Type.Integer(),
  eid: Type.Optional(Type.String()),
  isPartial: Type.Boolean(),
  name: Type.String({ examples: ['Investments'], default: 'Investments'}),
  code: Type.String(),
  type: Type.Enum(HoldingType),
  instrumentType: Type.Enum(InstrumentType),
  logo: Type.Optional(Type.String()),
  currencyCode: currencyCodeType,
  baseCurrencyCode: currencyCodeType,
  relatedHoldingId: Type.Optional(Type.Union([Type.Integer(), Type.Null()])),
  price: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
  priceDate: Type.String({ format: 'date-time', examples: ['2021-01-01T00:00:00.000Z'], default: '2021-01-01T00:00:00.000Z'}),
  exchangeRate: Type.Number({ examples: [1], default: 1}),
  quantity: Type.Number({ examples: [10], default: 10}),
  value: Type.Number({ examples: [10000], default: 10000}),
  maxValue: Type.Optional(Type.Number()),
  valueInOriginalCurrency: Type.Number({ examples: [10000], default: 10000}),
  costBase: Type.Number({ examples: [10000], default: 10000}),
  costBaseInBaseCurrency: Type.Number({ examples: [10000], default: 10000}),
  startDate: Type.String({ format: 'date', examples: ['2021-01-01'], default: '2021-01-01'}),
  endDate: Type.String({ format: 'date', examples: ['2021-01-01'], default: '2021-01-01'}),
  openAndClosedPositions: PerformanceItemDto,
  percentage: Type.Number(),
  costBasePercentage: Type.Number(),
  portfolios: Type.Array(Type.Object({
    portfolioId: Type.Integer(),
    name: Type.String()
  })),
  consolidate: Type.String()
})

export const SummaryItemDto = Type.Intersect([
  SummaryItem,
  Type.Object({
    consolidation: Type.Array(SummaryItem)
  })
])

export const SummaryGroupDto = Type.Recursive(This => Type.Object({
  name: Type.String({ examples: ['Investments'], default: 'Investments'}),
  key: Type.String(),
  value: Type.Number({ examples: [10000], default: 10000}),
  maxValue: Type.Optional(Type.Number()),
  costBaseInBaseCurrency: Type.Number({ examples: [10000], default: 10000}),
  openAndClosedPositions: PerformanceItemDto,
  items: Type.Array(SummaryItemDto),
  percentage: Type.Number(),
  costBasePercentage: Type.Number(),
  groups: Type.Array(This),
  isPartial: Type.Boolean()
}))

export const SummaryDto = Type.Object({
  portfolioId: Type.Integer(),
  currencyCode: currencyCodeType,
  baseCurrencyCode: currencyCodeType,
  value: Type.Number({ examples: [10000], default: 10000}),
  costBaseInBaseCurrency: Type.Number({ examples: [10000], default: 10000}),
  groups: Type.Array(SummaryGroupDto),
  openAndClosedPositions: PerformanceItemDto,
  isPartial: Type.Boolean()
})

export type SummaryItemDtoType = Static<typeof SummaryItemDto>
export type SummaryDtoType = Static<typeof SummaryDto>
export type SummaryGroupDtoType = Static<typeof SummaryGroupDto>
export type PerformanceItemDtoType = Static<typeof PerformanceItemDto>

export const GetAccountSummaryItemSchema = {
  tags: ['account'],
  params: Type.Object({
    id: Type.Integer()
  }),
  response: {
    200: SummaryDto
  }
}

export const GetInvestmentSummaryItemSchema = {
  tags: ['investment'],
  params: Type.Object({
    id: Type.Integer()
  }),
  response: {
    200: SummaryDto
  }
}

export const GetSummarySchema = {
  tags: ['portfolio'],
  params: Type.Object({
    id: Type.Integer()
  }),
  querystring: Type.Object({
    period: Type.Enum(PerformancePeriod),
    groupBy: Type.Union([Type.Enum(ReportGroupingType), Type.Number()]),
  }),
  response: {
    200: SummaryDto
  }
}