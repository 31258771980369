<script setup lang="ts">
import { useAccountStore } from '@/stores/accountStore';
import { ref } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { dateTimeFormatter, currencyFormatter} from '@/const';
import { HoldingType, TrackingType } from '~/enums';
import { CoinEuroIcon } from 'vue-tabler-icons';
import StatsCard from '@/components/shared/StatsCard.vue';
import PerformanceBar from '@/components/PerformanceBar.vue';
import { useI18n } from 'vue-i18n';
import DisplayCard from '@/components/shared/DisplayCard.vue';
import DateCell from '@/components/table/DateCell.vue';
import TransactionTypeCell from '@/components/table/TransactionTypeCell.vue';
import PriceCell from '@/components/table/PriceCell.vue';
import NotesModule from './../investment/NotesModule.vue';
import LinkInfo from './LinkInfo.vue';
import type { AccountDtoType, CashAccountTransactionDtoType, CashAccountTransactionsDtoType } from '~/cash-account.schema';
import { useFilters, usePagination, usePerformanceFilter } from '@/filter.helper';
import AddNewCurrencyForm from './AddNewCurrencyForm.vue';
import BalanceModule from './BalanceModule.vue';
import { useAccountLink } from '@/utils/account.utils';
import type { Ref } from 'vue';
import HideBalancesButton from '@/components/HideBalancesButton.vue';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useLazyFetch } from '@/utils/reactivity.utils';
import { whenever } from '@vueuse/core';

const router = useRouter();
const accountStore = useAccountStore();
const portfolioStore = usePortfolioStore();
const { t } = useI18n();

const portfolio = computed(() => portfolioStore.activePortfolio);
const linkInfoRef = ref(null);
const showOpenAndClosedPositions = ref(true);
const showMonetaryValue = ref(true);
const { summaryPeriod, summaryRange } = usePerformanceFilter()
const showAddNewCurrencyModal = ref(false);
const account = computed(() => accountStore.account) as Ref<AccountDtoType>;
const parentAccount = computed(() => accountStore.parentAccount);
const { page, perPage, perPageOptions } = usePagination();
const balanceForm = ref(null);
const { canBeLinked } = useAccountLink(account);
const selected = ref([]);
const deleteLoading = ref(false);

const { updateRoute } = useFilters([{
  key: 'P',
  ref: page,
  type: 'int',
}, {
  key: 'PS',
  ref: perPage,
  type: 'int',
}])

const {
  data: transactions,
  refresh: refreshTrasactions,
  loading: transactionsLoading,
} = useLazyFetch<CashAccountTransactionsDtoType>(async () => {
  if (!accountStore.accountId) return {} as CashAccountTransactionsDtoType;
  return await accountStore.getCashAccountTransactions(accountStore.accountId, {
    limit: perPage.value,
    offset: (page.value - 1) * perPage.value,
  })
}, {} as CashAccountTransactionsDtoType)

whenever(transactions, () => {
  selected.value = [];
  updateRoute();
})

const {
  data: summary
} = useLazyFetch(async () => {
  if (!accountStore.accountId) return {};
  return await accountStore.getAccountSummary(accountStore.accountId, summaryPeriod.value, summaryRange.value);
}, {})

const headers = ref([{
  title: t('label.date'),
  align: 'start',
  sortable: false,
  key: 'dateTransactionAt',
}, {
  title: t('label.transaction_type_short'),
  align: 'start',
  sortable: false,
  key: 'transactionType',
}, {
  title: t('label.description'),
  align: 'start',
  sortable: false,
  key: 'description',
}, {
  title: t('label.related_account'),
  align: 'start',
  sortable: false,
  key: 'relatedHolding.name',
}, {
  title: t('label.sum'),
  align: 'end',
  sortable: false,
  key: 'quantity',
},{
  title: t('label.balance'),
  align: 'end',
  sortable: false,
  key: 'balance',
}, {
  align: 'end',
  title: '',
  key: 'actions',
  sortable: false,
  width: '40px'
}])

const createTransaction = () => {
  router.push({
    name: 'account-create-transaction',
    params: { accountId: accountStore.accountId }
  })
}

const importFile = () => {
  router.push({
    name: 'import',
    params: { portfolioId: accountStore.account?.portfolioId },
    query: { accountId: accountStore.accountId }
  })
}

const editTransaction = (transaction: CashAccountTransactionDtoType) => {
  
  let name = 'account-edit-transaction';
  let params: any = { accountId: transaction.holdingId, transactionId: transaction.id };

  if (transaction.relatedHolding?.type === HoldingType.Investment) {
    name = 'investment-edit-transaction';
    params = { investmentId: transaction.relatedHolding.id, transactionId: transaction.relatedTransactionId };
  } else if (transaction.relatedHolding?.type === HoldingType.Loan) {
    name = 'loan-edit-transaction';
    params = { loanId: transaction.relatedHolding.id, transactionId: transaction.relatedTransactionId };
  } else if (transaction.rootId !== transaction.id) {
    params = { accountId: transaction.relatedHoldingId, transactionId: transaction.rootId };
  }

  return {
    name,
    params,
  }
}

const accountInfoLines = computed(() => {
  const account = accountStore.account;
  if (!account) {
    return [];
  }
  return [
    { title: t('label.cash_account_name'), subtitle: account.name },
    { title: t('label.currency'), subtitle: account.currencyCode },
  ]
})

const showStatsCard = computed(() => account.value?.trackingType !== TrackingType.None);
const showTransactions = computed(() => account.value?.trackingType === TrackingType.Full);
const showBalanceForm = computed(() => account.value?.trackingType === TrackingType.Balance);

const onBalanceHistoryEdit = (item: any) => {
  balanceForm?.value?.onEdit(item);
}

const hasDifferentCurrency = computed(() => {
  return summary.value.groups[0]?.items[0]?.currencyCode !== summary.value.baseCurrencyCode;
})

const deleteSelected = async () => {
  if (!selected.value.length) {
    return;
  }
  deleteLoading.value = true;
  for(const id of selected.value) {
    await accountStore.deleteCashAccountTransaction(account.value.id, id);
  }
  refreshTrasactions();
  deleteLoading.value = false;
};
</script>

<template>
  <PageLayout :heading="account?.name">
    <template v-slot:actions v-if="showStatsCard">
      <SettingsSidebar>
        <PerformanceFilters
          v-model="summaryPeriod"
          v-model:range="summaryRange"
          v-model:showMonetaryValue="showMonetaryValue"
          v-model:showOpenAndClosedPositions="showOpenAndClosedPositions"
          narrow
        />
      </SettingsSidebar>
    </template>
    <v-row>
      <v-col v-if="showStatsCard" cols="12" sm="6" md="4" class="d-flex align-stretch">
        <StatsCard
          :value="currencyFormatter(summary.value, summary.baseCurrencyCode)"
          :subtitle="summary?.groups?.[0]?.items?.[0]?.endDate ? `${t('account_page.balance_on')} ${dateTimeFormatter(summary?.groups?.[0]?.items?.[0]?.endDate)}` : ''"
          :icon="CoinEuroIcon"
          :loading="!summary.portfolioId"
          color="primary-lighten-1"
        >
          <h3 class="text-h3 d-flex ga-2 align-center" v-if="summary.portfolioId">
            <div v-if="hasDifferentCurrency && summary.value">{{ currencyFormatter(summary.groups[0]?.items[0]?.quantity, summary.groups[0]?.items[0]?.currencyCode) }}</div>
            <div v-if="hasDifferentCurrency && summary.value"> = </div>
            <div>{{ currencyFormatter(summary.value, summary.baseCurrencyCode) }}</div>
            <HideBalancesButton />
          </h3>
          <div v-else><v-progress-circular size="32" indeterminate color="white" /></div>
        </StatsCard>
      </v-col>
      <v-col v-if="showStatsCard && summary.portfolioId" cols="12" sm="12" md="8" class="d-flex align-stretch">
        <PerformanceBar
          :currencyCode="summary.baseCurrencyCode"
          :performance="summary['openAndClosedPositions']"
          color="primary-lighten-4"
        />
      </v-col>
      <v-col cols="12" lg="9">
        <v-row>
          <v-col cols="12" v-if="showTransactions">
            <DisplayCard :title="t('account_page.transactions')" color="primary-lighten-1">
              <template v-slot:actions>
                <DropdownMenu
                  v-if="portfolio?.canEdit"
                  :activator-size="$vuetify.display.xs ? 'small' : 'default'"
                  :label="$t('portfolio.add_new')"
                  density="default"
                  color="blue"
                  :items="[{
                    value: 'transaction',
                    onClick: () => createTransaction(),
                    title: $t('account_page.create_transaction'),
                    icon: 'mdi-plus-circle-outline'
                  }, {
                    value: 'import',
                    id: 'import-transactions',
                    onClick: () => importFile(),
                    title: $t('label.import'),
                    icon: 'mdi-microsoft-excel'
                  }, {
                    value: 'link',
                    onClick: () => linkInfoRef?.link(),
                    title: $t('account_page.link_account'),
                    icon: 'mdi-sync',
                    visible: canBeLinked,
                  }].filter(x => x.visible !== false)"
                />
              </template>
              <v-data-table-server
                class="mb-4"
                density="compact"
                v-model:items-per-page="perPage"
                v-model:page="page"
                v-model="selected"
                :headers="headers"
                :items-length="transactions.totalItems ?? 0"
                :items="transactions.items"
                :loading="transactionsLoading"
                item-value="id"
                :items-per-page-options="perPageOptions"
                :show-select="portfolio?.canEdit"
              >
                <template v-slot:footer.prepend>
                  <v-btn 
                    v-if="selected.length" 
                    class="ml-4" 
                    color="error"
                    variant="tonal"
                    size="small"
                    :loading="deleteLoading"
                    @click="deleteSelected">
                    {{ t('label.delete') }}
                  </v-btn>
                  <v-spacer />
                </template>
                <template v-slot:item.dateTransactionAt="{ value }">
                  <DateCell :value="value" />
                </template>
                <template v-slot:item.transactionType="{ value, item }">
                  <TransactionTypeCell :value="value" :transaction="item" :instrument-type="'cash'" />
                </template>
                <template v-slot:item.quantity="{ item }">
                  <PriceCell :value="item.quantity" :currencyCode="item.currencyCode"  :transaction-type="item.transactionType" :adjust-sign="true" isTotalValue />
                </template>
                <template v-slot:item.balance="{ item }">
                  <span class="balance">{{ currencyFormatter(item.balance, item.currencyCode) }}</span>
                </template>
                <template v-slot:item.description="{ item }">
                  <span class="balance">{{ item.description }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn variant="text" icon="mdi-pencil" :to="editTransaction(item)" size="x-small" v-if="portfolio?.canEdit" />
                </template>
                <template v-slot:no-data>
                  {{ t('label.no_data') }}
                </template>
              </v-data-table-server>
            </DisplayCard>
          </v-col>
          <v-col cols="12" v-if="showBalanceForm">
            <BalanceModule
              :account="account"
              @edit:balance="onBalanceHistoryEdit"
              @link="linkInfoRef?.link()"
            />
          </v-col>
          <v-col cols="12">
            <NotesModule :holding="account" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="3">
        <DisplayCard :title="t('account_page.account_info')">
          <v-list lines="one">
            <v-list-item
              v-for="(info, i) in accountInfoLines"
              :key="i"
              :title="info.title"
              :subtitle="info.subtitle"
            />
            <v-list-item :title="t('account_page.all_currencies')">
              <div class="d-flex ga-2 mt-1 flex-wrap">
                <v-chip
                  v-for="child in parentAccount?.childAccounts"
                  :key="child.currencyCode"
                  :to="{ name: 'account', params: { accountId: child.id } }"
                  label
                  color="primary">
                  {{ child.currencyCode }}
                </v-chip>
                <v-btn v-if="portfolio?.canEdit" icon="mdi-plus" @click="showAddNewCurrencyModal = true" color="success" size="small" variant="tonal" />
              </div>
            </v-list-item>
          </v-list>
          <div class="pa-4 d-flex flex-column" style="gap: 10px;">
            <LinkInfo
              ref="linkInfoRef"
              :account="(account as AccountDtoType)"
              version="extended"
              v-if="portfolio?.canEdit"
            />
            <v-btn
              v-if="portfolio?.canEdit"
              :to="{name: 'account-settings', params: { accountId: parentAccount?.id } }"
              color="primary"
              rounded="sm"
              size="small"
              variant="flat"
            >
              {{ $t('account_page.settings') }}
            </v-btn>
          </div>
        </DisplayCard>
      </v-col>
    </v-row>
  </PageLayout>
  <AddNewCurrencyForm v-model:model-visible="showAddNewCurrencyModal" :account="parentAccount" />
</template>
