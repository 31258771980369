import { Static, Type } from '@sinclair/typebox'
import { FixedIncomeType, HoldingType, InstrumentType, PaymentFrequency, PerformanceMethod, PortfolioEntityType, TrackingType, TransactionType } from './enums'

export const currencyCodeType = Type.String({ examples: ['EUR', 'USD', 'GBP'], default: 'EUR'})

export const PaginatedResponseDto = Type.Object({
  page: Type.Number(),
  pageSize: Type.Number(),
  totalItems: Type.Number(),
  totalPages: Type.Number(),
})

export const FixedRateInstrumentConfigDto = Type.Object({
  hasFixedIncome: Type.Optional(Type.Boolean()),
  incomeType: Type.Optional(Type.Enum(FixedIncomeType)),
  incomeAmount: Type.Optional(Type.Number()),
  taxRate: Type.Optional(Type.Number()),
  currencyCode: Type.Optional(currencyCodeType),
  paymentFrequencyType: Type.Optional(Type.Enum(PaymentFrequency)),
  paymentFrequency: Type.Optional(Type.Number({ default: 1 })),
  agreementStartDate: Type.Optional(Type.String({ format: 'date' })),
  dateOfFirstPayment: Type.Optional(Type.String({ format: 'date' })),
  dateOfLastPayment: Type.Optional(Type.Union([Type.Null(), Type.String({ format: 'date' })])),
  dateOfCall: Type.Optional(Type.Union([Type.Null(), Type.String({ format: 'date' })])),
})

export type FixedRateInstrumentConfigDto = Static<typeof FixedRateInstrumentConfigDto>

export const InstrumentDto = Type.Object({
  id: Type.Integer(),
  uniqueId: Type.String(),
  currencyCode: currencyCodeType,
  type: Type.Enum(InstrumentType),
  name: Type.String(),
  symbol: Type.String(),
  exchange: Type.String(),
  displayName: Type.String(),
  isinCode: Type.Optional(Type.String()),
  isPublic: Type.Boolean(),
  assetClass: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  consolidationLabel: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  logo: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  nominal: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
  fixedRateInstrumentConfig: Type.Optional(FixedRateInstrumentConfigDto),
  isArchived: Type.Optional(Type.Boolean()),
  isExpired: Type.Optional(Type.Boolean()),
})

export const PortfolioDto = Type.Object({
  id: Type.Integer(),
  name: Type.String({ examples: ['Investments'], default: 'Investments'}),
  currencyCode: Type.String({ examples: ['EUR', 'USD', 'GBP'], default: 'EUR'}),
  isConsolidated: Type.Boolean({ default: false }),
  isPrimary: Type.Boolean({ default: false }),
  consolidatedPortfolioIds: Type.Optional(Type.Array(Type.Integer())),
  consolidatedPortfolioAllocations: Type.Optional(Type.Record(Type.Number(), Type.Number())),
  hasAccounts: Type.Optional(Type.Boolean()),
  canEdit: Type.Optional(Type.Boolean()),
  performanceCalculationMethod: Type.Enum(PerformanceMethod),
  entityType: Type.Enum(PortfolioEntityType),
  enableMonthlyOverviewEmail: Type.Optional(Type.Boolean()),
  goal: Type.Optional(Type.Object({
    enabled: Type.Optional(Type.Boolean()),
    startDate: Type.Optional(Type.Union([
      Type.String({ format: 'date' }),
      Type.Literal(''),
    ])),
    targetDate: Type.Optional(Type.Union([
      Type.String({ format: 'date' }),
      Type.Literal(''),
    ])),
    startingValue: Type.Optional(Type.Union([
      Type.Number(),
      Type.Literal(''),
    ])),
    targetValue: Type.Optional(Type.Union([
      Type.Number(),
      Type.Literal(''),
    ])),
    calculationMethod: Type.Optional(Type.Union([
      Type.String(),
      Type.Literal(''),
    ])),
  })),
  user: Type.Optional(Type.Object({
    id: Type.Optional(Type.Integer()),
    email: Type.Optional(Type.String()),
  })),
})


export const NoteDto = Type.Object({
  content: Type.String(),
  portfolioId: Type.Integer(),
  holdingId: Type.Optional(Type.Union([Type.Integer(), Type.Null()])),
  holding: Type.Optional(Type.Any()),
})

export const InvestmentDto = Type.Object({
  id: Type.Integer(),
  name: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  symbol: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  consolidationLabel: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  assetClass: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  portfolioId: Type.Integer(),
  instrumentId: Type.Integer(),
  currencyCode: currencyCodeType,
  type: Type.Enum(HoldingType),
  instrument: InstrumentDto,
  portfolio: Type.Optional(PortfolioDto),
  loanHoldingId: Type.Optional(Type.Union([Type.Integer(), Type.Null()])),
  notes: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  nominal: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
  fixedRateInstrumentConfig: Type.Optional(FixedRateInstrumentConfigDto),
  isArchived: Type.Optional(Type.Boolean()),
  note: Type.Optional(NoteDto),
  connectedHoldings: Type.Optional(Type.Array(Type.Any())),
})

export const AccountDto = Type.Object({
  id: Type.Integer(),
  portfolioId: Type.Integer(),
  name: Type.String({ examples: ['LHV', 'SEB', 'Swedbank'], default: 'LHV'}),
  currencyCode: currencyCodeType,
  type: Type.Enum(HoldingType),
  isInPortfolio: Type.Optional(Type.Boolean()),
  trackingType: Type.Optional(Type.Enum(TrackingType)),
  symbol: Type.Optional(Type.String()),
  parentAccountId: Type.Optional(Type.Union([Type.Integer(), Type.Null()])),
  isRoot: Type.Optional(Type.Boolean()),
  providerId: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
  childAccounts: Type.Optional(Type.Array(Type.Object({
    id: Type.Integer(),
    currencyCode: currencyCodeType,
    balance: Type.Optional(Type.Number()),
    balanceDate: Type.Optional(Type.String({ format: 'date' })),
  }))),
  portfolio: Type.Optional(PortfolioDto),
  logo: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  notes: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  link: Type.Optional(Type.Any()),
  canBeLinked: Type.Optional(Type.Boolean()),
  consolidationLabel: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  balance: Type.Optional(Type.Number()),
  balanceDate: Type.Optional(Type.String({ format: 'date' })),
  isArchived: Type.Optional(Type.Boolean()),
  note: Type.Optional(NoteDto),
})

export const LoanAccountDto = Type.Object({
  id: Type.Integer(),
  portfolioId: Type.Integer(),
  name: Type.String(),
  symbol: Type.Optional(Type.String()),
  currencyCode: currencyCodeType,
  type: Type.Enum(HoldingType),
  notes: Type.Optional(Type.String()),
  portfolio: Type.Optional(PortfolioDto),
  fixedRateInstrumentConfig: Type.Optional(FixedRateInstrumentConfigDto),
  relatedHoldingId: Type.Optional(Type.Integer()),
  note: Type.Optional(NoteDto),
  isArchived: Type.Optional(Type.Boolean()),
  connectedHoldings: Type.Optional(Type.Array(InvestmentDto)),
})

export const TransactionDto = Type.Object({
  id: Type.Integer(),
  portfolioId: Type.Integer(),
  holdingId: Type.Integer(),
  dateTransactionAt: Type.String({ format: 'date' }),
  currencyCode: currencyCodeType,
  baseCurrencyCode: currencyCodeType,
  quantity: Type.Number(),
  balance: Type.Union([Type.Number(), Type.Null()]),
  price: Type.Number(),
  fee: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
  tax: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
  exchangeRate: Type.Number(),
  exchangeRatePair: Type.String(),
  relatedTransactionId: Type.Optional(Type.Integer()),
  relatedHoldingId: Type.Optional(Type.Union([Type.Integer(), Type.Null()])),
  relatedTransactionExchangeRate: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
  relatedHolding: Type.Optional(AccountDto),
  description: Type.Optional(Type.String()),
  isIncome: Type.Boolean(),
  totalValue: Type.Number(),
  value: Type.Optional(Type.Number()),
  transactionType: Type.Enum(TransactionType),
  relatedTransactionType: Type.Optional(Type.Enum(TransactionType)),
  referenceId: Type.Optional(Type.Union([Type.String(), Type.Null()])),
  rootId: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
})

export const InstrumentOpeningBalanceDto = Type.Object({
  instrument: Type.Object({
    id: Type.Integer(),
    uniqueId: Type.String(),
    symbol: Type.String(),
    exchange: Type.String(),
    currencyCode: Type.String(),
  }),
  quantity: Type.Number(),
  price: Type.Number(),
  cost: Type.Number()
})

export const AccountOpeningBalanceDto = Type.Object({
  name: Type.String(),
  providerId: Type.Number(),
  trackingType: Type.Optional(Type.Enum(TrackingType)),
  balances: Type.Array(Type.Object({
    currencyCode: Type.String(),
    amount: Type.Number()
  }))
})

export type NoteDtoType = Static<typeof NoteDto>