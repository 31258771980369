<script setup lang="ts">
import { currencyFormatter } from '@/const';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { useLazyFetch } from '@/utils/reactivity.utils';
import { ref } from 'vue';

const showCopiedLink = ref(false);
const showCopiedCode = ref(false);

function copyLink() {
  showCopiedLink.value = true;
  navigator.clipboard.writeText(marketingInfo.data.value?.inviteLink ?? '');
  setTimeout(() => {
    showCopiedLink.value = false;
  }, 2000);
}

function copyCode() {
  showCopiedCode.value = true;
  navigator.clipboard.writeText(marketingInfo.data.value?.inviteCode ?? '');
  setTimeout(() => {
    showCopiedCode.value = false;
  }, 2000);
}

const marketingInfo = useLazyFetch<{
  inviteLink: string;
  inviteCode: string;
  stats: {
    referredCount: number;
    subscribedCount: number;
    creditEarned: number;
    creditAvailable: number;
  }
} | null>(() => fetchWrapper.get('/api/v1/auth/me/marketing'), null);
</script>

<template>
  <PageLayout :show-loader="marketingInfo.loading.value">
    <template v-slot:heading>
      <div class="d-flex align-center ga-2" slot="heading">
        <v-avatar size="42" class="border-md border-primary" color="warning">
          <v-icon color="amber-lighten-5" size="24">mdi-gift</v-icon>
        </v-avatar>
        <span>{{ $t('settings_page.invite_friends_tab') }}</span>
      </div>
    </template>
    <v-sheet class="pa-4" border="0" elevation="4" rounded="md" color="primary-lighten-1">
      <ul class="ml-4 text-body-1">
        <li v-html="$t('settings_page.invite_friends.line_1')" />
        <li v-html="$t('settings_page.invite_friends.line_2')" />
        <li v-html="$t('settings_page.invite_friends.line_3')" />
        <li v-html="$t('settings_page.invite_friends.line_4')" />
      </ul>
    </v-sheet>
    <v-row class="mt-4">
      <v-col cols="12" sm="6" md="8" class="d-flex flex-column ga-4">
        <div class="d-flex flex-column ga-1 text-left">
          <div class="font-weight-medium">
            {{ $t('settings_page.invite_friends.invite_link') }}
          </div>
          <v-text-field :value="marketingInfo.data.value?.inviteLink" variant="outlined" :hint="$t('settings_page.invite_friends.invite_link_hint')" persistent-hint>
            <template v-slot:append-inner>
              <v-tooltip bottom v-model="showCopiedLink" :open-on-hover="false">
                <template v-slot:activator="{ props }">
                  <v-btn @click="copyLink()" v-bind="props" variant="text" icon="mdi-content-copy"></v-btn>
                </template>
                <span>
                  {{ $t('label.copied') }}
                </span>
              </v-tooltip>
            </template>
          </v-text-field>
        </div>
        <div class="d-flex flex-column ga-1">
          <div class="font-weight-medium">
            {{ $t('settings_page.invite_friends.invite_code') }}
          </div>
          <v-text-field :value="marketingInfo.data.value?.inviteCode" variant="outlined" :hint="$t('settings_page.invite_friends.invite_code_hint')" persistent-hint>
            <template v-slot:append-inner>
              <v-tooltip bottom v-model="showCopiedCode" :open-on-hover="false">
                <template v-slot:activator="{ props }">
                  <v-btn @click="copyCode()" v-bind="props" variant="text" icon="mdi-content-copy"></v-btn>
                </template>
                <span>
                  {{ $t('label.copied') }}
                </span>
              </v-tooltip>
            </template>
          </v-text-field>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-sheet class="pa-8" border="0" rounded="lg" color="primary-lighten-3 bubble-shape-sm bubble-primary overflow-hidden">
          <h2 class="text-h3">
            {{ $t('settings_page.invite_friends.stats') }}
          </h2>
          <div class="d-flex flex-column ga-2 mt-2 text-body-1">
            <div>{{ $t('settings_page.invite_friends.referred_users') }}: <strong>{{ marketingInfo.data.value?.stats?.referredCount ?? 0 }}</strong></div>
            <div>{{ $t('settings_page.invite_friends.purchased_users') }}: <strong>{{ marketingInfo.data.value?.stats?.subscribedCount ?? 0 }}</strong></div>
            <div>{{ $t('settings_page.invite_friends.credits_earned') }}: <strong>{{ currencyFormatter((marketingInfo.data.value?.stats?.creditEarned ?? 0) / 100, 'EUR') }}</strong></div>
            <div>{{ $t('settings_page.invite_friends.credit_available') }}: <strong>{{ currencyFormatter((marketingInfo.data.value?.stats?.creditAvailable ?? 0) / 100, 'EUR') }}</strong></div>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </PageLayout>
</template>
