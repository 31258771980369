<script setup lang="ts">
import { percentFormatter, currencyFormatter } from '@/const';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ValueWithArrow from './shared/ValueWithArrow.vue';
import UpgradeLock from './UpgradeLock.vue';

const { t } = useI18n();

const props = defineProps({
  currencyCode: {
    type: String,
    required: false
  },
  performance: {
    type: null,
    required: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: 'primary'
  },
  isPartial: {
    type: Boolean,
    default: false
  },
  returnType: {
    type: String,
    default: 'auto'
  }
});

const returnTypeComputed = computed(() => {
  if (props.returnType === 'auto') {
    return props.performance?.isAnnualized ? 'annualized' : 'cummulative';
  }
  return props.returnType === 'annualized' ? 'annualized' : 'cummulative';
})

const summaryStats = computed(() => {
  const performance = props.performance ?? {}
  const currencyCode = props.currencyCode ?? ''
  const suffix = returnTypeComputed.value === 'annualized' ? 'Annualized' : 'Cummulative'

  return [{
    title: t('performance.capital_gain'),
    rawValue: performance?.capitalGain ?? 0,
    rawReturn: performance?.[`capitalReturn${suffix}`] ?? 0,
    value: () => currencyFormatter(performance?.capitalGain ?? 0, currencyCode),
    return: percentFormatter(Math.abs(performance?.[`capitalReturn${suffix}`] ?? 0)),
    direction: performance?.capitalReturn > 0 ? 'up' : (performance?.capitalReturn < 0 ? 'down' : 'none')
  }, {
    title: t('performance.fee_gain'),
    rawValue: performance?.feesGain ?? 0,
    rawReturn: performance?.[`feesReturn${suffix}`] ?? 0,
    value: () => currencyFormatter(performance?.feesGain ?? 0, currencyCode),
    return: percentFormatter(Math.abs(performance?.[`feesReturn${suffix}`] ?? 0)),
    direction: performance?.feesReturn > 0 ? 'up' : (performance?.feesReturn < 0 ? 'down' : 'none')
  }, {
    title: t('performance.income_gain'),
    rawValue: performance?.cashflowGain ?? 0,
    rawReturn: performance?.[`cashflowReturn${suffix}`] ?? 0,
    value: () => currencyFormatter(performance?.cashflowGain ?? 0, currencyCode),
    return: percentFormatter(Math.abs(performance?.[`cashflowReturn${suffix}`] ?? 0)),
    direction: performance?.cashflowReturn > 0 ? 'up' : (performance?.cashflowReturn < 0 ? 'down' : 'none')
  }, {
    title: t('performance.currency_gain'),
    rawValue: performance?.currencyGain ?? 0,
    rawReturn: performance?.[`currencyReturn${suffix}`] ?? 0,
    value: () => currencyFormatter(performance?.currencyGain ?? 0, currencyCode),
    return: percentFormatter(Math.abs(performance?.[`currencyReturn${suffix}`] ?? 0)),
    direction: performance?.currencyReturn > 0 ? 'up' : (performance?.currencyReturn < 0 ? 'down' : 'none')
  }, {
    title: t('performance.total_gain'),
    rawValue: performance?.totalGain ?? 0,
    rawReturn: performance?.[`totalReturn${suffix}`] ?? 0,
    value: () => currencyFormatter(performance?.totalGain ?? 0, currencyCode),
    return: percentFormatter(Math.abs(performance?.[`totalReturn${suffix}`] ?? 0)),
    direction: performance?.totalReturn > 0 ? 'up' : (performance?.totalReturn < 0 ? 'down' : 'none'),
    bold: true
  }]
})

const isAnnualized = computed(() => {
  return returnTypeComputed.value === 'annualized';
})
</script>

<template>
  <v-card elevation="4" :class="`bg-${color} d-flex align-stretch ${$vuetify.display.xs ? 'overflow-x-auto overflow-y-hidden' : ''}`" variant="outlined">
    <v-overlay :model-value="loading" scrim="#FFF" contained persistent />
    <v-row dense class="ga-0 pa-1 flex-nowrap box-container">
      <v-col class="pa-0 d-flex box" v-for="(stat, i) in summaryStats" :key="i" :class="{
        'positive': stat.direction === 'up',
        'negative': stat.direction === 'down',
      }" dense>
        <v-card-text class="text-center stats d-flex justify-center flex-column pa-0">
          <div class="d-flex justify-center" style="font-size:20px;">
            <value-with-arrow
              :value="stat.rawValue"
              :arrow="false"
              :class="{'font-weight-light': !stat.bold, 'font-weight-medium': stat.bold}"
              :color-text="false"
              :currency="currencyCode"
            />
            <UpgradeLock v-if="isPartial" color="red" size="16" icon="mdi-exclamation" />
          </div>
          <div class="mt-1 font-weight-light d-flex justify-center">
            <value-with-arrow
              :value="stat.rawReturn"
              :arrow="true"
              :class="{'font-weight-light': !stat.bold, 'font-weight-medium': stat.bold}"
              :color-text="false"
            />
            <sup v-if="isAnnualized" class="font-weight-light text-caption">p.a.</sup>
          </div>
          <span class="mt-1 text-subtitle-1 font-weight-medium">{{ stat.title }}</span>
        </v-card-text>
      </v-col>
    </v-row>
    <div class="bg-primary-lighten-2 justify-center pa-3 rounded-e d-flex flex-row ga align-center">
      <span class="mt-1 text-subtitle-2 font-weight-medium vertical-text-center">
        <template v-if="isAnnualized">{{ t('label.annualized') }}</template>
        <template v-else>{{ t('label.cummulative') }}</template>
      </span>
    </div>
  </v-card>
</template>

<style scoped lang="scss">
.stats {
  white-space: nowrap;
}
.vertical-text-center {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.box {
  border-right: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
  padding: 10px !important;
  background: linear-gradient(to top, rgba(0,0,0, 0.05), rgba(var(--v-theme-success), 0));
  flex: 1 1 20%;
  max-width: 20%;
  box-sizing: border-box;
  min-width: 120px;

  &.positive {
    background: linear-gradient(to top, rgba(var(--v-theme-success), 0.05), rgba(var(--v-theme-success), 0));
  }

  &.negative {
    background: linear-gradient(to top, rgba(var(--v-theme-error), 0.05), rgba(var(--v-theme-error), 0));
  }
}
</style>
