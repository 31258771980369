<script setup lang="ts">
import FutureIncome from '../dashboard/FutureIncome.vue';

const props = defineProps<{
  holding?: any | undefined
}>()
</script>

<template>
  <FutureIncome
    v-if="holding?.id"
    :holding="props.holding"
    card-color="panel-heading-bg"
    button-color="white"
    flavour="investment"
  />
</template>
