<template>
  <template v-if="showButton">
    <v-btn
      :icon="icon"
      @click.stop="drawer = !drawer"
      v-if="showButton"
      :class="buttonClass"
      color="primary"
      variant="tonal"
      :size="size"
    />
    <v-navigation-drawer
      location="right"
      temporary
      rounded
      v-model="drawer"
      v-if="showButton"
      :width="350"
    >
      <div class="pa-4">
        <slot />
      </div>
      <template v-slot:append>
        <div class="pa-4">
          <v-btn
            @click="drawer = false"
            color="primary"
            variant="flat"
            block
          >{{ $t('label.close') }}</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </template>
  <template v-else>
    <slot />
  </template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useDisplay } from 'vuetify';

const { smAndDown } = useDisplay();

const props = defineProps({
  buttonClass: {
    type: String,
    default: ''
  },
  showButton: {
    default: () => (): boolean | null => null
  },
  icon: {
    type: String,
    default: 'mdi-cog'
  },
  size: {
    type: String,
    default: undefined
  }
})

const drawer = ref(false);

const showButton = computed(() => {
  return props.showButton() ?? smAndDown.value;
})
</script>