<template>
  <component :is="component" :color="color" :size="props.size">
    <v-icon v-if="logo" :color="iconColor" :size="iconSize">
      <template v-if="isIcon">{{ logo }}</template>
      <v-img v-else :src="logo" :width="size" :height="size" />
    </v-icon>
    <span v-else>{{ name }}</span>
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { AccountsConfig } from '~/accounts.config';

const props = defineProps({
  light: {
    type: Boolean,
    default: false,
  },
  thumbnail: {
    type: Boolean,
    default: false,
  },
  avatar: {
    type: Boolean,
    default: false
  },
  size: {
    type: Number,
    default: 32
  },
  broker: {
    default: ''
  },
  name: {
    type: String,
    default: ''
  }
})

const logo = computed(() => {
  // @ts-ignore
  return AccountsConfig[props.broker]?.logo
})

const color = computed(() => {
  // @ts-ignore
  return AccountsConfig[props.broker]?.backgroundColor ?? 'primary'
})

const iconColor = computed(() => {
  return isIcon.value ? 'white' : color.value
})

const iconSize = computed(() => {
  return isIcon.value ? props.size-10 : props.size
})

const name = computed(() => {
  return props.name.substring(0, 2).toUpperCase()
})

const component = computed(() => {
  return props.avatar ? 'v-avatar' : 'div'
})

const isIcon = computed(() => {
  return logo.value?.startsWith('mdi-')
})
</script>
