import { defineStore } from 'pinia';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import type { PortfolioCreateDtoType, PortfolioDtoType, ImportDtoType, OpeningBalanceDtoType, ImportResultDtoType } from '~/portfolio.schema';
import type { RemoteObject } from '@/const';
import { useAccountStore } from './accountStore';
import { useInvestmentStore } from './investmentStore';
import { computed, ref, watch } from 'vue';
import type { AccountDtoType } from '~/cash-account.schema';
import type { InvestmentDtoType } from '~/investment.schema';
import { useAuthStore } from './authStore';
import { useLoanStore } from './loanAccountStore';
import type { CustomGroupingConfig } from '~/types';
import { useLazyFetch } from '@/utils/reactivity.utils';

type State = {
  selectedPortfolio: PortfolioDtoType
  selectedPortfolioId: number | undefined
  portfolio: PortfolioDtoType | RemoteObject
  portfolios: {
    value:  PortfolioDtoType[] | null,
    loading: boolean,
    error: string | null
  },
  isPortfolioLoading: boolean
}

export const usePortfolioStore = defineStore('portfolio', () => {
  const accountStore = useAccountStore()
  const loanStore = useLoanStore()
  const investmentStore = useInvestmentStore()
  const authStore = useAuthStore()

  const portfolio = ref<State['portfolio']>({})
  const portfolioId = ref<State['selectedPortfolioId']>(undefined)
  const _selectedPortfolio = ref<PortfolioDtoType>({} as PortfolioDtoType)
  const _selectedPortfolioId = ref<State['selectedPortfolioId']>(undefined)
  const portfolios = ref<State['portfolios']>({
    value: null,
    loading: true,
    error: null
  })

  const selectedPortfolio = computed<PortfolioDtoType>(() => {
    if (_selectedPortfolio.value?.id) {
      return _selectedPortfolio.value ?? portfolios.value.value?.find((p) => p.id === _selectedPortfolioId.value)
    } else {
      return portfolio.value
    }
  })

  const selectedPortfolioId = computed(() => {
    if (_selectedPortfolioId.value) {
      return _selectedPortfolioId.value;
    } else {
      return portfolio.value.id
    }
  })

  const activePortfolioId = computed(() => {
    return portfolioId.value || selectedPortfolioId.value
  })

  const activePortfolio = computed(() => {
    return portfolios.value.value?.find((p) => p.id === activePortfolioId.value)
  })
  
  const getAll = async () => {
    portfolios.value.loading = true

    return fetchWrapper
      .get('/api/v1/portfolio')
      .then((portfoliosResponse) => {
        portfolios.value.value = portfoliosResponse
      })
      .catch((error) => (portfolios.value.error = error ))
      .then(() => (portfolios.value.loading = false));
  }

  const create = async (portfolio: PortfolioCreateDtoType) => {
    return fetchWrapper.post('/api/v1/portfolio', portfolio)
      .then((portfolioResponse) => {
        window.localStorage.setItem('_firstPortfolio', portfolios.value.value?.length === 0 ? 'true' : 'false');
        portfolio.value = portfolioResponse;
        getAll();
        return portfolioResponse;
      })
  }

  const update = async (portfolioDto: PortfolioCreateDtoType) => {
    return fetchWrapper.put(`/api/v1/portfolio/${selectedPortfolioId.value}`, portfolioDto)
      .then((portfolioResponse) => {
        portfolio.value = portfolioResponse
        _selectedPortfolio.value = portfolioResponse
        getAll()
        return portfolioResponse
      })
  }

  const del = async () => {
    return fetchWrapper.delete(`/api/v1/portfolio/${selectedPortfolioId.value}`)
      .then(() => {
        if (portfolio.value?.id === selectedPortfolioId.value) {
          portfolio.value = {};
        }
        _selectedPortfolio.value = {};
        _selectedPortfolioId.value = undefined;
        return getAll()
      })
  }

  async function select (id: number | undefined, forceRefresh = false) {
    id = id ? parseInt(id.toString()) : undefined
    if (!forceRefresh && _selectedPortfolio.value?.id === id || !id) {
      return
    }

    sessionStorage.setItem('selectedPortfolioId', id.toString())

    portfolioId.value = id
    _selectedPortfolioId.value = id
    _selectedPortfolio.value = { loading: true }
    portfolio.value = { loading: true }

    fetchWrapper
      .get(`/api/v1/portfolio/${id}`)
      .then((portfolioResponse) => {
        _selectedPortfolio.value = portfolioResponse
        portfolio.value = portfolioResponse
      })
      .catch((error: any) => {
        _selectedPortfolio.value = { error }
        if (error === 'Not Found') {
          document.location.href = '/'
        }
      });
  }

  async function get (id: number | undefined, forceRefresh = false) {
    id = id ? parseInt(id.toString()) : undefined
    if (!forceRefresh && portfolio.value?.id === id || !id) {
      return
    }
    portfolioId.value = id
    portfolio.value = { loading: true }

    fetchWrapper
      .get(`/api/v1/portfolio/${id}`)
      .then((portfolioResponse) => {
        portfolio.value = portfolioResponse
      })
      .catch((error) => (portfolio.value = { error }));
  }

  const getSummary = async (id: string, period: string, range?: Record<string, string>, groupBy?: string) => {
    const filters = new URLSearchParams(
      Object.assign({}, range, { period, groupBy: groupBy ?? ''})
    ).toString()

    return fetchWrapper.get(`/api/v1/portfolio/${id}/summary?${filters}`);
  }

  const portfolioAccounts = useLazyFetch<AccountDtoType[]>(async () => {
    if (!activePortfolioId.value) return;
    return await fetchWrapper.get(`/api/v1/portfolio/${activePortfolioId.value}/accounts`)
  }, [])

  const getAllAccounts = async (_portfolioId: number) => {
    portfolioAccounts.refresh()
  }

  const portfolioInvestments = useLazyFetch(async () => {
    if (!activePortfolioId.value) return;
    return await fetchWrapper.get(`/api/v1/portfolio/${activePortfolioId.value}/investments`)
  }, [])

  const getAllInvestments = async (_portfolioId: number) => {
    portfolioInvestments.refresh()
  }

  const uploadCsvFile = async (portfolioId: string, file: File, options: any) => {
    const formData = new FormData()
    formData.append('options', JSON.stringify(options))
    formData.append('file', file)
    return fetchWrapper.post(`/api/v1/portfolio/${portfolioId}/import`, formData)
  }

  const prepareCsvFile = async (portfolioId: number, mappings: any) => {
    return fetchWrapper.post(`/api/v1/portfolio/${portfolioId}/import/prepare`, mappings)
  }

  const prepareUnconfirmedTransactions = async (portfolioId: number, accountId: number) => {
    return fetchWrapper.post(`/api/v1/portfolio/${portfolioId}/import/prepare/${accountId}`, {})
  }

  const deleteUnconfirmedTransaction = async (portfolioId: number, accountId: number, references: string[]) => {
    try {
      if (!accountId) {
        return
      }
      fetchWrapper.delete(`/api/v1/portfolio/${portfolioId}/import/prepare/${accountId}/delete`, references);
    } finally {
      // Update unconfirmed transactions
      void useAuthStore().getMe()
    }
  }

  const importTransactions = async (portfolioId: string, cashAccountId: number | '', transactions: ImportDtoType[]): Promise<ImportResultDtoType[]> => {
    try {
      return fetchWrapper.post(`/api/v1/portfolio/${portfolioId}/import/transactions?accountId=${cashAccountId}`, transactions);
    } finally {
      // Update unconfirmed transactions
      void useAuthStore().getMe()
    }
  }

  const importOpenBalances = async (portfolioId: number, dto: OpeningBalanceDtoType) => {
    try {
      await fetchWrapper.post(`/api/v1/portfolio/${portfolioId}/opening-balance`, dto);
      await Promise.all([
        select(portfolioId, true),
        getAllAccounts(portfolioId),
        getAllInvestments(portfolioId)
      ])
    } finally {
      // Do nothing
    }
  }

  const groups = useLazyFetch<{name: string, id: string, config: CustomGroupingConfig}[]>(async () => {
    return await fetchWrapper.get('/api/v1/group')
  }, [])

  const getGroups = async () => {
    groups.refresh()
  }

  const addDemoPortfolio = async () => {
    try {
      const demo = await fetchWrapper.post('/api/v1/portfolio/clone/demo')
      await getAll()
      await select(demo.id)
    } finally {
      // Do nothing
    }
  }


  // Watch auth event
  watch(() => authStore.user, (user) => {
    if (user?.id) {
      getAll()
    }
  })

  // Change the portfolio when the account changes
  watch(() => accountStore.account, (account) => {
    if ('portfolioId' in account && account.portfolioId !== portfolio.value?.id) {
      get(account.portfolioId)
    }
  })

  // Change the portfolio when the loan changes
  watch(() => loanStore.loan, (loan) => {
    if ('portfolioId' in loan && loan.portfolioId !== loan.value?.id) {
      get(loan.portfolioId)
    }
  })

  // Change the portfolio when the investment changes
  watch(() => investmentStore.investment, (investment) => {
    if ('portfolioId' in investment && investment.portfolioId !== portfolio.value?.id) {
      get(investment.portfolioId)
    }
  })

  watch(() => portfolios.value.value, (portfolios) => {
    const getPriority = (portfolio: PortfolioDtoType) => {
      if (sessionStorage.getItem('selectedPortfolioId') === portfolio.id.toString()) {
        return -100
      } else if (portfolio.isPrimary && portfolio.user?.id === authStore.user?.id) {
        return -1
      } else {
        return 1
      }
    }

    if (!selectedPortfolioId.value && portfolios?.length) {
      const primary = portfolios.sort((a, b) => {
        return getPriority(a) - getPriority(b)
      })[0]
      _selectedPortfolioId.value = primary.id
      _selectedPortfolio.value = primary
    }
  })

  return {
    activePortfolio,
    selectedPortfolio,
    selectedPortfolioId,
    portfolio,
    activePortfolioId,
    portfolios,
    portfolioAccounts,
    portfolioInvestments,
    categories: groups,
    getAll,
    create,
    update,
    select,
    get,
    del,
    getSummary,
    getAllAccounts,
    getAllInvestments,
    uploadCsvFile,
    prepareCsvFile,
    prepareUnconfirmedTransactions,
    deleteUnconfirmedTransaction,
    importTransactions,
    importOpenBalances,
    getCategories: getGroups,
    addDemoPortfolio
  }
});
