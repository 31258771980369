<script setup lang="ts">
import { useAuthStore } from '@/stores/authStore';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { ChartType, FeatureFlag } from '~/enums';
import NoPricePolicy from '../NoPricePolicy.vue';

const props = defineProps({
  modelValue: {
    default: ''
  },
  mode: {
    type: String,
    default: 'portfolio'
  },
  disablePriceChart: {
    type: Boolean,
    default: false
  },
  enableLtvChart: {
    type: Boolean,
    default: false
  },
  enableYtmChart: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:modelValue']);

const authStore = useAuthStore();
const { t } = useI18n();

const grapTypeOptions = computed(() => {
  return [{
    title: t('filter.chart_type.total.title'),
    icon: 'mdi-chart-line',
    description: t('filter.chart_type.total.description'),
    value: ChartType.Total,
    visibleOnModes: ['portfolio', 'investment'],
    disabled: false
  }, {
    title: t('filter.chart_type.stacked.title'),
    icon: 'mdi-chart-areaspline',
    description: t('filter.chart_type.stacked.description'),
    value: ChartType.Stacked,
    visibleOnModes: ['portfolio'],
    disabled: false
  }, {
    title: t('filter.chart_type.price.title'),
    icon: 'mdi-finance',
    description: t('filter.chart_type.price.description'),
    value: ChartType.Price,
    visibleOnModes: authStore.isEnabled(FeatureFlag.HistoricalPrices) ? ['investment'] : [],
    disabled: props.disablePriceChart
  }, {
    title: t('filter.chart_type.costbase-total.title'),
    icon: 'mdi-chart-line',
    description: t('filter.chart_type.costbase-total.description'),
    value: ChartType.CostbaseTotal,
    visibleOnModes: ['portfolio', 'investment'],
    disabled: false
  }, {
    title: t('filter.chart_type.costbase-stacked.title'),
    icon: 'mdi-chart-areaspline',
    description: t('filter.chart_type.costbase-stacked.description'),
    value: ChartType.CostbaseStacked,
    visibleOnModes: ['portfolio'],
    disabled: false
  }, {
    title: t('filter.chart_type.growth.title'),
    icon: 'mdi-chart-bar-stacked',
    description: t('filter.chart_type.growth.description'),
    value: ChartType.Growth,
    visibleOnModes: ['portfolio', 'investment'],
    disabled: false
  }, {
    title: t('filter.chart_type.ltv.title'),
    icon: 'mdi-chart-ppf',
    description: t('filter.chart_type.ltv.description'),
    value: ChartType.LTV,
    visibleOnModes: props.enableLtvChart ? ['investment'] : [],
  }, {
    title: t('filter.chart_type.ytm.title'),
    icon: 'mdi-chart-ppf',
    description: t('filter.chart_type.ytm.description'),
    value: ChartType.YTM,
    visibleOnModes: props.enableYtmChart ? ['investment'] : [],
  }].filter((item) => {
    return item.visibleOnModes.includes(props.mode);
  })
})

const graphType = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

onMounted(() => {
  if (graphType.value && !grapTypeOptions.value.find((item) => item.value === graphType.value && !item.disabled)) {
    graphType.value = grapTypeOptions.value[0].value
  }
})
</script>

<template>
   <v-select
    :label="$t('label.chart_type')"
    v-model="graphType"
    :items="grapTypeOptions"
    hide-details
    class="text-left"
    variant="outlined"
    style="min-width: 200px;"
  >
    <template v-slot:item="{ props, item }">
      <v-list-item
        v-bind="props"
        :title="item.raw.title"
        :subtitle="item.raw.description"
        :disabled="item.raw.disabled"
      >
        <template v-slot:prepend>
          <v-icon size="36" :icon="item.raw.icon" />
        </template>
        <template v-if="item.raw.disabled" v-slot:append>
          <NoPricePolicy />
        </template>
      </v-list-item>
    </template>
  </v-select>
</template>