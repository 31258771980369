<template>
  <div style="cursor:pointer;" :class="`d-flex flex-row justify-${justify} align-center`">
    <slot><span  @click="$emit('sort', sortingKey)">{{ title }}</span></slot>
    <v-icon  @click="$emit('sort', sortingKey)" style="cursor:pointer;" size="22" class="mr-n2" :color="color" data-html2canvas-ignore="true">
      {{ icon }}
    </v-icon>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: false
  },
  sorting: Object,
  sortingKey: String,
  justify: {
    type: String,
    default: 'end'
  },
  defaultColor: {
    type: String,
    default: 'white'
  }
});
const emit = defineEmits([
  'sort',
]);

const icon = computed(() => {
  return props.sorting.key === props.sortingKey ? (props.sorting.direction === 'asc' ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin') : 'mdi-arrow-up-thin'
})

const color = computed(() => {
  return props.sorting.key === props.sortingKey ? 'blue' : props.defaultColor
})
</script>