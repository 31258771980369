<template>
  <v-menu location="bottom" v-model:model-value="value" :persistent="persistent">
    <template v-slot:activator="{ props }">
      <v-btn
        :class="activatorClass"
        id="activatorId"
        v-bind="props"
        :loading="loading"
        :variant="activatorVariant"
        :color="color"
        :size="activatorSize" 
        append-icon="mdi-chevron-down"
        :block="activatorBlock">
        {{ label }}
      </v-btn>
    </template>
    <v-list :density="(density as any)">
      <v-list-item v-for="item in items" :id="item.id || item.value" :value="item.value" @click="item.onClick">
        <v-list-item-title>
          <v-icon v-if="item.icon" left>{{  item.icon }}</v-icon>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: false,
    default: undefined
  },
  color: {
    type: String,
    required: false,
    default: 'primary'
  },
  label: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  persistent: {
    type: Boolean,
    required: false,
    default: false
  },
  activatorId: {
    type: String,
    required: false,  
  },
  activatorSize: {
    type: String,
    required: false,
    default: 'small'
  },
  activatorBlock: {
    type: Boolean,
    required: false,
    default: false
  },
  activatorVariant: {
    type: String,
    required: false,
    default: undefined
  },
  density: {
    type: String,
    required: false,
    default: 'compact'
  },
  size: {
    type: String,
    required: false,
    default: 'normal'
  },
  activatorClass: {
    type: String,
    required: false,
    default: ''
  },
  loading: {
    required: false,
    default: false
  }
});

const emit = defineEmits(['update:modelValue']);
const localValue = ref(false);

const hasModelValue = ref(props.modelValue !== undefined);

const value = computed({
  get: () => hasModelValue.value ? props.modelValue : localValue.value,
  set: (value) => hasModelValue.value ? emit('update:modelValue', value) : (localValue.value = value)
});
</script>
