import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ref } from "vue";

function addWatermark(canvas: HTMLCanvasElement) {

}

export const useDownload = () => {
  const isLoading = ref(false);

  const _downloadAsPng = async (elementId: string, opts?: any) => {
    try {
      isLoading.value = true;
      await downloadAsPng(elementId, opts)
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    downloadAsPng: _downloadAsPng,
  }
}

export const download = async (elementId: string, opts = {}) => {
  const element = document.getElementById(elementId);

  if (element) {
    const rect = element.getBoundingClientRect();
    const width = opts.width ?? Math.round(rect.width);
    const screenWidth = opts.screenWidth ?? window.innerWidth;
    const isMobile = window.innerWidth < 600 && rect.height > 1000;


    return html2canvas(element, {
      width: width,
      windowWidth: screenWidth,
      useCORS: true,
      backgroundColor: null,
      scale: isMobile ? 1 : undefined,
      onclone: (clonedDoc) => {
        const vertical = clonedDoc.querySelector('.vertical-text-center');
        if (vertical) {
          vertical.classList.remove('vertical-text-center');
          vertical.classList.add('fallback-vertical');
        }
      },
    }).then((canvas) => {
      addWatermark(canvas);
      return canvas;
    });
  }
}

export const downloadAsPng = (elementId: string, opts = {}) => {
  return download(elementId, opts).then((canvas) => {
    if (!canvas) {
      return;
    }
    const link = document.createElement('a');
    link.download = opts.fileName + '.png';
    link.href = canvas.toDataURL('image/png');
    link.click();
  });
}

export const downloadAsPdf = (elementId: string) => {
  download(elementId).then((canvas) => {
    if (!canvas) {
      return;
    }
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [canvas.width, canvas.height],
    });

    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("download.pdf");
  });
}
