<template>
  <v-alert
    color="amber"
    density="compact"
    rounded="md"
  >
    <div class="text-body-1 d-flex flex-column ga-2 justify-space-between">
      {{ $t('header.upgrade_to_premium') }}
      <v-btn color="blue" :elevation="10" :to="{ name: 'subscribe' }">
        {{ $t('header.upgrade') }}
      </v-btn>
    </div>
  </v-alert>
</template>