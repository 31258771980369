<script setup lang="ts">
</script>

<template>
   <h1 class="mt-10 text-center">
    {{ $t('subscription.cancel_title') }}
  </h1>
  <p class="text-center">
    {{ $t('subscription.cancel_description') }}
  </p>
</template>
