<template>
  <div class="d-flex justify-space-between flex-row">
    <div class="d-flex flex-row align-center">
      <avatar
        :size="size"
        :code="codeValue"
        :logo="logoValue"
        :color="avatarColor"
        :avatarClass="avatarClass"
        class="mr-2"
        v-if="showLogo"
      />
      <router-link
        v-if="showCode"
        :to="toValue"
        class="font-weight-bold text-truncate mr-2"
        :class="textClasses">{{ codeValue }}</router-link>
      <router-link
        :style="{
          maxWidth: flavour === 'default' ? '200px' : 'inherit'
        }"
        v-if="showName"
        :to="toValue"
        class="text-truncate"
        :class="{
          ...textClasses,
          'd-none': showCode,
          'd-lg-inline': showCode
        }"
      >{{ nameValue }}</router-link>
      <span v-else-if="showRegularName" class="text-truncate" :class="textClasses">{{ nameValue }}</span>
    </div>
    <v-chip v-if="showPortfolios && !!holding.parent && holding.parent.portfolios.length > 1" color="secondary" size="x-small" class="d-none d-lg-flex">
      {{ holding.portfolios[0].name }}
    </v-chip>
  </div>
</template>

<script setup lang="ts">
import { computed, h } from 'vue';
import Avatar from './Avatar.vue';
import { HoldingType, InstrumentType } from '~/enums';
import { useInvestment } from '@/utils/instrument.utils';

const props = defineProps({
  code: String,
  logo: String,
  route: null,
  name: String,
  isCash: Boolean,
  isLoan: Boolean,
  showPortfolios: Boolean,
  holding: Object,
  size: {
    default: 24
  },
  flavour: {
    default: 'default'
  },
  avatarClass: {
    default: ''
  }
});

const { codeValue, logoValue } = useInvestment(
  computed(() => props.holding),
  computed(() => props.code),
  computed(() => props.logo),
)

const nameValue = computed(() => {
  if (props.name) {
    return props.name;
  } else if (props.holding?.instrument?.name) {
    return props.holding.instrument.name;
  } else if (props.holding?.name) {
    return props.holding.name;
  }
});

const toValue = computed(() => {
  if (props.route) {
    return props.route;
  } else if ([HoldingType.Loan, HoldingType.Cash].includes(props.holding.type)) {
    return `/${props.holding.type === 'loan' ? 'loan' : 'account'}/${props.holding.id}`
  } else if (props.holding.id) {
    return `/investment/${props.holding.id}`;
  }
});

const textClasses = computed(() => {
  return {
    'text-secondary': props.holding?.hasActiveInvestments !== false,
    'text-decoration-line-through': props.holding?.isArchived,
    'text-disabled': props.holding?.isArchived || props.holding?.hasActiveInvestments === false
  };
});

const showLogo = computed(() => {
  return ['default', 'name-with-logo', 'only-logo'].includes(props.flavour);
});

const showCode = computed(() => {
  return ['default', 'short'].includes(props.flavour);
});

const showRegularName = computed(() => {
  return !['short', 'only-logo'].includes(props.flavour) && !isCash.value && !isLoan.value
});

const showName = computed(() => {
  return toValue.value && nameValue.value !== codeValue.value && !['only-logo'].includes(props.flavour);
});

const isCash = computed(() => {
  return props.isCash || props.holding?.type === HoldingType.Cash;
});

const isInstrument = computed(() => {
  return props.holding?.isPublic !== undefined
});

const isLoan = computed(() => {
  if (isInstrument.value) {
    return props.holding?.type === InstrumentType.TakenLoan;
  }
  return props.isLoan || props.holding?.type === HoldingType.Loan;
});

const avatarColor = computed(() => {
  if (isLoan.value) {
    return 'amber';
  } else if (isCash.value) {
    return 'blue'
  }
});
</script>