import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import { router } from './router';
import vuetify from './plugins/vuetify';
import '@/scss/style.scss';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/style.css';
import CleaveDirective from './plugins/cleave';
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Highcharts } from './plugins/highcharts'
import { registerSW } from 'virtual:pwa-register'
import { i18n } from './plugins/i18n';

const updateSW = registerSW({
  immediate: true,
  onNeedRefresh() {
    useAppStore().showNotice(i18n.global.t('serviceworker.update.message'), 'info', {
      title: i18n.global.t('serviceworker.update.title'),
      timeout: -1,
      cb: () => {
        useAppStore().hideNotice();
        updateSW(true);
      },
      button: {
        label: i18n.global.t('serviceworker.update.button'),
      }
    });
  },
  onOfflineReady() {
  },
  onRegistered(swRegistration) {
    swRegistration && setInterval(() => {
      swRegistration.update()
    }, 60 * 60 * 1000)
  },
  onRegisterError(e) {
    console.log('onRegisterError', e);
  }
})

dayjs.extend(customParseFormat)

import { vMaska } from 'maska';
import { useApp } from './plugins/app';
import marketing from './plugins/marketing';
import { useAppStore } from './stores/app';
//i18

const app = createApp(App);

app.config.errorHandler = function (err, vm, info) {
  console.log(err)
  // @ts-expect-error
  if (window.__APP_VERSION__ && window.__APP_VERSION_BACKEND__) {
    // @ts-expect-error
    if (window.__APP_VERSION__ !== window.__APP_VERSION_BACKEND__ && sessionStorage.getItem('version') !== window.__APP_VERSION__) {
      // @ts-expect-error
      sessionStorage.setItem('version', window.__APP_VERSION__);
      // @ts-expect-error
      window.newrelic?.log(`Version mismatch: ${window.__APP_VERSION__} !== ${window.__APP_VERSION_BACKEND__}`);
      // @ts-expect-error
      location.reload(true);
      return;
    }
  }
  // @ts-expect-error
  window.newrelic?.noticeError(err);
  return true;
}

window.onerror = function (msg, url, line, col, error) {
  console.log('Error:', msg, 'URL:', url, 'Line:', line, 'Column:', col, 'Error object:', error);
  return true;
}

// @ts-expect-error
window.__APP_VERSION__ = __APP_VERSION__;

useApp(app);
app.use(router);
app.use(PerfectScrollbarPlugin);
app.use(createPinia());
app.use(i18n);
app.use(marketing);
app.directive('maska', vMaska);
app.directive('cleave', CleaveDirective);
app.component('highcharts', Highcharts);
app.use(vuetify).mount('#app');

