<script lang="ts" setup>
import { computed } from 'vue';
import { HoldingType } from '~/enums';

const props = defineProps({
  code: {
    type: String,
    required: false,
  },
  logo: {
    type: String,
    required: false,
  },
  size: {
    type: Number,
    default: 24,
  },
  color: {
    type: String,
    default: 'primary-lighten-1',
  },
  investmentType: {
    type: String,
    required: false,
  },
  avatarClass: {
    type: String,
    required: false,
    default: ''
  }
});

const calculatedColour = computed(() => {
  return {
    [HoldingType.Loan]: 'amber',
    [HoldingType.Cash]: 'blue',
    [HoldingType.Investment]: props.color,
  }[props.investmentType || ''] || props.color;
});
</script>

<template>
  <v-avatar :color="calculatedColour" :size="size" :class="avatarClass">
    <v-img :src="logo" cover>
      <template v-slot:placeholder>
        <div class="d-flex align-center justify-center fill-height" :style="`font-size:${size/2}px`">
          {{ code?.substring(0, 2) }}
        </div>
      </template>
    </v-img>
  </v-avatar>
</template>