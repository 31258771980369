<template>
  <DisplayCard
    :title="$t('loan_page.allocation_page.title')"
    :help-text="$t('loan_page.allocation_page.help_text')"
    variant="text"
    color="panel-heading-bg"
  >
    <template v-slot:actions>
      <v-btn
        @click="addAllocation"
        prepend-icon="mdi-plus"
        size="small"
        variant="flat"
        :disabled="!authStore.isEnabled(FeatureFlag.MultiLoanAllocation)"
        color="blue">
          {{ $t('loan_page.allocation_page.add_allocation') }}
      </v-btn>
    </template>
    <div class="pa-0">
      <v-table class="radius-0" :hover="true">
        <thead>
          <tr class="bg-table-heading-bg">
            <th style="width:1%;">
              <div class="d-flex align-center ga-1">
                <v-icon>mdi-calendar</v-icon>
                {{ $t('label.period') }}
              </div>
            </th>
            <th v-for="holding in holdingRows" :key="holding.id">
              <div class="d-flex align-center ga-1" style="min-width:150px;">
                <v-icon>mdi-home-city</v-icon>
                {{ holding.name }}
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(allocation,i) in allocationsMapped" :key="allocation.date">
            <td class="text-body-1 font-weight-medium" style="white-space:nowrap;">
              {{ dateFormatter(allocation.date) }}
              -
              {{ dateFormatter(allocation.dateEnd, '...') }}
            </td>
            <td v-for="i in allocation.allocations" :key="i.holding_id" :class="getClass(i.allocation)">
              <template v-if="i.allocation">{{ percentFormatter(i.allocation / 100) }}</template>
            </td>
            <td class="text-right">
              <v-btn
                @click="editAllocation(allocation)"
                icon
                color="primary"
                size="x-small"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr v-if="allocationsMapped.length === 0">
            <td colspan="100%" class="text-center">
              {{ $t('label.no_data') }}
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </DisplayCard>
  <v-dialog
    v-model:model-value="showModal" 
    transition="dialog-bottom-transition"
    :max-width="700"
    :persistent="false"
    scrollable
  >
    <FormContainer
      :title="formMode === 'create' ? $t('loan_page.allocation_page.add_form_title') : $t('loan_page.allocation_page.edit_form_title')"
      :color="'panel-heading-bg'"
      :is-button-loading="isSaving"
      :mode="formMode"
      @delete="onDelete"
      @submit="saveAllocation"
      ref="formRef"
    >
      <template v-slot:title-actions>
        <v-btn variant="plain" icon="mdi-close" @click="showModal = false"></v-btn>
      </template>
      <v-row>
        <v-col>
          <DateField
            v-model="form.date"
            :label="$t('label.start_date')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-table density="compact">
            <thead>
              <tr class="bg-table-heading-bg">
                <th>
                  {{ $t('label.investment') }}
                </th>
                <th>
                  {{ $t('label.allocation') }}
                </th>
                <th>
                  <v-btn
                    @click="form.allocations.push({ holding_id: undefined, allocation: 100 })"
                    icon
                    color="success"
                    size="x-small"
                    :disabled="!authStore.isEnabled(FeatureFlag.MultiLoanAllocation)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, k) in form.allocations" :key="item.holding_id">
                <td class="py-2">
                  <InvestmentSelectField
                    v-model:model-value="item.holding_id"
                    :items="selectOptions"
                    :return-object="false"
                    :field-design="{...fieldDesign, density:'compact'}"
                    :chips="false"
                    :label="''"
                    :rules="[rules.required, uniqueRule]"
                  />
                </td>
                <td class="py-2">
                  <AmountField
                    v-model="item.allocation"
                    :rules="[rules.min(0), rules.max(100), ...(k + 1 === form.allocations.length ? [totalRule] : [])]"
                    :design="{density:'compact', ...fieldDesign}"
                    :label="undefined"
                    :disabled="!authStore.isEnabled(FeatureFlag.MultiLoanAllocation)"
                    hideCurrency
                    @input="formRef?.validate()"
                  >
                    <template v-slot:append-inner>
                      %
                    </template>
                  </AmountField>
                </td>
                <td style="width:1%;" class="text-right">
                  <v-btn
                    @click="form.allocations = form.allocations.filter((a) => a.holding_id !== item.holding_id)"
                    icon
                    color="error"
                    size="x-small"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </FormContainer>
  </v-dialog>
</template>

<script setup lang="ts">
import { usePortfolioStore } from '@/stores/portfolioStore';
import { computed, onMounted, ref } from 'vue';
import { FeatureFlag, InstrumentType } from '~/enums';
import { rules, fieldDesign, dateFormatter, percentFormatter } from '@/const';
import InvestmentSelectField from '@/components/inputs/InvestmentSelectField.vue';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { useLoanStore } from '@/stores/loanAccountStore';
import dayjs from 'dayjs';
import FormContainer from '@/components/shared/FormContainer.vue';
import { useAuthStore } from '@/stores/authStore';

const portfolioStore = usePortfolioStore();
const loanStore = useLoanStore();
const authStore = useAuthStore();

const showModal = ref(false);
const isSaving = ref(false);
const formMode = ref('create');
const formRef = ref(null);

const form = ref<{
  id: number | undefined,
  date: string | null,
  allocations: { holding_id: number | undefined, allocation: number }[],
}>({
  id: undefined,
  date: null,
  allocations: [],
});
const allocations = ref<{
  id: number,
  date: string,
  allocations: { holding_id: number, allocation: number }[],
}[]>([]);

const realestate = computed(() => {
  if (!Array.isArray(portfolioStore.portfolioInvestments.data)) {
    return [];
  }
  return (portfolioStore.portfolioInvestments.data).filter((investment) => investment.instrument?.type === InstrumentType.RealEstate);
});

const allocationsMapped = computed(() => {
  return allocations.value.map((allocation, i) => {
    const next = getNextByIndex(i);
    return {
      id: allocation.id,
      date: allocation.date,
      dateEnd: next ? dayjs(next?.date).subtract(1, 'day').format('YYYY-MM-DD') : '...',
      allocations: holdingRows.value.map((holding) => {
        const holdingAllocation = allocation.allocations.find((a) => a.holding_id === holding.id);
        return {
          holding_id: holding.id,
          allocation: holdingAllocation?.allocation || 0,
        }
      })
    }
  });
});

const holdingRows = computed(() => {
  const holdingIds = allocations.value.reduce((acc, allocation) => {
    allocation.allocations.forEach((holding) => {
      if (!acc[holding.holding_id]) {
        acc[holding.holding_id] = 1
      }
    });
    return acc;
  }, {} as Record<number, number>);

  return Object.keys(holdingIds)
    .map((holdingId) => {
      const holding = realestate.value.find((investment) => investment.id === Number(holdingId));
      return holding || {
        id: Number(holdingId),
      }
    })
})

const editAllocation = (allocation: any) => {
  form.value.id = allocation.id;
  form.value.date = allocation.date;
  form.value.allocations = allocation.allocations;
  showModal.value = true;
  formMode.value = 'update';
}

const addAllocation = () => {
  const last = allocations.value[allocations.value.length - 1];
  form.value.id = undefined;
  form.value.date = dayjs().format('YYYY-MM-DD');
  form.value.allocations = last ? last.allocations.map((a) => ({ ...a })) : [];
  showModal.value = true;
  formMode.value = 'create';
}

const getNextByIndex = (index: number) => {
  return allocations.value[index + 1];
}

const getClass = (allocation: number) => {
  if (allocation >= 90) {
    return 'bg-green-lighten-1';
  } else if (allocation >= 70) {
    return 'bg-green-lighten-2';
  } else if (allocation >= 50) {
    return 'bg-green-lighten-3';
  } else if (allocation >= 30) {
    return 'bg-green-lighten-4';
  } else if (allocation > 0) {
    return 'bg-green-lighten-5';
  } else {
    return '';
  }
}

const selectOptions = computed(() => {
  return realestate.value.map((investment) => ({
    ...investment,
    ...investment.instrument,
    id: investment.id,
  }));
})

const getAllocations = async () => {
  try {
    const response = await fetchWrapper.get(`/api/v1/account/${loanStore.loanId}/allocation`);
    allocations.value = response;
  } catch (error) {
    console.error(error);
  }
}

const saveAllocation = async () => {
  try {
    isSaving.value = true;
    await fetchWrapper.post(`/api/v1/account/${loanStore.loanId}/allocation`, {
      id: form.value.id,
      date: form.value.date,
      allocations: form.value.allocations,
    });
    await getAllocations();
    await loanStore.get(loanStore.loanId ?? '0', true);
  } finally {
    showModal.value = false;
    isSaving.value = false;
  }
}

const onDelete = async () => {
  try {
    isSaving.value = true;
    await fetchWrapper.delete(`/api/v1/account/${loanStore.loanId}/allocation/${form.value.id}`);
    await getAllocations();
    await loanStore.get(loanStore.loanId ?? '0', true);
  } finally {
    showModal.value = false;
    isSaving.value = false;
  }
}

const totalRule = () => {
  const total = form.value.allocations.reduce((acc, a) => acc + parseFloat(a.allocation.toString()), 0);
  return total === 100 || 'Total allocation must be 100%';
}

const uniqueRule = (value: any) => {
  const ids = form.value.allocations.map((a) => a.holding_id);
  return ids.length === new Set(ids).size || 'Investment must be unique';
}

onMounted(() => {
  getAllocations();
})
</script>


<style scoped lang="scss">
:deep(tbody tr:nth-of-type(even)) {
  background-color: rgba(0, 0, 0, .03);
}
.border :deep(table) {
  border: 1px solid #e0e0e0;
}
</style>
