<template>
  <v-dialog v-model:model-value="visible"  transition="dialog-bottom-transition" :max-width="maxWidth" :persistent="false" scrollable>
    <v-card>
      <v-toolbar :color="color">
        <v-toolbar-title>
          <div class="d-flex align-center ga-2">
            <v-icon v-if="titleIcon" size="24">{{ titleIcon }}</v-icon>
            {{ title }}
          </div>
        </v-toolbar-title>
        <v-btn v-if="!hideClose" icon="mdi-close" @click="visible = false"></v-btn>
      </v-toolbar>
      <v-card-text class="pa-0">
        <div class="pa-4">
          <slot></slot>
        </div>
        <div v-if="confirm?.show">
          <v-divider class="my-0"></v-divider>
          <div class="d-flex justify-end pa-4 py-4">
            <v-btn
              @click="emit('confirm')"
              :disabled="confirm.disabled"
              :variant="confirm.variant"
              :loading="!!confirm.loading"
              :color="confirm.color">{{ confirm.label }}</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  modelVisible: Boolean,
  title: String,
  color: {
    default: 'primary-lighten-1'
  },
  titleIcon: {
    default: ''
  },
  maxWidth: {
    default: 500
  },
  hideClose: {
    type: Boolean,
    default: false
  },
  confirm: {
    default: {
      show: false,
      label: 'Kinnita',
      color: 'warning',
      variant: undefined,
      disabled: false,
      loading: false
    }
  }
});

const emit = defineEmits(['update:modelVisible', 'confirm']);

const visible = computed({
  get: () => props.modelVisible,
  set: (value) => {
    emit('update:modelVisible', value)
  }
});

</script>