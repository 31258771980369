<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { fieldDesign } from '@/const';
import { useLazyFetch } from '@/utils/reactivity.utils';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import Avatar from '../Avatar.vue';

const emit = defineEmits(['update:modelValue', 'update:modelObject']);

const props = defineProps({
  modelValue: null,
  modelObject: null,
  country: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  fieldDesign: {
    type: Object,
    default: () => fieldDesign
  },
  label: {
    type: String,
    default: () => 'Institution'
  },
  returnObject: {
    type: Boolean,
    default: true
  },
  loading: {
    type: Boolean,
    default: false
  }
});

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const { t } = useI18n();

const institutions = useLazyFetch<{ id: string, name: string, logo: string }[]>(async () => {
  if (!props.country) {
    return [];
  }
  return fetchWrapper.get(`/api/v1/import/institutions?country=${props.country}`);
}, []);

const items = computed(() => {
  return institutions.data.value.map((bank: any) => {
    return {
      id: bank.id,
      name: bank.name,
      logo: bank.logo,
    }
  });
});

const customFilter = (itemString: string, queryText: string, item: any) => {
  const textOne = item.raw?.name?.toLowerCase() || '';
  const textTwo = item.raw?.id?.toLowerCase() || '';

  return textOne.indexOf(queryText.toLowerCase()) > -1 || textTwo.indexOf(queryText.toLowerCase()) > -1;
}
</script>

<template>
  <v-autocomplete
    v-bind="(fieldDesign as any)"
    v-model="value"
    :items="items"
    item-title="name"
    item-value="id"
    :label="label"
    :return-object="returnObject"
    clearable
    :disabled="disabled"
    :multiple="false"
    :custom-filter="customFilter"
    :loading="institutions.loading.value"
    clear-on-select
  >
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :title="''" density="compact">
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
        <template v-slot:prepend>
          <Avatar :logo="item.raw.logo" />
        </template>
      </v-list-item>
    </template>
    <template v-slot:prepend-inner v-if="value">
      <Avatar :logo="value.logo" />
    </template>
  </v-autocomplete>
</template>