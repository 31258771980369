import BlankLayout from '@/layouts/blank/BlankLayout.vue';
import LoginPage from '@/views/authentication/auth/LoginPage.vue';
import RegisterPage from '@/views/authentication/auth/RegisterPage.vue';
import AuthLayout from '@/views/authentication/AuthLayout.vue';
import Error404Page from '@/views/pages/maintenance/error/Error404Page.vue';
import AuthorizeDevicePage from '@/views/authentication/auth/AuthorizeDevicePage.vue';

const AuthRoutes = {
  path: '/auth',
  component: BlankLayout,
  meta: {
    requiresAuth: false
  },
  children: [
    {
      path: '/auth',
      component: AuthLayout,
      children: [{
        name: 'login',
        path: '/auth/login',
        component: LoginPage,
        meta: {
          title: 'login'
        }
      }, {
        name: 'Register',
        path: '/register/:promoCode?',
        alias: '/auth/register',
        component: RegisterPage,
        meta: {
          title: 'Register'
        },
        beforeEnter: (_to: any, _from: any, next: any) => {
          const promoCode = _to.params.promoCode;
          if (promoCode) {
            localStorage.setItem('promoCode', promoCode);
          }
          next();
        }
      }, {
        name: 'Forgot Password',
        path: '/auth/forgot-pwd',
        component: () => import('@/views/authentication/auth/ForgotPwd.vue'),
        meta: {
          title: 'Forgot password'
        }
      }, {
        name: 'Reset Password',
        path: '/auth/reset-password/:token',
        component: () => import('@/views/authentication/auth/ResetPwd.vue'),
        meta: {
          title: 'Reset password'
        }
      },  {
        name: 'authorize-device',
        path: '/auth/device',
        component: AuthorizeDevicePage,
        meta: {
          title: 'Authorize Device',
          requiresAuth: true
        }
      }]
    },
    {
      name: 'Error 404',
      path: '/pages/error',
      component: Error404Page,
      meta: {
        title: 'Error 404'
      }
    }
  ]
};

export default AuthRoutes;
