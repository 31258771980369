<script setup lang="ts">
import { computed, ref, watch, type PropType } from 'vue';
import PriceCell from '@/components/table/PriceCell.vue';
import { useI18n } from 'vue-i18n';
import DateCell from '@/components/table/DateCell.vue';
import DisplayCard from '@/components/shared/DisplayCard.vue';
import { usePagination } from '@/filter.helper';
import { useAccountStore } from '@/stores/accountStore';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useLazyFetch } from '@/utils/reactivity.utils';
import BalanceForm from './BalanceForm.vue';
import type { AccountDtoType } from '~/cash-account.schema';

const emits = defineEmits(['edit:balance', 'link']);

const { t } = useI18n();
const { page, perPage, perPageOptions } = usePagination()
const accountStore = useAccountStore();
const portfolioStore = usePortfolioStore();

const isDeleteActionLoading = ref(false);
const portfolio = computed(() => portfolioStore.activePortfolio);
const balanceForm = ref<HTMLElement | null>(null);

defineProps({
  account: {
    type: Object as PropType<AccountDtoType>,
    required: true,
  }
});

const {
  data: balanceItems,
  loading: balanceItemsLoading,
  refresh: refreshBalanceItems,
} = useLazyFetch<{}>(async () => {
  if (!accountStore.accountId) {
    return {};
  }
  return await accountStore.getCashAccountBalanceItems(accountStore.accountId, {
    limit: perPage.value,
    offset: (page.value - 1) * perPage.value,
  });
}, {})

const headers = ref([{
  title: t('label.date'),
  align: 'start',
  sortable: false,
  key: 'date',
}, {
  title: t('label.balance'),
  align: 'end',
  sortable: false,
  key: 'balance',
}, {
  align: 'end',
  title: '',
  key: 'actions',
  sortable: false,
  width: '40px'
}]);

const del = async (item: any) => {
  try {
    isDeleteActionLoading.value = true;
    await fetchWrapper.delete(`/api/v1/account/${accountStore.account.id}/balance/${item.id}`);
    refreshBalanceItems();
  } finally {
    isDeleteActionLoading.value = false;
  }
}
</script>

<template>
  <div class="d-flex flex-column ga-5">
  <BalanceForm
    :account="account"
    ref="balanceForm"
    @link="$emit('link')"
  />

  <DisplayCard :title="$t('account_page.balance_history')" color="panel-heading-bg">
    <v-data-table-server
      class="mb-4"
      density="compact"
      v-model:items-per-page="perPage"
      v-model:page="page"
      :headers="headers"
      :items-length="balanceItems.totalItems ?? 0"
      :items="balanceItems.items"
      :loading="balanceItemsLoading"
      item-value="name"
      :items-per-page-options="perPageOptions"
    >
      <template v-slot:item.date="{ value }">
        <DateCell :value="value" />
      </template>
      <template v-slot:item.balance="{ value, item }">
        <PriceCell :value="value" :currency-code="item.currencyCode" isTotalValue adjustSign />
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-center" v-if="portfolio?.canEdit">
          <v-btn variant="plain" @click="balanceForm?.onEdit(item)" size="small" icon="mdi-pencil" />
          <v-btn variant="plain" @click="del(item)" :loading="isDeleteActionLoading" size="small" icon="mdi-delete" color="error" />
        </div>
      </template>
      <template v-slot:no-data>
        {{ t('label.no_data') }}
      </template>
    </v-data-table-server>
  </DisplayCard>
</div>
</template>
