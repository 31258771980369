<template>
   <v-select
    :label="$t('performance_bar.return_type')"
    v-model="returnType"
    :items="returnTypeOptions"
    hide-details
    class="text-left"
    variant="outlined"
    style="min-width: 200px;"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: String,
    default: 'auto'
  }
});

const { t } = useI18n();

const returnType = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const returnTypeOptions = computed(() => ([
  {
    value: 'auto',
    title: t('performance_bar.return_type_auto')
  },
  {
    value: 'annualized',
    title: t('performance_bar.return_type_annualized')
  },
  {
    value: 'cummulative',
    title: t('performance_bar.return_type_cummulative')
}]));
</script>
