<script setup lang="ts">
import groupBy from 'lodash/groupBy';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import AvatarVue from '../Avatar.vue';

const emit = defineEmits(['update:modelValue', 'update:modelObject']);

const props = defineProps({
  modelValue: null,
  modelObject: null,
  disabled: {
    type: Boolean,
    default: false
  },
  fieldDesign: {
    type: Object,
    default: () => ({})
  },
  label: {
    type: String,
    default: () => 'Konto'
  },
  showCreate: {
    type: Boolean,
    default: () => true
  },
  currencyCode: {
    type: String,
    required: false
  },
  hint: {
    type: String,
    required: false
  },
  items: {
    type: Array,
    required: true
  },
  multiple: {
    type: Boolean,
    default: false
  },
  returnObject: {
    type: Boolean,
    default: true
  },
  chips: {
    type: Boolean,
    default: true
  }
});

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const { t } = useI18n();

const itemsGrouped = computed(() => {
  const grouped = groupBy(props.items, 'type');
  const groupsSorted = Object.keys(grouped).sort((a, b) => {
    return t(`instrument_type.${a}`).localeCompare(t(`instrument_type.${b}`));
  })

  return groupsSorted.reduce((list, key) => {
    list.push({
      title: '',
      value: '',
      header: t(`instrument_type.${key}`),
    });

    list.push(...grouped[key]);

   
    return list;
  }, [] as any[]);
});

const removeItem = (item: any) => {
  // find by id and use splice
  const index = value.value.findIndex((i: any) => i.id === item.value);
  value.value.splice(index, 1);
}

const customFilter = (itemString: string, queryText: string, item: any) => {
  const textOne = item.raw?.name?.toLowerCase() || '';
  const textTwo = item.raw?.code?.toLowerCase() || '';

  return textOne.indexOf(queryText) > -1 || textTwo.indexOf(queryText) > -1;
}
</script>

<template>
  <v-autocomplete
    v-bind="(fieldDesign as any)"
    v-model="value"
    :items="itemsGrouped"
    item-title="name"
    item-value="id"
    :label="label"
    :return-object="returnObject"
    clearable
    :disabled="disabled"
    :multiple="multiple"
    :custom-filter="customFilter"
    :chips="chips"
    clear-on-select
  >
    <template v-slot:item="{ props, item }">
      <template v-if="item.value.header">
        <v-list-subheader>
          {{ item.value.header }}
        </v-list-subheader>
        <v-divider />
      </template>
      <v-list-item v-else v-bind="props" :title="''" density="compact">
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
        <template v-slot:prepend>
          <AvatarVue
            class="mr-n2"
            :size="24"
            :logo="item.raw.logo"
            :code="item.raw.code || item.title"
            :investmentType="item.raw.investmentType"
          />
        </template>
      </v-list-item>
    </template>
    <template v-slot:prepend-inner="{}">
      <v-icon>mdi-magnify</v-icon>
    </template>
    <template v-slot:chip="{item, props}" v-if="chips">
      <v-chip v-bind="props" close @click:close="removeItem(item)">
        <div class="d-flex align-center ga-1">
          <AvatarVue
            :size="16"
            :logo="item.raw.logo"
            :code="item.raw.code || item.title"
            :investmentType="item.raw.investmentType"
          />
          <span>{{ item.title }}</span>
        </div>
      </v-chip>
    </template>
  </v-autocomplete>
</template>