<script setup lang="ts">
import { currencyFormatter, percentFormatter, countFormatter } from '@/const';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { onBeforeMount, watch } from 'vue';
import { ref } from 'vue';
import StatsCard from '@/components/shared/StatsCard.vue';
import { CoinEuroIcon } from 'vue-tabler-icons';
import PerformanceBar from '@/components/PerformanceBar.vue';
import { instrumentConfig } from '~/config';
import PerformanceFilters from '@/components/PerformanceFilters.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import HoldingCell from '@/components/table/HoldingCell.vue';
import { useAuthStore } from '@/stores/authStore';
import { useReport } from '@/report.helper';
import { useRoute, useRouter } from 'vue-router';
import SummaryOnboarding from '@/components/onboarding/SummaryOnboarding.vue';
import UpgradeLock from '@/components/UpgradeLock.vue';
import HeaderCell from '@/components/table/HeaderCell.vue';
import { usePerformanceFilter } from '@/filter.helper';
import NoPricePolicy from '@/components/NoPricePolicy.vue';
import SettingsSidebar from '@/components/shared/SettingsSidebar.vue';
import { useLazyFetch } from '@/utils/reactivity.utils';
import type { SummaryDtoType } from '~/summary.schema';
import type { InstrumentType } from '~/enums';
import DownloadButton from '@/components/shared/DownloadButton.vue';

const portfolioStore = usePortfolioStore();
const authStore = useAuthStore();
const showOpenAndClosedPositions = ref(true);
const openAddNewMenu = ref(false);
const showMonetaryValue = ref(true);
const isOnobardingActive = ref(false);
const { summaryPeriod, summaryRange, summaryGroupBy, summaryReturnType } = usePerformanceFilter()
const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const columns = ref([
  { text: t('portfolio.price'), value: 'price', selected: true },
  { text: t('portfolio.quantity'), value: 'quantity', selected: true },
  { text: t('portfolio.value'), value: 'value', selected: true },
  { text: t('portfolio.valuePercentage'), value: 'percentage', selected: true },
  { text: t('portfolio.costbase'), value: 'costbase', selected: false },
  { text: t('portfolio.costbasePercentage'), value: 'costBasePercentage', selected: false },
  { text: t('performance.capital_gain'), value: 'capital', selected: true },
  { text: t('performance.fee_gain'), value: 'fees', selected: true },
  { text: t('performance.income_gain'), value: 'cashflow', selected: true },
  { text: t('performance.currency_gain'), value: 'currency', selected: true },
  { text: t('performance.total_gain'), value: 'total', selected: true },
]);

const columnsByKey = computed(() => {
  return columns.value.reduce((acc, column) => {
    acc[column.value] = column;
    return acc;
  }, {} as any);
});

const selectedColumnsCount = computed(() => {
  return columns.value.filter((column) => column.selected).length;
});

const {
  data: overview,
  loading: isLoading
} = useLazyFetch<SummaryDtoType>(() => {
  return portfolioStore.getSummary(
    portfolioStore.selectedPortfolioId,
    summaryPeriod.value,
    summaryRange.value,
    summaryGroupBy.value
  );
}, {} as SummaryDtoType)


watch(columns, (columnList) => {
  columnList.forEach((column) => {
    authStore.setUserPreference(`portfolio.holding.column.${column.value}`, column.selected);
  });
}, { deep: true });

const positionKey = computed(() => {
  return !showOpenAndClosedPositions.value ? 'openPositions' : 'openAndClosedPositions';
})

const returnTypeComputed = computed(() => {
  if (summaryReturnType.value === 'auto') {
    return overview.value?.['openAndClosedPositions']?.isAnnualized ? 'annualized' : 'cummulative';
  }
  return summaryReturnType.value === 'annualized' ? 'annualized' : 'cummulative';
})

const getKey = (name: string) => {
  if (showMonetaryValue.value) {
    return name + 'Gain';
  }
  const suffix = returnTypeComputed.value === 'annualized' ? 'Annualized' : 'Cummulative'
  const field = name + 'Return';

  return `${field}${suffix}`
}

const getFullKey = (name: string) => {
  return `${positionKey.value}.${getKey(name)}`;
}

const formatItem = (item: any, name: string) => {
  const performace = item[positionKey.value];
  const value = performace?.[getKey(name)];
  if (showMonetaryValue.value) {
    return currencyFormatter(value ?? 0, item.baseCurrencyCode ?? overview.value.currencyCode);
  } else {
    return percentFormatter(value ?? 0)
  }
}

const itemClass = (item: any, name: string, bg: boolean = false) => {
  const performace = item[positionKey.value];
  const value = performace?.[getKey(name)];

  return {
    'text-error': value < 0 && !bg,
    'bg-red': value < 0 && bg,
    'zero': value === 0 && !bg,
    'blur-text': item.isPartial && !bg,
    balance: showMonetaryValue.value
  }
}

const sorting = ref<{
  key: string,
  direction: 'asc' | 'desc'
}>({
  key: 'value',
  direction: 'desc'
})

const { itemsFalattened, isHoldingVisible, open, openGroup, toggleAll, toggleAllState, groupClass } = useReport(overview, computed(() => {
  return {
    key: !['value', 'percentage', 'costBasePercentage', 'quantity', 'costBaseInBaseCurrency'].includes(sorting.value.key) ? getFullKey(sorting.value.key) : sorting.value.key,
    direction: sorting.value.direction
  }
}))

const sort = (key: string) => {
  sorting.value.direction = sorting.value.key === key && sorting.value.direction === 'desc' ? 'asc' : 'desc';
  sorting.value.key = key;
}

const translations = computed(() => {
  return {
    tooltip: {
      capitalGain: t('performance.tooltip.capitalGain'),
      feeGain: t('performance.tooltip.feeGain'),
      incomeGain: t('performance.tooltip.incomeGain'),
      currencyGain: t('performance.tooltip.currencyGain'),
      totalGain: t('performance.tooltip.totalGain')
    }
  }
})

const tooltipOpts = {
  activator: 'parent',
  maxWidth: 250,
}

const goTo = (name: string) => {
  router.push({
    name: name,
    params: { id: route.params.id }
  });
}

const isAddMenuVisible = computed(() => {
  return !portfolioStore.selectedPortfolio?.isConsolidated && portfolioStore.selectedPortfolio?.canEdit;
})

onBeforeMount(() => {
  columns.value.forEach((column) => {
    column.selected = authStore.getUserPreference(`portfolio.holding.column.${column.value}`, column.selected);
  });
})
</script>

<template>
  <SummaryOnboarding
    v-model="isOnobardingActive"
    :openMenu="() => {
      openAddNewMenu = true;
    }"
  />
  <v-row id="downloadable">
    <v-col cols="12" class="order-sm-0" data-html2canvas-ignore="true">
      <v-row class="justify-end">
        <v-col cols="12" class="d-flex align-center ga-4">
          <h1 class="text-h1 text-truncate word-break text-darkprimary" v-if="$vuetify.display.smAndDown">
            {{ portfolioStore.selectedPortfolio?.name }}
          </h1>
          <v-spacer v-if="$vuetify.display.smAndDown" />
          <SettingsSidebar>
            <PerformanceFilters
              v-model="summaryPeriod"
              v-model:showMonetaryValue="showMonetaryValue"
              v-model:showOpenAndClosedPositions="showOpenAndClosedPositions"
              v-model:groupByValue="summaryGroupBy"
              v-model:range="summaryRange"
              v-model:return-type-value="summaryReturnType"
              :is-summary="true"
              :is-monetary-value-enabled="true"
              :is-return-type-enabled="true"
              narrow
            />
            <DropdownMenu
              activator-class="flex-grow-1 mt-4 mt-md-0"
              v-model="openAddNewMenu"
              v-if="isAddMenuVisible"
              activator-id="add-new-transaction"
              :activator-size="!$vuetify.display.smAndDown ? 'large' : 'default'"
              :activator-block="$vuetify.display.smAndDown"
              :label="$t('portfolio.add_new')"
              color="green"
              density="default"
              :items="[{
                value: 'investment',
                id: 'add-new-investment-transaction',
                onClick: () => goTo('add-investment'),
                title: $t('portfolio.add_new_investment_or_transaction'),
                icon: 'mdi-credit-card-outline'
              }, {
                value: 'cash-account',
                id: 'add-new-account',
                onClick: () => goTo('add-cash-account'),
                title: $t('cash_account.title'),
                icon: 'mdi-bank'
              }, {
                value: 'import',
                id: 'import-transactions',
                onClick: () => goTo('import'),
                title: $t('label.import'),
                icon: 'mdi-microsoft-excel'
              }, {
                value: 'opening-balance',
                id: 'opening-balance',
                onClick: () => goTo('open-balance'),
                title: $t('onboarding.add_opening_balances'),
                icon: 'mdi-format-list-numbered'
              }]"
            />
          </SettingsSidebar>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="overview.isPartial" data-html2canvas-ignore="true">
      <PartialReportAlert />
    </v-col>
    <v-col cols="12" sm="12" md="4" class="d-flex align-stretch order-0">
      <StatsCard
        id="portfolio-summary"
        :loading="isLoading"
        :summary="overview"
        :subtitle="$t('portfolio.summary.subtitle')"
        :icon="CoinEuroIcon"
        color="primary-lighten-1"
      />
    </v-col>
    <v-col cols="12" sm="12" md="8" class="d-flex align-stretch order-1">
      <PerformanceBar
        :loading="isLoading"
        :currencyCode="overview.currencyCode"
        :performance="overview.openAndClosedPositions"
        :isPartial="overview?.isPartial"
        :return-type="summaryReturnType"
        color="primary-lighten-4"
      />
    </v-col>
    <v-col cols="12" class="order-2">
      <v-card :variant="$vuetify.theme.current.dark ? 'outlined' : 'text'" elevation="4" class="overflow-hidden">
        <v-divider></v-divider>
        <v-table density="compact" :hover="true" class="summary">
          <v-overlay :model-value="isLoading" scrim="#FFF" contained persistent />
          <thead>
            <tr class="heading bg-primary-lighten-1">
              <th colspan="2" style="padding-left:0px !important;">
                <div class="d-flex align-center">
                  <v-btn size="x-small" @click="toggleAll" :icon="toggleAllState ? 'mdi-chevron-down' : 'mdi-chevron-right'" variant="plain" />
                  <div class="py-2 text-h4 mr-2">{{ $t('portfolio.holdings') }}</div>
                  <Filter :is-active="true" color="blue" icon="mdi-tune-variant" id="portfolio-visible-columns">
                    <h3 class="ml-1">
                      {{ $t('portfolio.visible_columns') }}
                    </h3>
                    <v-checkbox
                      v-for="column in columns"
                      :false-value="false"
                      hide-details
                      v-model="column.selected"
                      :label="column.text"
                      :value="true"
                      density="compact"
                    />
                  </Filter>
                </div>
              </th>
              <th class="text-right" v-if="columnsByKey.price.selected">
                {{ $t('portfolio.price') }}
              </th>
              <th class="text-right" v-if="columnsByKey.quantity.selected">
                {{ $t('portfolio.quantity') }}
              </th>
              <th class="text-right" v-if="columnsByKey.costbase.selected">
                <HeaderCell :title="$t('label.costbase')" sortingKey="costBaseInBaseCurrency" :sorting="sorting" @sort="sort" />
              </th>
              <th class="text-right" v-if="columnsByKey.costBasePercentage.selected">
                <HeaderCell :title="$t('portfolio.costbasePercentage')" sortingKey="costBasePercentage" :sorting="sorting" @sort="sort" />
              </th>
              <th class="text-right" v-if="columnsByKey.value.selected">
                <HeaderCell :title="$t('portfolio.value')" sortingKey="value" :sorting="sorting" @sort="sort" />
              </th>
              <th class="text-right" v-if="columnsByKey.percentage.selected">
                <HeaderCell :title="$t('portfolio.valuePercentage')" sortingKey="percentage" :sorting="sorting" @sort="sort" />
              </th>
              <th class="performance" v-if="columnsByKey.capital.selected">
                <HeaderCell :sortingKey="'capital'" :sorting="sorting" @sort="sort">
                  <span>
                    {{ $t('performance.capital_gain') }}
                    <v-tooltip v-bind="tooltipOpts" :text="translations.tooltip.capitalGain" />
                  </span>
                </HeaderCell>
              </th>
              <th class="performance" v-if="columnsByKey.fees.selected">
                <HeaderCell :sortingKey="'fees'" :sorting="sorting" @sort="sort">
                  <span>
                    {{ $t('performance.fee_gain') }}
                    <v-tooltip v-bind="tooltipOpts" :text="translations.tooltip.feeGain" />
                  </span>
                </HeaderCell>
              </th>
              <th class="performance" v-if="columnsByKey.cashflow.selected">
                <HeaderCell :sortingKey="'cashflow'" :sorting="sorting" @sort="sort">
                  <span>
                    {{ $t('performance.income_gain') }}
                    <v-tooltip v-bind="tooltipOpts" :text="translations.tooltip.incomeGain" />
                  </span>
                </HeaderCell>
              </th>
              <th class="performance" v-if="columnsByKey.currency.selected">
                <HeaderCell :sortingKey="'currency'" :sorting="sorting" @sort="sort">
                  <span>
                    {{ $t('performance.currency_gain') }}
                    <v-tooltip v-bind="tooltipOpts" :text="translations.tooltip.currencyGain" />
                  </span>
                </HeaderCell>
              </th>
              <th class="performance" v-if="columnsByKey.total.selected">
                <HeaderCell :sortingKey="'total'" :sorting="sorting" @sort="sort">
                  <div class="d-flex align-center">
                    <span>
                      {{ $t('performance.total_gain') }}
                      <v-tooltip v-bind="tooltipOpts" :text="translations.tooltip.totalGain" />
                    </span>
                  </div>
                </HeaderCell>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in itemsFalattened">
              <template v-if="item.isGroup">
                <tr v-show="item.group?.isOpen !== false" :key="i" :class="groupClass(item)">
                  <th colspan="2" class="name" style="width:100%;">
                    <div class="d-flex align-center">
                      <v-btn size="x-small" @click="openGroup(item)" :icon="item.isOpen ? 'mdi-chevron-down' : 'mdi-chevron-right'" variant="plain" />
                      <span class="d-block text-truncate">{{ item.title }}</span>
                    </div>
                  </th>
                  <th class="text-right balance" v-if="columnsByKey.price.selected"></th>
                  <th class="text-right balance" v-if="columnsByKey.quantity.selected"></th>
                  <th class="text-right balance" v-if="columnsByKey.costbase.selected">
                    {{ currencyFormatter(item.costBaseInBaseCurrency, overview.currencyCode) }}
                  </th>
                  <th class="text-right" v-if="columnsByKey.costBasePercentage.selected">
                    {{ percentFormatter(item.costBasePercentage) }}
                  </th>
                  <th class="text-right balance" v-if="columnsByKey.value.selected">
                    {{ currencyFormatter(item.value, overview.currencyCode) }}
                  </th>
                  <th class="text-right" v-if="columnsByKey.percentage.selected">
                    {{ percentFormatter(item.percentage) }}
                  </th>
                  <th class="performance" v-if="columnsByKey.capital.selected" :class="itemClass(item, 'capital')">
                    {{ formatItem(item, 'capital') }}
                  </th>
                  <th class="performance" v-if="columnsByKey.fees.selected" :class="itemClass(item, 'fees')">
                    {{ formatItem(item, 'fees') }}
                  </th>
                  <th class="performance" v-if="columnsByKey.cashflow.selected" :class="itemClass(item, 'cashflow')">
                    {{ formatItem(item, 'cashflow') }}
                  </th>
                  <th class="performance" v-if="columnsByKey.currency.selected" :class="itemClass(item, 'currency')">
                    {{ formatItem(item, 'currency') }}
                  </th>
                  <th class="performance" v-if="columnsByKey.total.selected" :class="itemClass(item, 'total')">
                    {{ formatItem(item, 'total') }}
                  </th>
                </tr>
              </template>
              <template v-else>
                <tr class="main bg-primary-lighten-5" :class="`level-${item.level}`" v-show="isHoldingVisible(item)" :key="i">
                  <td colspan="2" class="name" :class="{sub: !!item.parent}">
                    <div class="d-flex align-center" style="width:100%;">
                      <v-btn v-if="item.consolidation?.length" size="x-small" @click="open(item)" :icon="item.isOpen ? 'mdi-chevron-down' : 'mdi-chevron-right'" variant="plain" />
                      <HoldingCell :class="{[`pl-3`]: item.id}" :holding="item" />
                    </div>
                  </td>
                  <td class="text-right" v-if="columnsByKey.price.selected">
                    <span v-if="instrumentConfig[item.instrumentType as InstrumentType]?.hasPrice && item.consolidate === 'instrument'">
                      <span v-if="item.price !== null" class="balance">
                        {{ currencyFormatter(item.price, item.currencyCode, 3) }}
                      </span>
                      <NoPricePolicy size="16" v-else />
                    </span>
                  </td>
                  <td class="text-right balance" v-if="columnsByKey.quantity.selected">
                    <span v-if="instrumentConfig[item.instrumentType]?.showQuantity && item.consolidate === 'instrument'">{{ countFormatter(item.quantity) }}</span>
                  </td>
                  <td class="text-right balance" v-if="columnsByKey.costbase.selected">
                    <span v-if="!item.isPartial">
                      {{ currencyFormatter(item.costBaseInBaseCurrency, item.baseCurrencyCode) }}
                    </span>
                    <UpgradeLock size="16" v-else />
                  </td>
                  <td class="text-right" v-if="columnsByKey.costBasePercentage.selected">
                    <span v-if="!item.isPartial">
                      {{ percentFormatter(item.costBasePercentage) }}
                    </span>
                    <UpgradeLock size="16" v-else />
                  </td>
                  <td class="text-right balance" v-if="columnsByKey.value.selected">
                    <span v-if="!item.isPartial">
                      {{ currencyFormatter(item.value, item.baseCurrencyCode) }}
                    </span>
                    <UpgradeLock size="16" v-else />
                  </td>
                  <td class="text-right" v-if="columnsByKey.percentage.selected">
                    <span v-if="!item.isPartial">
                      {{ percentFormatter(item.percentage) }}
                    </span>
                    <UpgradeLock size="16" v-else />
                  </td>
                  <td class="performance" v-if="columnsByKey.capital.selected" :class="itemClass(item, 'capital')">{{ formatItem(item, 'capital') }}</td>
                  <td class="performance" v-if="columnsByKey.fees.selected" :class="itemClass(item, 'fees')">{{ formatItem(item, 'fees') }}</td>
                  <td class="performance" v-if="columnsByKey.cashflow.selected" :class="itemClass(item, 'cashflow')">{{ formatItem(item, 'cashflow') }}</td>
                  <td class="performance" v-if="columnsByKey.currency.selected" :class="itemClass(item, 'currency')">{{ formatItem(item, 'currency') }}</td>
                  <td class="performance" v-if="columnsByKey.total.selected" :class="itemClass(item, 'total')">{{ formatItem(item, 'total') }}</td>
                </tr>
              </template>
            </template>
          </tbody>
          <tbody v-if="!isLoading && !itemsFalattened?.length">
            <tr>
              <th :colspan="selectedColumnsCount + 2" class="pa-2">
                <v-alert color="amber" v-if="!portfolioStore.selectedPortfolio.hasAccounts ">
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      {{ $t('portfolio.no_data') }}
                    </div>
                    <v-btn
                      id="add-opening-balance"
                      :to="{ name: 'onboarding', params: { portfolioId: portfolioStore.selectedPortfolioId } }"
                      color="success">
                      <v-icon left>mdi-plus</v-icon>
                      {{ $t('portfolio.go_to_onboarding') }}
                    </v-btn>
                  </div>
                </v-alert>
                <div v-else>
                  {{ $t('portfolio.no_visible_data') }}
                </div>
              </th>
            </tr>
          </tbody>
          <tbody v-if="isLoading && itemsFalattened.length <= 0">
            <tr>
              <th :colspan="selectedColumnsCount + 2" class="pa-2">
                <div class="d-flex justify-center align-center" style="overflow: hidden;">
                  <v-progress-circular :size="150" color="secondary" indeterminate />
                </div>
              </th>
            </tr>
          </tbody>
          <tfoot>
            <tr class="bg-primary-lighten-1" v-if="!isLoading">
              <th colspan="2" class="name text-left">
                <span class="pl-6">
                  {{ $t('performance.grand_total') }}
                </span>
              </th>
              <th v-if="columnsByKey.price.selected"></th>
              <th v-if="columnsByKey.quantity.selected"></th>
              <th v-if="columnsByKey.costbase.selected" class="text-right balance">{{ currencyFormatter(overview.costBaseInBaseCurrency, overview.currencyCode) }}</th>
              <th v-if="columnsByKey.costBasePercentage.selected" class="text-right"></th>
              <th v-if="columnsByKey.value.selected" class="text-right balance">{{ currencyFormatter(overview.value, overview.currencyCode) }}</th>
              <th v-if="columnsByKey.percentage.selected" class="text-right"></th>
              <th v-if="columnsByKey.capital.selected" class="performance" :class="itemClass(overview, 'capital')">{{ formatItem(overview, 'capital') }}</th>
              <th v-if="columnsByKey.fees.selected" class="performance" :class="itemClass(overview, 'fees')">{{ formatItem(overview, 'fees') }}</th>
              <th v-if="columnsByKey.cashflow.selected" class="performance" :class="itemClass(overview, 'cashflow')">{{ formatItem(overview, 'cashflow') }}</th>
              <th v-if="columnsByKey.currency.selected" class="performance" :class="itemClass(overview, 'currency')">{{ formatItem(overview, 'currency') }}</th>
              <th v-if="columnsByKey.total.selected" class="performance" :class="itemClass(overview, 'total')">{{ formatItem(overview, 'total') }}</th>
            </tr>
          </tfoot>
        </v-table>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" class="d-flex justify-end">
      <DownloadButton
        element-id="downloadable"
        :elementWidth="1400"
        :screenWidth="1400"
        file-name="portfolio-summary"
      />
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.summary {
  .level-1 {
    .name {
      padding-left: 7px !important;
    }
  }

  .level-2 {
    .name {
      padding-left: 14px !important;
    }
  }

  .level-3 {
    .name {
      padding-left: 21px !important;
    }
  }

  .bg-secondary {
    th:nth-last-child(5) {
      border-left-color: transparent !important;
    }
  }

  .performance.zero {
    color: gray !important;
  }
}

.blur-text,
.performance.zero.blur-text {
  color: transparent !important; /* hide the original text color */
  text-shadow: 0px 0px 5px rgba(0,0,0,0.5); /* apply a text shadow with a blur radius */
}

.word-break {
  word-break: break-word; /* Works in most browsers */
  overflow-wrap: anywhere; /* Ensures breaking in modern browsers */
}
</style>
