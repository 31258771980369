<script setup lang="ts">
import { useAuthStore } from '@/stores/authStore';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { onMounted, computed, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { SubscriptionLimits } from '~/config/Feature.config'

const portfolioStore = usePortfolioStore();
const userStore = useAuthStore();
const authStore = useAuthStore();

const { t } = useI18n();
const emits = defineEmits(['click:outside']);

onMounted(() => {
  if (Array.isArray(portfolioStore.portfolios.value)) {
    return;
  }
  portfolioStore.getAll();
});

const allPortfolios = computed(() => {
  if (portfolioStore.portfolios.loading) {
    return [{
      id: 'loading',
      title: 'Loading...',
    }]
  } else if (portfolioStore.portfolios.error) {
    return [{
      id: 'error',
      title: 'Error loading portfolios',
    }]
  } else if (portfolioStore.portfolios.value.length === 0) {
    return []
  } else {
    return portfolioStore.portfolios
      .value?.filter(p => !p.isConsolidated)
      .map((portfolio) => ({
        id: portfolio.id,
        title: portfolio.name,
        portfolio: portfolio,
        isShared: portfolio.user.id !== userStore.user.id,
        to: { name: 'portfolio', params: { portfolioId: portfolio.id } },
        active: portfolio.id === portfolioStore.selectedPortfolioId
      }))
      .sort((a, b) => a.isShared ? 1 : (b.isShared ? -1 : 0)) ?? []
  }
});

const userPortfolios = computed(() => {
  return allPortfolios.value.filter(p => !p.isShared)
});

const sharedPortfolios = computed(() => {
  return allPortfolios.value.filter(p => p.isShared)
});

const consolidatedViews = computed(() => {
  if (portfolioStore.portfolios.loading) {
    return []
  } else if (portfolioStore.portfolios.error) {
    return []
  } else if (portfolioStore.portfolios.value.length === 0) {
    return []
  } else {
    return portfolioStore.portfolios.value?.filter(p => p.isConsolidated).map((portfolio) => ({
      id: portfolio.id,
      title: portfolio.name,
      portfolio: portfolio,
      isShared: portfolio.user.id !== userStore.user.id,
      to: { name: 'portfolio', params: { portfolioId: portfolio.id } },
      active: portfolio.id === portfolioStore.selectedPortfolioId
    })) ?? []
  }
});

const canAddPortfolio = computed(() => {
  return authStore.limits.data?.portfolios.availableLimit > 0;
});

const isLimitsLoading = computed(() => {
  return authStore.limits.loading;
});

const actions = computed(() => {
  return [{
    title: t('header.add_portfolio'),
    to: '/portfolio/create',
    visible: true,
    upgrade: !canAddPortfolio.value,
    id: 'add-portfolio-btn',
    icon: 'mdi-briefcase-plus-outline',
    iconSize: 24
  }, {
    title: t('header.add_consolidated_view'),
    to: '/portfolio/create/consolidated',
    visible: portfolioStore.portfolios.value?.length > 0,
    upgrade: !SubscriptionLimits[userStore.tier].consolidatedPortfolios,
    id: '',
    icon: 'mdi-briefcase-eye-outline',
    iconSize: 24
  }, {
    title: t('header.custom_instruments'),
    to: { name: 'all-instruments' },
    visible: true,
    id: '',
    icon: 'mdi-clipboard-text-outline',
    iconSize: 24
  }].filter(a => a.visible)
})

onBeforeMount(() => {
  authStore.limits.refresh();
});
</script>

<template>
  <v-list density="compact" class="overflow-hidden">
    <v-row no-gutters>
      <v-col cols="12">
        <template v-if="consolidatedViews.length > 0">
          <v-list-subheader class="font-weight-bold">
            {{ $t('header.consolidated_views') }}
          </v-list-subheader>
          <v-list-item v-for="(item) in consolidatedViews" :to="item.to" :active="item.active" :key="item.id" rounded="0" color="secondary" class="no-spacer">
            <template v-slot:prepend>
              <v-icon size="20" class="text-8 mr-2">mdi-briefcase-variant-outline</v-icon>
            </template>
            <v-list-item-title class="text-subtitle-1 font-weight-regular">{{ item.title }} </v-list-item-title>
          </v-list-item>
          <v-divider class="my-2"></v-divider>
        </template>
        <template v-if="userPortfolios?.length">
          <v-list-subheader class="font-weight-bold">
            {{ $t('header.portfolios') }}
          </v-list-subheader>
          <v-list-item v-for="(item) in userPortfolios" :to="item.to" :key="item.id" :active="item.active" rounded="0" color="secondary" class="no-spacer">
            <template v-slot:prepend> 
              <v-icon color="secondary" :size="20"  class="text-8 mr-2">mdi-briefcase-outline</v-icon>
            </template>
            <v-list-item-title class="text-subtitle-1 font-weight-regular">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
          <v-divider class="my-1"></v-divider>
        </template>
        <template v-if="sharedPortfolios?.length">
          <v-list-subheader class="font-weight-bold">
            {{ $t('header.shared_portfolios') }}
          </v-list-subheader>
          <v-list-item v-for="(item) in sharedPortfolios" :to="item.to" :key="item.id" :active="item.active" rounded="0" color="secondary" class="no-spacer">
            <template v-slot:prepend> 
              <v-icon size="20" class="text-8 mr-2">mdi-briefcase</v-icon>
            </template>
            <v-list-item-title class="text-subtitle-1 font-weight-regular">
              {{ item.title }}
              <span class="text-disabled font-weight-light">
                ({{ item.portfolio.user.email }})
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-divider class="my-2"></v-divider>
        </template>
      </v-col>
      <v-col cols="12" class="d-flex flex-column ga-1">
        <v-list-item v-for="(item, i) in actions" :to="item.upgrade ? { name: 'subscribe' } : item.to" :key="i" rounded="0" color="secondary" class="no-spacer" :id="item.id">
          <template v-slot:prepend>
            <v-icon class="text-8 mr-2" :size="item.iconSize">{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title class="text-subtitle-1 font-weight-regular d-flex justify-space-between align-center">
            <span :class="{'text-disabled': item.upgrade}">{{ item.title }}</span>
            <upgrade-lock :loading="isLimitsLoading" v-if="item.upgrade" size="22" tooltip="Sinu praegune pakett ei võimalda seda funktsionaalsust kasutada, palun telli- või vahet paketti!" />
          </v-list-item-title>
        </v-list-item>
      </v-col>
    </v-row>
  </v-list>
</template>