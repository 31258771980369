import { colors, currencyFormatter, darkerColors, icons, lighterColors, percentFormatter } from "@/const";
import { type Ref, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { TransactionType } from "~/enums";
import { i18n } from '@/plugins/i18n';
import dayjs from "dayjs";
import vuetify from "@/plugins/vuetify";
import { useTheme } from 'vuetify';
import { getGroupName } from "./instrument.utils";

export function useFutureCashflowReport (report: Ref<any>) {
  const { t } = useI18n();

  const isChartCummulative = ref(false)

  const chartCategories = computed(() => {
    return report.value?.summary?.groupSteps ?? [];
  });

  const summaryItems = computed(() => {
    return Object.entries(report.value?.summary?.byType ?? {}).map(([key, value]) => {
      return {
        type: key,
        // @ts-ignore
        ...value
      }
    }).filter((item) => {
      return item.totalCount > 0
    }).sort((a, b) => {
      return b.base - a.base
    })
  })

  const chartData = computed(() => {
    return summaryItems.value.map((item) => {
      let sum = 0;
      return {
        name: t(`cashflowTransactionType.${item.type}.title`),
        data: chartCategories.value.map((category: string) => {
          const itemValue = item.grouped[category] ?? 0
          sum += itemValue
          const show = isChartCummulative.value ? sum : itemValue
          return item.grouped[category] || isChartCummulative.value ? Math.round(show * 100) / 100 : null
        })
      }
    })
  })

  return {
    chartData,
    chartCategories,
    summaryItems,
    isChartCummulative
  }
}

export function usePerformanceReport (reports: Ref<any>, opts: {
  summaryInterval: Ref<string>,
  showAverages: Ref<boolean>,
  aggregationType: Ref<string>
}) {
  const { t } = useI18n();
  const theme = useTheme();

  const isChartCummulative = ref(false)

  const formatDateToCategory = (date: string, interval: string) => {
    if (interval === 'month') {
      return dayjs(date).format('YYYY-MM')
    } else if (interval === 'year') {
      return dayjs(date).format('YYYY')
    } else if (interval === 'quarter') {
      return dayjs(date).format('YYYY') + ' Q' + Math.ceil(dayjs(date).month() / 3)
    } else {
      return dayjs(date).format('YYYY-MM-DD')
    }
  }

  const mainReport = computed(() => {
    return reports.value[0] || {};
  });

  const chartCategories = computed(() => {
    const categories: Record<string, string> = {}

    mainReport.value.items?.[opts.aggregationType.value]?.forEach((item: any) => {
      const category = formatDateToCategory(item.date, reports.value[0].params.interval)
      if (!categories[category]) {
        categories[category] = category;
      }
    })

    return Object.keys(categories).sort();
  });

  const calculateAverage = (data: any[]) => {
    return data.reduce((acc: number, item: any) => acc + item.y, 0) / data.length;
  }

  const chartData = computed(() => {
    let lines = reports.value.map((report: any, i: number) => {
      return {
        type: opts.aggregationType.value === 'cummulative' ? 'area' : 'column',
        name: report.title,
        color: colors[i],
        dashStyle: 'solid',
        yAxis: 0,
        threshold: 0,
        fillOpacity: 0.8,
        zIndex: i == 0 ? 9 : i,
        data: report.items[opts.aggregationType.value].map((item: any) => ({
          category: formatDateToCategory(item.date, report.params.interval),
          x: chartCategories.value.indexOf(formatDateToCategory(item.date, report.params.interval)),
          y: item.totalReturn * 100,
          color: i >= 0 ? undefined : item.totalReturn < 0 ? vuetify.theme.current.value.colors.error : vuetify.theme.current.value.colors.success
        })).filter((item: any) => chartCategories.value.includes(item.category))
      }
    })


    lines = lines.reduce((acc: any, line: any, i: number) => {
      acc.push(line);
      if (line.type === 'column' && opts.showAverages.value) {
        const avg = calculateAverage(line.data);
        acc.push({
          name: 'avg',
          color: colors[i],
          type:'line',
          dashStyle: 'dash',
          yAxis: 0,
          lineWidth: 2,
          pointPlacement: 'on',
          enableMouseTracking: false,
          marker: {
            enabled: false
          },
          zIndex: 10,
          data: [[0, avg], {x: chartCategories.value.length - 1, y: avg, 
            dataLabels: {
              enabled: false
            }
          }]
        })
      }
      return acc;
    }, []);

    const networthData = (reports.value[0]?.items?.periodical || []).map((item: any) => ({
      y: item.value
    }));
    const minNetworth = Math.min(...networthData.map((item: any) => item.y));

    lines.push({
      name: t('label.networth'),
      type: 'area',
      dashStyle: 'dash',
      // min value of the item.value
      threshold: null,
      yAxis: 1,
      zIndex: 0,
      color: theme.current.value.dark ? lighterColors[0] : darkerColors[0],
      showInLegend: true,
      fillOpacity: 0.1,
      data: networthData
    })

    return lines;
  })

  const groups = computed(() => {
    return Object.keys(mainReport.value.groups || {}).map((groupName: string) => {
      return {
        name: groupName
      }
    })
  })

  const extractGroupData = (groupName: string) => {
    const report = mainReport.value;
    if (!report.groups) {
      return null;
    }

    return {
      title: getGroupName(groupName),
      params: report.params,
      ...report.groups[groupName]
    }
  }

  const performanceSummary = computed(() => {
    const footnotes: Record<number, string> = {
      1: t('report.performance.footnote.interval', { interval: t(`label.interval.${opts.summaryInterval.value}`) }),
      2: t('report.performance.footnote.simulation'),
    }

    const usedFootnotes: number[] = [];

    const useFootnote = (key: number) => {
      if (!usedFootnotes.includes(key)) {
        usedFootnotes.push(key);
      }
      return usedFootnotes.length;
    }

    const items: {title: string, values: string[], footnote?: number, type?: string, class?: string, tooltip?: string}[] = [{
      title: t('report.performance.report.start_balance'),
      values: reports.value.map((report: any) => currencyFormatter(report.startValue, report.currency)),
      class: 'balance'
    }, {
      title: t('report.performance.report.end_balance'),
      values: reports.value.map((report: any) => currencyFormatter(report.endValue, report.currency)),
      class: 'balance'
    }, {
      title: t('report.performance.report.contributions'),
      values: reports.value.map((report: any) => currencyFormatter(report.performance?.contributions, report.currency)),
      tooltip: t('report.performance.report.contributions_tooltip'),
      class: 'balance'
    }, {
      title: t('report.performance.report.annualized_return'),
      values: reports.value.map((report: any) => percentFormatter(report.performance?.moneyWeighted.annualized))
    }, {
      title: t('report.performance.report.cumulative_return'),
      values: reports.value.map((report: any) => percentFormatter(report.performance?.moneyWeighted.cummulative))
    }, {
      title: t('report.performance.report.mean_return'),
      values: reports.value.map((report: any) => percentFormatter(report.performance?.average)),
      footnote: 1,
    }, {
      title: t('report.performance.report.std_deviation'),
      values: reports.value.map((report: any) => percentFormatter(report.performance?.std)),
      tooltip: t('report.performance.report.std_deviation_tooltip'),
      footnote: useFootnote(1),
    }, {
      title: t('report.performance.report.max_drawdown'),
      values: reports.value.map((report: any) => percentFormatter(report.performance?.maxDrawdown)),
      tooltip: t('report.performance.report.max_drawdown_tooltip'),
      footnote: useFootnote(1),
    }, {
      title: t('report.performance.report.max_drawdown_value'),
      values: reports.value.map((report: any) => percentFormatter(report.performance?.maxDrawdownValue)),
      tooltip: t('report.performance.report.max_drawdown_value_tooltip'),
      footnote: useFootnote(1),
    }]

    items.unshift({
      title: t('report.performance.report.metric'),
      values: reports.value.map((report: any) => ({
        title: report.title,
        footnote: report.isSimulation ? useFootnote(2) : undefined
      })),
      type: 'title'
    })

    return {
      items,
      footnotes: usedFootnotes.map((key, i) => ({
        id: i + 1,
        text: footnotes[key]
      }))
    }
  });

  return {
    chartData,
    chartCategories,
    isChartCummulative,
    groups,
    extractGroupData,
    performanceSummary
  }
}

export function createTransactionTooltip(item: { type: TransactionType, count: number, quantity: number, averagePrice: number, currency: string }) {
  let topLine = i18n.global.t(`transactionType.${item.type}.title`);
  let bottomLine = i18n.global.t('chart.tooltip.info_line', {
    quantity: item.quantity,
    price: currencyFormatter(item.averagePrice, item.currency)
  });

  switch (item.type) {
    case TransactionType.Buy:
      topLine = i18n.global.t('chart.tooltip.transaction_type.buy', { count: item.count });
      break;
    case TransactionType.Sell:
      topLine = i18n.global.t('chart.tooltip.transaction_type.sell', { count: item.count });
      break;
    case TransactionType.Dividend:
    case TransactionType.Interest:
    case TransactionType.LoanPrincipalPaid:
    case TransactionType.InterestPaid:
      bottomLine = '%net';
      break;
  }

  topLine = topLine.replace('#', item.count.toString());
  bottomLine = bottomLine
    .replace('#', item.quantity.toString())
    .replace('%net', currencyFormatter(item.quantity, item.currency))
    .replace('%', currencyFormatter(item.averagePrice, item.currency))
  
  return `
    <div class="pa-1 text-darkText text-body-2">
      <div class="">${topLine}</div>
      <div class="font-weight-bold text-narrow text-body-1">${bottomLine}</div>
    </div>
  `
}

export function prepareAnnotations (values: any[]) {
  const priority = [
    TransactionType.LoanPrincipalPaid,
    TransactionType.InterestPaid
  ]

  return values.map(val => {
    const dates = Object.keys(val.info)
    return dates.map(date => {
      const items = Object.keys(val.info[date]);
      let i = 0;
      return items
        .filter(type => Object.keys(icons).includes(type))
        .sort((a, b) => {
          return priority.indexOf(a) - priority.indexOf(b)
        })
        .map((type: any) => {
          const icon = icons[type as unknown as TransactionType];
          return {
            x: new Date(date).getTime(),
            distance: i++,
            icon: icon?.icon,
            color: icon?.color,
            colorHex: icon?.colorHex,
            type,
            data: val.info[date][type]
          }
        }
      )
    }).flat()
  }).flat()
}