<template>
  <DropdownMenu
    :activator-size="'small'"
    :label="$t('label.download')"
    :loading="download.isLoading.value"
    color="default"
    density="compact"
    activator-variant="tonal"
    :items="[{
      value: 'png',
      onClick: () => download.downloadAsPng(elementId, {
        width: elementWidth,
        screenWidth: screenWidth,
        fileName: fileName
      }),
      title: $t('label.file_type.png'),
      icon: 'mdi-file-png-box'
    }]"
  />
</template>

<script lang="ts" setup>
import { useDownload } from '@/utils/helpers/download';

defineProps({
  elementId: {
    type: String,
    required: true
  },
  elementWidth: {},
  screenWidth: {},
  fileName: {
    type: String,
    default: 'download'
  }
})

const download = useDownload();
</script>