<template>
  <div class="d-flex flex-column flex-sm-row ga-4 align-sm-center justify-space-between w-100">
    <v-btn
      :loading="authStore.pricingInfo.loading"
      variant="tonal"
      color="blue"
      @click="openPromoCodeDialog"
    >
    <v-icon class="mr-2">mdi-gift</v-icon>
      {{ t('subscription.use_promo_code') }}
    </v-btn>
    <v-spacer />
    <div class="d-flex ga-1 flex-column flex-sm-row" v-if="!$vuetify.display.xs">
      <div class="d-flex flex-row flex-sm-column align-start align-sm-end">
        <v-icon :style="$vuetify.display.smAndUp ? '' : 'transform: rotate(275deg) scaleX(-1);'" class="mb-n4" color="blue">mdi-redo</v-icon>
        <span style="line-height:1em; text-align: center;" class="text-blue font-weight-bold text-caption pr-sm-2">🚀 <span style="white-space: nowrap;" v-html="$t('subscription.annual_info')">
        </span></span>
      </div>
      <ButtonToggle :density="variant === 'compact' ? 'compact' : undefined" full-on-mobile v-model="value" :options="[
        {value: true, title: t('subscription.annual')},
        {value: false, title: t('subscription.monthly')}
      ]" />
    </div>
  </div>
  <Dialog
    v-model:modelVisible="showPromoCodeDialog"
    :title="$t('subscription.use_promo_code')"
    title-icon="mdi-gift"
    @confirm="savePromocode"
    :confirm="{
      show: true,
      label: $t('label.apply'),
      color: 'primary',
      variant: 'tonal',
      loading: authStore.pricingInfo.loading
    }"
  >
    <div class="d-flex flex-column ga-2 text-body-1">
      <p>{{ $t('subscription.enter_promo_code_info') }}</p>
      <v-text-field
        v-bind="(fieldDesign as any)"
        type="text"
        density="compact"
        v-model="promoCode"
        :label="t('subscription.promo_code')"
        :disabled="!!authStore.pricingInfo.loading"
      />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { fieldDesign } from '@/const';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/authStore';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  modelValue: Boolean,
  variant: {
    type: String,
    default: 'default'
  }
});

const emit = defineEmits(['update:modelValue', 'applyPromoCode']);

const { t } = useI18n();
const authStore = useAuthStore();
const appStore = useAppStore();

const value = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emit('update:modelValue', value)
});

const showPromoCodeDialog = ref(false);
const promoCode = ref(authStore.promoCode);

const openPromoCodeDialog = () => {
  showPromoCodeDialog.value = true;
}

const savePromocode = async () => {
  authStore.setPromoCode(promoCode.value);
  await authStore.pricingInfo.refresh();
  showPromoCodeDialog.value = false;

  if (!promoCode.value) {
    return;
  }

  if (authStore.pricingInfo.data.discount) {
    appStore.showNotice(t('subscription.promo_code_applied'), 'success');
  } else {
    appStore.showNotice(t('subscription.promo_code_invalid'), 'warning');
  }
}
</script>