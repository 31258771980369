<template>
  <div class="d-flex align-center" :class="class">
    <v-icon v-if="icon" size="x-small" :color="arrowColor">{{ icon }}</v-icon>
    <span :class="{clazz, balance: !!currency}">{{ valueFormatted }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { currencyFormatter, percentFormatter } from '@/const';

const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  arrow: {
    type: Boolean,
    required: true,
  },
  currency: {
    type: String,
    default: null
  },
  class: {
    type: String,
    default: null
  },
  colorText: {
    type: Boolean,
    default: true
  },
  minDecimals: {
    type: Number,
    default: 2
  },
  maxDecimals: {
    type: Number,
    default: 2
  }
});

const icon = computed(() => {
  if (!props.arrow) {
    return null;
  }
  if (props.value > 0) {
    return 'mdi-arrow-top-right-thick';
  } else if (props.value < 0) {
    return 'mdi-arrow-bottom-right-thick';
  } else {
    return 'mdi-arrow-right-thick';
  }
})

const valueFormatted = computed(() => {
  const number = icon.value ? Math.abs(props.value) : props.value;

  if (props.currency) {
    return currencyFormatter(number, props.currency, props.maxDecimals, props.minDecimals);
  }
  return percentFormatter(number);
})

const clazz = computed(() => {
  if (!props.colorText) {
    return '';
  }
  if (props.value > 0) {
    return 'text-success';
  } else if (props.value < 0) {
    return 'text-error';
  } else {
    return '';
  }
})

const arrowColor = computed(() => {
  if (props.value > 0) {
    return 'success';
  } else if (props.value < 0) {
    return 'error';
  } else {
    return '';
  }
})
</script>