import { defineStore } from 'pinia';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import type { PriceDtoType } from '~/price.schema';

export const usePriceStore = defineStore('price', () => {
  const getInstrumentPriceAt = async (instrumentId: number, date: string): Promise<PriceDtoType> => {
    return fetchWrapper.get(`/api/v1/price/instrument/${instrumentId}?date=${date}`)
      .then((response) => {
        return response
      })
  }

  return {
    getInstrumentPriceAt
  }
});
