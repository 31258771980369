<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import TopList from './TopList.vue';
import { useReport } from '@/report.helper';
import { useTreemapChart } from '@/chart.helper';
import { useAuthStore } from '@/stores/authStore';

const props = defineProps({
  report: {
    type: Object,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const authStore = useAuthStore();

const isPercent = ref<boolean | string>(authStore.getUserPreference(`performance.type`, true));
const chartMode = ref<string>(authStore.getUserPreference(`performance.tree.type`, 'value'));
const { groupsFlattened } = useReport(computed(() => props.report));
const { chartOptions: chart } = useTreemapChart(groupsFlattened, computed(() => ({
  height: 320,
  valueKey: (item: any) => Math.abs(chartMode.value === 'value' ? item.maxValue : (chartMode.value === 'endValue' ? item.value : item.openAndClosedPositions?.totalGain)),
  mode: 'performance',
  currencyCode: props.report.currencyCode,
})));

watch(() => isPercent.value, (value) => {
  authStore.setUserPreference(`performance.type`, value);
});
watch(() => chartMode.value, (value) => {
  authStore.setUserPreference(`performance.tree.type`, value);
});

</script>

<template>
  <DisplayCard :title="$t('label.performance')" style="overflow: hidden;">
    <template v-slot:actions>
      <div class="d-flex ga-2 align-center" data-html2canvas-ignore="true">
        <ButtonToggle
          v-model="isPercent"
          mode="icon"
          density="compact"
          :options="[{
              title: 'mdi-percent-outline',
              value: true
            }, {
              title: 'mdi-numeric',
              value: false
            }, {
              title: 'mdi-chart-tree',
              value: 'tree'
            }]"
          no-columns
        />
        <v-menu v-if="isPercent === 'tree'">
          <template v-slot:activator="{ props }">
            <v-btn class="" icon="mdi-cog-outline" variant="plain" v-bind="props" slim></v-btn>
          </template>
          <v-card>
            <div class="pa-5">
              <div class="text-h6 mb-2">
                {{ $t('dashboard.performance.settings.title') }}
              </div>
              <ButtonToggle
                v-model="chartMode"
                :grow="false"
                :noColumns="false"
                :options="[{
                    title: $t('portfolio.costbase'),
                    value: 'value',
                    help: $t('dashboard.performance.settings.help.costbase')
                  }, {
                    title: $t('performance.total_gain'),
                    value: 'gain',
                    help: $t('dashboard.performance.settings.help.total_gain')
                  }, {
                    title: $t('portfolio.value'),
                    value: 'endValue',
                    help: $t('dashboard.performance.settings.help.endValue')
                  }]"
              />
            </div>
          </v-card>
        </v-menu>
        <slot name="configuration" />
      </div>
    </template>
    <v-row>
      <template v-if="!['tree', 'tree-2'].includes(isPercent)">
        <v-col cols="12" sm="6">
          <TopList
            :title="$t('label.best_performance')"
            type="top"
            :report="report"
            :is-loading="isLoading"
            :is-percent="!!isPercent"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <TopList
            :title="$t('label.worst_performance')"
            type="bottom"
            :report="report"
            :is-loading="isLoading"
            :is-percent="!!isPercent"
          />
        </v-col>
      </template>
      <v-col v-else>
        <highcharts
          :chartOptions="chart"
          v-if="groupsFlattened.length > 0 && !isLoading"
        />
        <div v-if="isLoading" class="ma-4">
          <v-skeleton-loader height="280" style="width:100%;" type="ossein" />
        </div>
      </v-col>
    </v-row>
  </DisplayCard>
</template>