import { i18n } from '@/plugins/i18n';
import { computed, watch, type ComputedRef, type Ref, type WritableComputedRef } from "vue"
import { instrumentConfig } from "~/config"
import { AssetClass, HoldingType, InstrumentType, PaymentFrequency } from '~/enums';

const { t, te } = i18n.global

export const investmentTypeOptions = computed(() => {
  return Object.values(instrumentConfig).map((instrument) => {
    return {
      title: t(`instrumentType.${instrument.type}.title`),
      value: instrument.type
    }
  }).filter((instrument) => ![InstrumentType.TakenLoan].includes(instrument.value))
})

export const assetClassTypeOptions = computed(() => {
  return Object.values(AssetClass).map((assetClassKey) => {
    return {
      title: t(`asset_class.${assetClassKey}`),
      value: assetClassKey
    }
  })
})

export const paymentFrequencyOptions = computed(() => {
  return Object.values(PaymentFrequency).map((frequency) => {
    return {
      title: t(`payment_frequency.${frequency}`),
      value: frequency
    }
  })
})

export const useInvestment = (
  holding: ComputedRef<any>,
  code: ComputedRef<string | undefined>,
  logo: ComputedRef<string | undefined>
) => {
  const codeValue = computed(() => {
    if (code.value) {
      return code.value;
    } else if (![HoldingType.Cash].includes(holding.value?.type)) {
      return holding.value?.symbol;
    } else if (holding.value?.instrument?.displayName) {
      return holding.value?.instrument?.displayName;
    } else {
      return holding.value?.name;
    }
  });

  const logoValue = computed(() => {
    if (logo.value) {
      return logo.value;
    } else if ([HoldingType.Loan, HoldingType.Cash].includes(holding.value?.type)) {
      return holding.value?.logo;
    } else {
      return holding.value?.instrument?.logo;
    }
  });

  return {
    codeValue,
    logoValue
  }
}

export const getGroupName = (groupName: string) => {
  return te(`${groupName}`) ? t(`${groupName}`) : groupName?.replace('custom.', '')
}

export const generateShortCode = (name: string | undefined) => {
  if (!name) {
    return '';
  }

  // 2. Clean and split name
  const clean = name.replace(/[^A-Za-z0-9 ]+/g, '').trim();
  const words = clean.split(/\s+/);

  // 3. Single word: first 4 letters
  if (words.length === 1) {
    return words[0].substring(0, 4).toUpperCase();
  }

  // 4. Two words: first 2 letters of each
  if (words.length === 2) {
    return (
      words[0].substring(0, 2) + words[1].substring(0, 2)
    ).toUpperCase();
  }

  // 5. Three or more: first letter of up to 4 words
  let code = words.slice(0, 4).map(w => w[0]).join('');

  // If less than 4 letters, fill in with next letters from words
  if (code.length < 4) {
    for (let word of words) {
      for (let i = 1; i < word.length; i++) {
        code += word[i];
        if (code.length >= 4) break;
      }
      if (code.length >= 4) break;
    }
  }

  return code.substring(0, 4).toUpperCase();
}

export const useShortCode = (name: Ref<any>, code: Ref<any>) => {
  watch(name, (newValue, oldValue) => {
    const newGenerated = generateShortCode(newValue);
    const oldGenerated = generateShortCode(oldValue);

    if (code.value === oldGenerated || !code.value) {
      code.value = newGenerated
    }
  });
}