export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export enum Lang {
  en = 'en',
  et = 'et',
}

export enum TransactionType {
  Opening = 'opening',
  Transfer = 'transfer',
  Buy = 'buy',
  Sell = 'sell',
  CapitalReturn = 'capital_return',
  BonusShares = 'bonus_shares',
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Conversion = 'conversion',
  Fee = 'fee',
  Expenses = 'expenses',
  Tax = 'tax',
  InterestPaid = 'interest_paid',
  LoanPrincipalPaid = 'loan_principal_paid',
  Writeoff = 'writeoff',
  Split = 'split',
  Dividend = 'dividend',
  Interest = 'interest',
  RentalIncome = 'rental_income',
  OptionsIncome = 'options_income',
  OtherIncome = 'other_income',
}

export enum TransactionCategory {
  Capital = 0,
  Income = 1,
  Expense = 2
}

export enum Currency {
  Euro = 'EUR',
  US_Dollar = 'USD',
  Australian_Dollar = 'AUD',
  British_Pound = 'GBP',
  British_Pence = 'GBX',
  Swedish_Krona = 'SEK',
  Signapore_Dollar = 'SGD',
  Japanese_Yen = 'JPY',
  Honk_Kong_Dollar = 'HKD',
  Poland_Zloty = 'PLN',
  Canada_Dollar = 'CAD',
  Norwegian_Krone = 'NOK',
  Danish_Krone = 'DKK',
  Swiss_Franc = 'CHF',
}

export enum ReturnType {
  Capital = 'capital',
  Income = 'income',
  Currency = 'currency',
  Fees = 'fees',
  Total = 'total'
}

export enum HoldingType {
  Cash = 'cash',
  Investment = 'investment',
  Loan = 'loan'
}

export enum InstrumentType {
  Cash = 'cash',
  OrdinaryShare = 'ordinary_share',
  Equity = 'equity',
  PreferredShare = 'preferred_share',
  ExchangeTradedFund = 'etf',
  ManagedFund = 'managed_fund',
  RealEstate = 'real_estate',
  Commodities = 'commodities',
  Other = 'other',
  Currency = 'currency',
  Cryptocurrency = 'crypto_currency',
  Bond = 'bond',
  Loan = 'loan',
  P2PLoan = 'p2p_loan',
  TakenLoan = 'taken_loan',
  Deposit = 'deposit'
}

export enum AssetClass {
  Equity = 'equity',
  FixedIncome = 'fixed_income',
  RealEstate = 'real_estate',
  Startup = 'startup',
  Cash = 'cash',
  Loan = 'loan',
  Cryptocurrency = 'cryptocurrency',
  Other = 'other'
}

export enum CsvFileFields {
  Date = 'date',
  ValueDate = 'value_date',
  TransactionType = 'transaction_type',
  TransactionCode = 'transaction_code',
  InstrumentCode = 'instrument_code',
  IsinCode = 'isin_code',
  InstrumentName = 'instrument_name',
  InstrumentType = 'instrument_type',
  Quantity = 'quantity',
  Price = 'price',
  Currency = 'currency',
  Fee = 'fee',
  FeeCurrency = 'fee_currency',
  TaxCurrency = 'tax_currency',
  ExchangeRate = 'exchange_rate',
  UniqueId = 'unique_id',
  TransactionId = 'transaction_id',
  Unknown = 'unknown',
  Ignore = 'ignore',
  Description = 'description',
  GrossAmount = 'gross_amount',
  NetAmount = 'net_amount',
  Tax = 'tax',
  Exchange = 'exchange',
  LevelOfDetail = 'level_of_detail',
  Multiplier = 'multiplier',
  DebitCredit = 'debit_credit',
  DebtorCreditorName = 'debtor_creditor_name',
  DebtorCreditorAccount = 'debtor_creditor_account',
}

export enum CsvRowStatus {
  Imported = 'imported',
  Review = 'review',
  Error = 'error',
  Duplicate = 'duplicate',
  Ignore = 'ignore' 
}

export enum CsvImportType {
  Transactions = 'transactions',
  Transfers = 'transfers',
  Price = 'price',
}

export enum PerformancePeriod {
  Yesterday = 'yesterday',
  ThisMonth = 'this_month',
  PreviousMonth = 'previous_month',
  TwoMonthsAgo = 'two_months_ago',
  LastTwelveMonths = 'last_twelve_months',
  ThisYear = 'this_year',
  PreviousYear = 'previous_year',
  FirstTransaction = 'first_transaction',
  Custom = 'custom'
}

export enum CashflowPeriod {
  ThisMonth = 'this_month',
  NextMonth = 'next_month',
  Next6Months = 'next_6_months',
  ThisYear = 'this_year',
  NextYear = 'next_year',
  NextFiveYears = 'next_five_years',
  Custom = 'custom'
}

export enum FeatureFlag {
  UseImportPredictions = 'import_predictions',
  ManagerPermissions = 'manager_permissions',
  HistoricalPrices = 'historical_prices',
  DuplicateAmounts = 'duplicate_amounts',
  InviteFriends = 'invite_friends',
  MultiLoanAllocation = 'multi_loan_allocation',
}

export enum ReportGroupingType {
  AssetClass = 'asset_class',
  InstrumentType = 'instrument_type',
  Portfolio = 'portfolio',
  Currency = 'currency',
  None = 'none'
}

export enum ChartType {
  Total = 'total',
  Stacked = 'stacked',
  CostbaseTotal = 'costbase-total',
  CostbaseStacked = 'costbase-stacked',
  Growth = 'growth',
  Price = 'price',
  LTV = 'ltv',
  YTM = 'ytm',
}

export enum InstrumentTypeGroups {
  Stocks = 'stocks',
  ETF = 'etf',
  ManagedFunds = 'managed_funds',
  FixedIncome = 'fixedIncome',
  RealEstate = 'realestate',
  Crypto = 'crypto',
  Equity = 'equity',
  Other = 'other',
  Cash = 'cash',
  Loan = 'loan'
}

export enum PerformanceMethod {
  Simple = 'simple',
  Compound = 'compound',
  Xirr = 'xirr'
}

export enum PortfolioEntityType {
  Individual = 'individual',
  Company = 'company',
  Combined = 'combined'
}

export enum FixedIncomeType {
  Bullet = 'bullet', // regular interest, principal at the end
  FullBullet = 'full_bullet', // interest and principal at the end
  InterestOnly = 'interest_only', // interest only
  EvenPrincipal = 'even_principal', // equal principal payments
  EvenTotal = 'event_total', // equal (interest + principal) payments
  Dps = 'dps', // dividend per share
  RentalIncome = 'rental_income', // rental income
  FixedIncome = 'fixed_income', // fixed income
}

export enum PaymentFrequency {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Yearly = 'yearly'
}

export enum TrackingType {
  None = 'none',
  Balance = 'balance',
  Full = 'full'
}

export enum GuidPrefix {
  User = 'usr',
  Investment = 'inv',
  Cash = 'cash',
  Loan = 'loan',
  Instrument = 'ins',
  Link = 'lnk',
  LinkAgreement = 'lag',
  Exchange = 'exh',
  Note = 'note',
  Portfolio = 'port',
  Permission = 'perm',
  Price = 'prc',
  ExchangeRate = 'fxr',
  Transaction = 'trx',
  UnconfirmedTransaction = 'utrx',
  Split = 'spl',
  Subscription = 'sub',
  Notification = 'not',
  InvestmentGroup = 'igp',
  Grouping = 'grp',
}