<script setup lang="ts">
import { ref } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { rules } from '@/const';
import { useI18n } from 'vue-i18n';

const authStore = useAuthStore();
const emit = defineEmits(['register']);
const { t, locale } = useI18n();

const isSubmitting = ref(false);
const checkbox = ref(false);
const havePromoCode = ref(false);
const show1 = ref(false);
const password = ref('');
const email = ref('');
const Regform = ref();
const firstname = ref('');
const lastname = ref('');
const promoCode = ref('');
const apiError = ref('');

async function validate() {
  const { valid } = await Regform.value.validate();
 
  if (!valid) {
    return;
  }

  try {
    isSubmitting.value = true;
    const promoCodeValue = promoCode.value?.trim() || localStorage.getItem('promoCode');

    await authStore.register({
      email: email.value?.trim(),
      password: password.value,
      firstName: firstname.value?.trim(),
      lastName: lastname.value?.trim(),
      language: locale.value,
      promoCode: promoCodeValue
    });
    if (promoCodeValue) {
      localStorage.setItem('promoCode', promoCodeValue);
    }
    emit('register');
  } catch (error: any) {
    apiError.value = t(`error.${error?.message?.replace(/\.|"/g, '_')}`, error?.message)
  } finally {
    isSubmitting.value = false;
  }
}
</script>

<template>
  <div class="mt-5">
    <div id="g_id_onload"
      data-client_id="517474754173-s0f971mj4jpm1qq8v3d4rjml25vmanda.apps.googleusercontent.com"
      data-context="signin"
      data-ux_mode="popup"
      data-login_uri="https://app.portfellow.com/api/v1/auth/google"
      data-auto_select="false"
      data-itp_support="false">
    </div>

    <div class="g_id_signin"
      data-type="standard"
      data-shape="rectangular"
      data-theme="filled_blue"
      data-text="signup_with"
      data-size="large"
      data-logo_alignment="left">
    </div>

    <v-divider class="my-5 text-center">
      <span class="text-caption text-medium-emphasis">{{$t('label.or')}}</span>
    </v-divider>
  </div>
  <v-form ref="Regform" lazy-validation action="" class="loginForm">
    <v-row dense>
      <v-col cols="12" sm="6" class="pr-2">
        <v-text-field
          required
          v-model="firstname"
          density="compact"
          hide-details="auto"
          variant="outlined"
          color="primary"
          :label="$t('register_page.first_name')"
          :rules="[rules.required]"
          bg-color="surface"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" class="mt-2 mt-sm-0">
        <v-text-field
          required
          v-model="lastname"
          density="compact"
          hide-details="auto"
          variant="outlined"
          color="primary"
          :label="$t('register_page.last_name')"
          :rules="[rules.required]"
          bg-color="surface"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="mt-2">
        <v-text-field
          v-model="email"
          :rules="[rules.required, rules.email]"
          :label="$t('register_page.email')"
          required
          density="compact"
          hide-details="auto"
          variant="outlined"
          color="primary"
          bg-color="surface"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="mt-2">
        <v-text-field
          v-model="password"
          :rules="[rules.required, rules.password]"
          :label="$t('register_page.password')"
          required
          density="compact"
          variant="outlined"
          color="primary"
          hide-details="auto"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"
          bg-color="surface"
          class="pwdInput"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="mt-2" v-if="havePromoCode">
        <v-text-field
          :label="$t('register_page.promo_code')"
          v-model="promoCode"
          density="compact"
          hide-details="auto"
          variant="outlined"
          color="primary"
          bg-color="surface"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-checkbox
      class="mt-2"
      v-model="havePromoCode"
      :rules="[rules.required]"
      label=""
      required
      density="compact"
      color="primary"
      hide-details="auto"
      bg-color="surface"
    >
      <template v-slot:label>
        <div class="text-body-1">{{ $t('register_page.i have_a_promo_code') }}</div>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="checkbox"
      :rules="[rules.required]"
      :label="$t('register_page.i_agree_to')"
      required
      density="compact"
      color="primary"
      hide-details="auto"
      bg-color="surface"
      class="mb-2"
    >
      <template v-slot:label>
        <div class="text-body-1">{{ $t('register_page.i_agree_to') }} <a target="_blank" href="https://portfellow.com/terms-and-conditions/" class="text-lightText">{{$t('register_page.terms_and_conditions')}}</a> {{ $t('register_page.and') }} <a target="_blank"  href="https://portfellow.com/privacy-policy/" class="text-lightText">{{ $t('register_page.privacy_policy') }}</a></div>
      </template>
    </v-checkbox>

    <div v-if="apiError" class="mt-2">
      <v-alert color="error">{{ apiError }}</v-alert>
    </div>
    <v-btn
      :loading="isSubmitting"
      :disabled="isSubmitting"
      color="blue"
      block
      class="mt-1"
      variant="flat"
      size="large"
      @click="validate()">
      {{ $t('register_page.register') }}
    </v-btn>
  </v-form>
  <div class="mt-1 text-right">
    <v-btn variant="plain" to="/auth/login" class="mt-2 text-capitalize pa-0">
      {{ $t('register_page.already_have_account') }}
    </v-btn>
  </div>
</template>
<style lang="scss">
.custom-devider {
  border-color: rgba(0, 0, 0, 0.08) !important;
}
.googleBtn {
  border-color: rgba(0, 0, 0, 0.08);
  margin: 30px 0 20px 0;
}
.outlinedInput .v-field {
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: none;
}
.orbtn {
  padding: 2px 40px;
  border-color: rgba(0, 0, 0, 0.08);
  margin: 20px 15px;
}
.pwdInput {
  position: relative;
  .v-input__append {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
