import { asyncComputed } from "@vueuse/core";
import { ref } from "vue";

export const useLazyFetch = <T>(getter: () => Promise<T>, initial: T, id = 'none') => {
  const loading = ref(false);
  const version = ref(1);
  const promiseStack: {r: () => void}[] = [];
  const refresh = () => {
    return new Promise<void>(resolve => {
      promiseStack.push({r: resolve});
      version.value++;
    });
  }
  const error = ref();

  const data = asyncComputed(async () => {
    if (version.value) {
      // used to trigger the getter
    }
    try {
      //await new Promise(resolve => setTimeout(resolve, 10000));
      return await getter();
    } catch (e) {
      error.value = e;
      return initial;
    } finally {
      promiseStack.forEach(p => setTimeout(p.r, 0));
      promiseStack.length = 0;
    }
  }, initial, { lazy: true, evaluating: loading });

  return {
    loading,
    refresh,
    data,
    error
  }
}