import { defineStore } from 'pinia';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import type { RemoteObject } from '@/const';
import { usePortfolioStore } from './portfolioStore';
import { computed, ref } from 'vue';
import type {
  AccountDtoType,
  MoveInvestmentPortfolioDtoType,
  AccountCreateDtoType,
  CreateCashAccountTransactionDtoType,
  CashAccountTransactionDtoType,
  CashAccountTransactionsDtoType
} from '~/cash-account.schema';
import type { SummaryItemDtoType } from '~/summary.schema';
import { useRoute } from 'vue-router';

type State = {
  account: AccountDtoType | RemoteObject
  accountSummary: SummaryItemDtoType | RemoteObject
  accounts: AccountDtoType[] | RemoteObject,
  transaction: CashAccountTransactionDtoType | RemoteObject,
  transactions:  {
    value: CashAccountTransactionDtoType[],
    loading: boolean,
    error: string
  },
}

export const useAccountStore = defineStore('account', () => {
  const portfolioStore = usePortfolioStore()
  const route = useRoute()

  const accountId = ref<string | null>(null);
  // @ts-expect-error
  const account = ref<AccountDtoType | null>({})
  const accountSummary = ref<State['accountSummary']>({})
  const accounts = ref<State['accounts']>({})
  const transaction = ref<State['transaction']>({})
  const transactions = ref<State['transactions']>({
    value: [],
    loading: false,
    error: ''
  })
  const balanceItems = ref({
    value: [],
    loading: false,
    error: ''
  })

  const parentAccount = computed(() => {
    if (!account.value?.id) {
      return undefined;
    }
    if (account.value.isRoot) {
      return account.value
    }
    return portfolioStore.portfolioAccounts.data.find((acc) => acc.id === account.value.parentAccountId)
  })

  const get = async (id: number, forceRefresh = false) => {
    if (!forceRefresh && account.value?.id === id || !id) {
      return
    }

    accountId.value = id
    account.value = { loading: true }
    transactions.value.loading = true

    fetchWrapper
      .get(`/api/v1/account/${id}`)
      .then((accountReponse) => {
        account.value = accountReponse
      })
      .catch((error) => (account.value = { error }));
  }

  const getAllAccounts = async () => {
    try {
      accounts.value = await fetchWrapper.get('/api/v1/account');
      return accounts.value;
    } finally {
      // ignore
    }
  }

  const refresh = async () => {
    if (route.params.accountId) {
      get(account.value.id, true)
    }
  }

  const getBalance = async (id: string, currencyCode: string) => {
    return fetchWrapper
      .get(`/api/v1/account/${id}/balance/${currencyCode}`)
      .then((accountReponse) => {
        return accountReponse;
      })
      .catch((error) => (account.value = { error }));
  }

  const getBalanceNumber = async (id: number, date: string) => {
    return fetchWrapper
      .get(`/api/v1/account/${id}/balance/date/${date}`)
      .then((accountReponse) => {
        return accountReponse;
      })
      .catch((error) => (account.value = { error }));
  }
  
  const createCashAccount = async (account: AccountCreateDtoType): Promise<AccountDtoType> => {
    return fetchWrapper.post('/api/v1/account', account)
      .then((account) => {
        portfolioStore.getAllAccounts(account.portfolioId)
        return account
      })
  }

  const updateCashAccount = async (id: string, accountDto: Partial<AccountCreateDtoType>): Promise<AccountDtoType> => {
    return fetchWrapper.put(`/api/v1/account/${id}`, accountDto)
      .then((accountResponse) => {
        portfolioStore.getAllAccounts(accountResponse.portfolioId)
        account.value = accountResponse
        return accountResponse
      })
  }

  const deleteCashAccount = async (id: string): Promise<void> => {
    return fetchWrapper.delete(`/api/v1/account/${id}`)
      .then(() => {
        portfolioStore.getAllAccounts(portfolioStore.portfolio.id)
      })
  }

  const createCashAccountTransaction = async (accountId: string, transaction: CreateCashAccountTransactionDtoType) => {
    return fetchWrapper.post(`/api/v1/account/${accountId}/transaction`, transaction);
  }

  const getCashAccountTransaction = async (accountId: string, transactionId: string) => {
    transaction.value = { loading: true }

    fetchWrapper
      .get(`/api/v1/account/${accountId}/transaction/${transactionId}`)
      .then((transactionResponse) => {
        transaction.value = transactionResponse
      })
      .catch((error) => (transaction.value = { error }));
  }

  const deleteCashAccountTransaction = async (accountId: number, transactionId: string) => {
    return fetchWrapper.delete(`/api/v1/account/${accountId}/transaction/${transactionId}`);
  }
  
  const updateCashAccountTransaction = async (accountId: number, transactionId: number, transaction: CreateCashAccountTransactionDtoType) => {
    return fetchWrapper.put(`/api/v1/account/${accountId}/transaction/${transactionId}`, transaction);
  }

  const getCashAccountTransactions = async (accountId: string, query: any = {limit: 10, offset: 0}): Promise<CashAccountTransactionsDtoType> => {
    transactions.value.loading = true

    return fetchWrapper
      .get(`/api/v1/account/${accountId}/transactions?limit=${query.limit}&offset=${query.offset}`)
      .then((transactionsResponse) => {
        transactions.value.value = transactionsResponse
        return transactionsResponse
      })
      .catch((error) => {
        transactions.value.error = error
        return []
      })
      .finally(() => {
        transactions.value.loading = false
      });
  }

  const getCashAccountBalanceItems = async (accountId: string, query: any = {limit: 10, offset: 0}): Promise<{}> => {
    balanceItems.value.loading = true

    return fetchWrapper
      .get(`/api/v1/account/${accountId}/balance/history?limit=${query.limit}&offset=${query.offset}`)
      .then((response) => {
        balanceItems.value.value = response
        return response
      })
      .catch((error) => (balanceItems.value.error = error))
      .finally(() => {
        balanceItems.value.loading = false
      });
  }

  const getAccountSummary = async (accountId: string, period: string, range?: { startDate: string, endDate: string }) => {
    accountSummary.value = { loading: true }

    const filters = new URLSearchParams(
      Object.assign({}, range, { period })
    ).toString()

    return fetchWrapper
      .get(`/api/v1/account/${accountId}/summary?${filters}`)
      .then((summaryResponse) => {
        accountSummary.value = summaryResponse
        return summaryResponse
      })
      .catch((error) => (accountSummary.value = { error }));
  }

  const moveToOtherPortfolio = async (accountId: number, form: MoveInvestmentPortfolioDtoType) => {
    return fetchWrapper.post(`/api/v1/account/${accountId}/move`, form);
  }

  return {
    accountId,
    account,
    parentAccount,
    accountSummary,
    accounts,
    transaction,
    transactions,
    balanceItems,
    get,
    refresh,
    createCashAccount,
    updateCashAccount,
    deleteCashAccount,
    createCashAccountTransaction,
    getCashAccountTransaction,
    deleteCashAccountTransaction,
    updateCashAccountTransaction,
    getCashAccountTransactions,
    moveToOtherPortfolio,
    getAccountSummary,
    getCashAccountBalanceItems,
    getBalance,
    getBalanceNumber,
    getAllAccounts
  }
});
