<script setup lang="ts">
import { useAuthStore } from '@/stores/authStore';
import { ref } from 'vue';
import { onMounted } from 'vue';
import { computed } from 'vue';
import { SubscriptionTier } from '../../../../backend/src/enums';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { dateFormatter, currencyFormatter } from '@/const';
import { useI18n } from 'vue-i18n';

const authStore = useAuthStore();
const { t } = useI18n();

const me = computed(() => authStore.user);
const billingInfo = ref<{
  nextBillingDate: string,
  invoices: any[],
  subscription: {
    billingCycle: string,
    isCancelled: boolean,
    cancelAt?: string,
  },
  nextInvoice: null | {
    total: number,
    appliedBalance: number,
    amountDue: number,
    currency: string,
    lines: {}[]
  }
} | null>(null);

const manageSubscriptionLoading = ref(false);
const isBillingInfoLoading = ref(false);
const showAllInvoices = ref(false);
const showInfo = ref(false);


const showStripe = computed(() => {
  return me.value && ![SubscriptionTier.Free, SubscriptionTier.Undefined].includes(me.value.subscription.tier)
});

const filteredInvoices = computed(() => {
  return (billingInfo.value?.invoices || [])
    .slice(0, showAllInvoices.value ? undefined : 5)
    .map((invoice) => ({
      ...invoice,
      status: getInvoiceStatusInfo(invoice)
    }));
});

const hasHiddenInvoices = computed(() => {
  return (billingInfo.value?.invoices || []).length > 5;
});

const manageSubscription = () => {
  manageSubscriptionLoading.value = true;
  fetchWrapper.get('/api/v1/stripe/customer-portal').
    then((response) => {
      window.location.href = response.url;
    })
    .catch(() => {
      manageSubscriptionLoading.value = false;
    });
};

const getInvoiceStatusInfo = (invoice: any) => {
  return {
    color: invoice.isPaid ? 'success' : (invoice.status === 'draft' ? 'gray' : 'error'),
    label: invoice.isPaid ? t('billing_page.paid') : (invoice.status === 'draft' ? t('billing_page.draft') :  t('billing_page.unpaid'))
  };
};

const hasActiveSubscription = computed(() => {
  return me.value && ![SubscriptionTier.Free, SubscriptionTier.Undefined].includes(me.value.subscription.tier);
});

const isCancelled = computed(() => {
  return billingInfo.value?.subscription.isCancelled;
});

const billingCycle = computed(() => {
  if (!hasActiveSubscription.value) {
    return '-';
  }
  if (billingInfo.value?.subscription.billingCycle === 'monthly') {
    return t('billing_page.monthly');
  }
  return t('billing_page.annual');
});

const cancelDate = computed(() => {
  if (!isCancelled) {
    return '-';
  }
  return dateFormatter(billingInfo.value?.subscription.cancelAt ?? '');
});

const nextBillingDate = computed(() => {
  if (!hasActiveSubscription.value || isCancelled.value) {
    return '-';
  }
  return dateFormatter(billingInfo.value?.nextBillingDate ?? '');
});

const total = computed(() => {
  if (!billingInfo.value?.nextInvoice) {
    return currencyFormatter(0, 'EUR');
  }
  return currencyFormatter(billingInfo.value.nextInvoice.total, billingInfo.value.nextInvoice.currency);
});

const appliedBalance = computed(() => {
  if (!billingInfo.value?.nextInvoice) {
    return currencyFormatter(0, 'EUR');
  }
  return currencyFormatter(billingInfo.value.nextInvoice.appliedBalance, billingInfo.value.nextInvoice.currency);
});

const amountDue = computed(() => {
  if (!billingInfo.value?.nextInvoice) {
    return currencyFormatter(0, 'EUR');
  }
  return currencyFormatter(billingInfo.value.nextInvoice.amountDue, billingInfo.value.nextInvoice.currency);
});

onMounted(async () => {
  try {
    isBillingInfoLoading.value = true;
    billingInfo.value = await authStore.getBillingInfo();
  } catch (_e) {
    //
  } finally {
    isBillingInfoLoading.value = false;
  }
});

</script>

<template>
  <PageLayout :heading="$t('settings_page.plans_and_billing_tab')">
    <v-row style="max-width:1000px;">
      <v-col cols="12" v-if="!hasActiveSubscription">
        <v-sheet theme="dark" border="0" rounded="lg" class="text-body-2 pa-5 fancy">
          <v-row dense>
            <v-col class="d-flex flex-row align-center" cols="12" md="6">
              <h2>
                {{ $t('billing_page.subscribe_now') }}
              </h2>
            </v-col>
            <v-col class="d-flex flex-column align-md-end">
              <v-btn color="green" :to="{ name: 'subscribe' }">
                {{ $t('billing_page.subscribe') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12" class="d-flex flex-column ga-2">
        <h2>
          {{ $t('billing_page.billing') }}
        </h2>
        <v-sheet border="md opacity-12" rounded="lg" class="text-body-2 pa-5">
          <v-row>
            <v-col cols="6" sm="3">
              <div class="text-body-1 font-weight-light">
                {{ $t('billing_page.your_plan') }}
              </div>
              <div class="text-h3">
                {{ $t(`plans.${me.subscription.tier}.title`) }}
                <v-btn
                  style="min-width: 20px;"
                  size="small"
                  variant="tonal"
                  density="comfortable"
                  rounded="xs"
                  class="pa-0"
                  :to="{ name: 'subscribe' }"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="6" sm="3">
              <div class="text-body-1 font-weight-light">
                {{ $t('billing_page.billing_cycle') }}
              </div>
              <v-progress-circular class="mt-2" size="24" indeterminate v-if="!billingInfo" />
              <div class="text-h3" v-else>{{ billingCycle }}</div>
            </v-col>
            <v-col cols="6" sm="3" v-if="!isCancelled">
              <div class="text-body-1 font-weight-light">
                {{ $t('billing_page.next_billing_date') }}
              </div>
              <v-progress-circular class="mt-2" size="24" indeterminate v-if="!billingInfo" />
              <div class="text-h3" v-else>{{ nextBillingDate }}</div>
            </v-col>
            <v-col cols="6" sm="3" v-if="isCancelled">
              <div class="text-body-1 font-weight-light">
                {{ $t('billing_page.cancelled_at') }}
              </div>
              <div class="text-h3">{{ cancelDate }}</div>
            </v-col>
            <v-col cols="6" sm="3" class="text-sm-right" v-if="!isCancelled">
              <div class="text-body-1 font-weight-light">
                {{ $t('billing_page.total') }}
              </div>
              <v-progress-circular class="mt-2" size="24" indeterminate v-if="!billingInfo" />
              <div class="text-h3 d-flex align-center justify-sm-end ga-2" v-else>
                <span>{{ amountDue }}</span>
                <v-btn
                  style="min-width: 20px;"
                  size="small"
                  variant="tonal"
                  density="comfortable"
                  rounded="xs"
                  class="pa-0"
                  @click="showInfo = !showInfo"
                >
                  <v-icon v-if="!showInfo">mdi-chevron-down</v-icon>
                  <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="6" sm="3" class="text-sm-right" v-if="isCancelled">
              <div class="text-body-1 font-weight-light">
                {{ $t('billing_page.status') }}
              </div>
              <v-chip color="error" variant="tonal" label>
                {{ $t('billing_page.cancelled') }}
              </v-chip>
            </v-col>
          </v-row>
          <v-divider class="my-5 mx-n5" v-if="showInfo" />
          <v-row v-if="showInfo">
            <v-col cols="12">
              <v-table density="compact">
                <thead>
                  <tr>
                    <th>{{ $t('billing_page.invoice.description') }}</th>
                    <th style="width: 1px;" class="text-right">{{ $t('billing_page.invoice.amount') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="line in billingInfo?.nextInvoice?.lines">
                    <td>{{ line.description }}</td>
                    <td class="text-right">{{ currencyFormatter(line.amount, line.currency) }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr v-if="billingInfo?.nextInvoice?.discount">
                    <td class="text-right font-weight-bold">{{ $t('billing_page.discount') }}</td>
                    <td class="text-right font-weight-bold">{{  currencyFormatter(-billingInfo?.nextInvoice?.discount, billingInfo?.nextInvoice?.currency) }}</td>
                  </tr>
                  <tr>
                    <td class="text-right font-weight-bold">{{ $t('billing_page.total') }}</td>
                    <td class="text-right font-weight-bold">{{ total }}</td>
                  </tr>
                  <tr v-if="billingInfo?.nextInvoice?.appliedBalance">
                    <td class="text-right font-weight-bold">{{ $t('billing_page.applied_balance') }}</td>
                    <td class="text-right font-weight-bold">{{ appliedBalance }}</td>
                  </tr>
                  <tr v-if="billingInfo?.nextInvoice?.appliedBalance">
                    <td class="text-right font-weight-bold">{{ $t('billing_page.amount_due') }}</td>
                    <td class="text-right font-weight-bold">{{ amountDue }}</td>
                  </tr>
                </tfoot>
              </v-table>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12" class="d-flex flex-column ga-2" v-if="hasActiveSubscription">
        <h2>
          {{ $t('billing_page.manage_subscription') }}
        </h2>
        <v-sheet border="md opacity-12" rounded="lg" class="text-body-1 pa-5 d-flex flex-column flex-sm-row ga-4">
            <v-btn
              @click="manageSubscription"
              color="primary"
              :loading="manageSubscriptionLoading"
            >
              {{ $t('billing_page.manage_subscription_stripe') }}
            </v-btn>
            {{ $t('billing_page.manage_subscription_description') }}
        </v-sheet>
      </v-col>
      <v-col cols="12" class="d-flex flex-column ga-2">
        <h2>
          {{ $t('billing_page.invoices') }}
        </h2>
        <v-progress-circular indeterminate v-if="!billingInfo" />
        <v-sheet border="md" rounded="lg" class="text-body-1 overflow-hidden" v-else-if="filteredInvoices.length">
          <v-table class="rounded-0" density="comfortable">
            <thead>
              <tr class="bg-primary-lighten-3">
                <th>{{ $t('billing_page.invoice_date') }}</th>
                <th class="text-right">{{ $t('billing_page.invoice_amount') }}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invoice in filteredInvoices" :key="invoice.invoicNr">
                <td>
                  {{ dateFormatter(invoice.createdAt) }}
                </td>
                <td class="text-right">
                  {{ currencyFormatter(invoice.totalAmount, invoice.currency) }}
                </td>
                <td class="text-end">
                  <v-chip :color="invoice.status.color" variant="flat" density="compact" label>
                    {{ invoice.status.label }}
                  </v-chip>
                </td>
                <td class="text-right">
                  <div class="d-flex ga-2 justify-end">
                    <v-btn color="primary" variant="flat" size="small" :href="invoice.link.pdf" target="_blank">
                      {{ $t('billing_page.download') }}
                    </v-btn>
                    <v-btn color="primary" variant="tonal" size="small" :href="invoice.link.hosted" target="_blank">
                      {{ $t('billing_page.view') }}
                    </v-btn>
                  </div>
                </td>
              </tr>
              <tr v-if="hasHiddenInvoices && !showAllInvoices">
                <td colspan="4">
                  <v-btn class="pa-0" variant="plain" color="primary" @click="showAllInvoices = !showAllInvoices">
                    {{ $t('billing_page.show_all') }}
                  </v-btn>
                </td>
              </tr>
              <tr v-if="showAllInvoices">
                <td colspan="4">
                  {{ $t('billing_page.more_in_stripe') }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-sheet>
        <div class="text-body-1" v-else>
          {{ $t('billing_page.no_invoices') }}
        </div>
      </v-col>
    </v-row>
  </PageLayout>
</template>

<style lang="scss" scoped>
.fancy {
  background: linear-gradient(to right , #3699ff, #8950fc) padding-box, linear-gradient(to right, #3699ff, #8950fc) border-box !important;
}
</style>