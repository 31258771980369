<template>
  <v-progress-circular v-if="showLoader" indeterminate color="primary" />
  <v-row v-else :style="`max-width:100%;margin-left:auto;margin-right:auto;`" no-gutters>
    <slot name="header">
      <v-col 
        v-if="heading || $slots.heading"
        cols="12"
        :class="{
          'flex-column': hideBack,
          'flex-sm-row': !noFlexRow,
          //'flex-column-reverse': !hideBack && !noFlexRow,
          'align-sm-center': true,
          'align-center': !hideBack || noFlexRow
        }"
        class="d-flex justify-space-between ga-5"
      >
        <h1 id="page-header" class="heading font-weight-black text-darkprimary text-truncate d-flex align-center ga-2">
          <slot name="heading">
            {{ heading }}
          </slot>
        </h1>
        <div v-if="$slots.actions || $slots['before-back'] || $slots['after-back'] || !hideBack">
          <slot name="actions">
            <div class="d-flex ga-2 justify-center">
              <slot name="before-back"></slot>
              <v-btn
                v-if="!hideBack"
                class="flex-grow-1 flex-sm-grow-0"
                size="large"
                variant="tonal"
                color="secondary"
                icon="mdi-arrow-left"
                @click="back">
              </v-btn>
              <slot name="after-back"></slot>
            </div>
          </slot>
        </div>
      </v-col>
    </slot>
    <v-col class="mt-5" :style="`max-width:${maxWidth ? maxWidth + 'px' : '100%'};margin-left:auto;margin-right:auto;`">
      <slot />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';

defineProps({
  heading: {
    type: String,
    required: false
  },
  hideBack: {
    type: Boolean,
    default: false
  },
  showLoader: {
    type: Boolean,
    default: false
  },
  maxWidth: {
    default: undefined
  },
  noFlexRow: {
    type: Boolean,
    default: false
  }
})

const router = useRouter();

const back = () => {
  router.go(-1);
}
</script>