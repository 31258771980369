import FullLayout from '@/layouts/full/FullLayout.vue';
import Layout from '@/views/loan/Layout.vue';
import Loan from '@/views/loan/Loan.vue';
import Settings from '@/views/loan/Settings.vue';
import Allocation from '@/views/loan/Allocation.vue';
import CreateTransaction from '@/views/loan/CreateTransaction.vue';
import EditTransaction from '@/views/loan/EditTransaction.vue';

const AccountRoutes = {
  path: '/loan',
  meta: {
    requiresAuth: true
  },
  component: FullLayout,
  children: [
    {
      path: ':loanId',
      component: Layout,
      meta: {
        parent: 'portfolio-loans'
      },
      children: [
        {
          name: 'loan',
          path: '',
          component: Loan,
          meta: {
            title: 'Loan',
            hideHeading: true,
          }
        },
        {
          name: 'loan-settings',
          path: 'settings',
          component: Settings,
          meta: {
            title: 'Loan settings'
          }
        },
        {
          name: 'loan-create-transaction',
          path: 'create-transaction',
          component: CreateTransaction,
          meta: {
            title: 'Create loan transaction'
          }
        },
        {
          name: 'loan-edit-transaction',
          path: 'edit-transaction/:transactionId',
          component: EditTransaction,
          meta: {
            title: 'Edit loan transaction'
          }
        },
        {
          name: 'loan-allocation',
          path: 'allocation',
          component: Allocation,
          meta: {
            title: 'Loan allocation'
          }
        },
      ]
    },
  ]
};

export default AccountRoutes;
