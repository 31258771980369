<template>
  <v-alert
    color="success"
    density="compact"
    icon="mdi-gift"
    theme="dark"
    rounded="md"
    @click="$router.push({ name: 'invite-friends' })"
    style="cursor: pointer;"
  >
    <div class="text-body-1">
      <v-icon @click="close" style="float:right;">mdi-close</v-icon>
      {{ $t('header.invite_friends') }}
    </div>
  </v-alert>
</template>

<script lang="ts" setup>
import { useAuthStore } from '@/stores/authStore';


const authStore = useAuthStore();

const close = (e: Event) => {
  e.stopPropagation();
  authStore.setUserPreference('hideReferralBanner', true);
}
</script>