<script setup lang="ts">
import { ref } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { computed } from 'vue';
import { watch } from 'vue';
import { useAppStore } from '@/stores/app';
import ReferralBanner from './ReferralBanner.vue';
import SubscribeBanner from './SubscribeBanner.vue';

const emit = defineEmits(['showNotifications', 'close']);

const authStore = useAuthStore();
const appStore = useAppStore();
const isDarkTheme = ref(appStore.theme === 'MainThemeDark');

watch(() => isDarkTheme.value, (newVal) => {
  if (newVal) {
    appStore.setTheme('MainThemeDark')
  } else {
    appStore.setTheme('MainTheme')
  }
});

const showUpgrade = computed(() => {
  return authStore.user.subscription?.tier === 'free'
})

const locales = computed(() => {
  return [
    { title: 'English', subtext: 'UK', value: 'en' },
    { title: 'Eesti', subtext: 'ET', value: 'et' },
  ]
})

const onShowNotifications = () => {
  emit('showNotifications');
  emit('close');
}

const notificationCount = computed(() => {
  return authStore.unreadNotificationCount;
});

const bannersToShow = computed(() => {
  return [
    { component: SubscribeBanner, show: showUpgrade.value },
    { component: ReferralBanner, show: !showUpgrade.value && !authStore.getUserPreference('hideReferralBanner', false) },
  ].filter(banner => banner.show)
})
</script>

<template>
  <div class="profile-menu" v-if="authStore.user">
    <h4 class="mb-n3 pa-4">{{ $t('header.welcome') }} <span class="font-weight-regular">{{ authStore.user.firstName }}</span></h4>
    <perfect-scrollbar style="height: auto; max-height: 560px">
      
      <v-divider v-if="bannersToShow.length > 0" class="mt-2 mb-2"></v-divider>
      
      <div class="d-flex flex-column px-4 ga-2" v-if="bannersToShow.length > 0">
        <template v-for="(banner, index) in bannersToShow">
          <component
            :key="index"
            :is="banner.component"
            v-if="banner.show"
          />
        </template>
      </div>

      <v-divider class="mt-2 mb-0"></v-divider>

      <v-list density="compact">
        <v-list-item @click="onShowNotifications" color="secondary" rounded="0">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">mdi-bell-outline</v-icon>
            <span>
              {{ $t('header.notifications') }}
            </span>
            <v-badge
              v-if="notificationCount"
              color="warning"
              :content="notificationCount"
              inline
            />
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'user-account-settings'}" color="secondary" rounded="0">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">mdi-account-outline</v-icon>
            <span>{{ $t('header.account_settings') }}</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'plans-and-billing-settings'}" color="secondary" rounded="0">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">mdi-credit-card-outline</v-icon>
            <span>{{ $t('header.plans_and_billing') }}</span>
          </v-list-item-title>
        </v-list-item>
        <v-divider class="mt-1 mb-1"></v-divider>
        <v-list-item :to="{name: 'list-groups'}" color="secondary" rounded="0">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">
              mdi-view-list-outline
            </v-icon>
            <span>
              {{ $t('label.custom_groups') }}
            </span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="{name: 'import-links'}" color="secondary" rounded="0">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">
              mdi-link-variant
            </v-icon>
            <span>
              {{ $t('label.account_links') }}
            </span>
          </v-list-item-title>
        </v-list-item>
        <v-divider class="mt-1 mb-1"></v-divider>
        <v-list-item :to="{name: 'support'}" color="secondary" rounded="0">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon color="secondary" size="24">mdi-help-circle-outline</v-icon>
            <span>{{ $t('header.community') }}</span>
          </v-list-item-title>
        </v-list-item>
        <v-list-item color="secondary" rounded="0">
          <v-list-item-title class="text-body-1 d-flex justify-space-between align-center ga-2">
            <div class="d-flex ga-2 align-center">
              <v-icon color="secondary" size="24">mdi-brightness-4</v-icon>
              <span class="text-body-1">Dark Mode</span>
            </div>
            <v-switch
              @click.stop color="primary"
              v-model="isDarkTheme"
              class="text-body-1"
              density="compact"
              false-icon="mdi-white-balance-sunny"
              true-icon="mdi-moon-waning-crescent"
              :hide-details="true">
              <template v-slot:label>
              </template>
            </v-switch>
          </v-list-item-title>
        </v-list-item>
        <template v-if="true">
          <v-divider class="mt-2 mb-0"></v-divider>
          <v-list-item color="secondary" rounded="md">
            <v-list-item-title class="text-body-1 d-flex align-center ga-2 pt-2">
              <v-icon color="secondary" size="24">mdi-web</v-icon>
              <v-select
                label="Language"
                variant="plain"
                density="compact"
                v-model="$i18n.locale"
                :items="locales"
                item-text="title"
                item-value="value"
                class="text-body-1"
                :hide-details="true"
                @click.stop
              />
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-divider class="mt-2 mb-1"></v-divider>
        <v-list-item @click="authStore.logout()" color="secondary" rounded="0">
          <v-list-item-title class="text-body-1 d-flex align-center ga-2">
            <v-icon size="24" color="secondary">mdi-logout</v-icon>
            <span>{{ $t('header.logout') }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </perfect-scrollbar>
  </div>
</template>

<style lang="scss">
.profile-menu {
  label {
    font-size: 16px;
  }
}
</style>
