<script setup lang="ts">
import { computed } from 'vue';
import Investment from '../Investment.vue';

const props = defineProps({
  holding: null,
  showPortfolios: {
    type: Boolean,
    default: true
  }
});

const isCash = computed(() => {
  return props.holding.type === 'cash'
})

const isLoan = computed(() => {
  return props.holding.type === 'loan'
})

const route = computed(() => {
  if (isLoan.value) {
    return `/loan/${props.holding.id}`
  }
  return isCash.value ? `/account/${props.holding.id}` : `/investment/${props.holding.id}`
})

const code = computed(() => {
  return isCash.value || isLoan.value ? props.holding.name : (props.holding.code || props.holding.symbol)
})
</script>

<template>
  <template v-if="holding.id > 0">
    <Investment
      style="width: 100%;"
      :code="code"
      :logo="holding.logo ?? holding?.instrument?.logo"
      :route="route"
      :name="holding.name"
      :isCash="isCash"
      :isLoan="isLoan"
      :showPortfolios="showPortfolios"
      :holding="holding"
    />
  </template>
  <template v-else>
    <div class="d-flex justify-space-between" style="width: 100%;">
      <span class="text-truncate">{{ holding.name }}</span>
      <v-chip color="secondary" size="x-small" v-if="showPortfolios && holding.portfolios?.length > 1" class="d-none d-lg-flex">
        {{ $t('label.n_portfolios', holding.portfolios?.length, { count: holding.portfolios?.length })}}
      </v-chip>
    </div>
  </template>
</template>