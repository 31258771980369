import FullLayout from '@/layouts/full/FullLayout.vue';
import Layout from '@/views/settings/Layout.vue';
import Index from '@/views/settings/Index.vue';
import Subscribe from '@/views/settings/Subscribe.vue';
import PlansAndBillingTab from '@/views/settings/PlansAndBillingTab.vue';
import UserProfileTab from '@/views/settings/UserProfileTab.vue';
import InviteFriendsTab from '@/views/settings/InviteFriendsTab.vue';

const SettingsRoutes = {
  path: '/settings',
  meta: {
    requiresAuth: true,
    requiresTier: false,
    requiresTerms: false
  },
  component: FullLayout,
  children: [
    {
      name: 'settings-layout',
      path: '',
      component: Layout,
      children: [{
        name: 'settings',
        path: '',
        component: Index,
        children: [
          {
            name: 'user-account-settings',
            path: 'account',
            component: UserProfileTab,
            meta: {
              title: 'Account settings'
            }
          },
          {
            name: 'security',
            path: 'security',
            component: () => import('@/views/settings/SecurityTab.vue'),
            meta: {
              title: 'Account security'
            }
          },
          {
            name: 'permissions',
            path: 'permissions',
            component: () => import('@/views/settings/PermissionsTab.vue'),
            meta: {
              title: 'Permissions'
            }
          },
          {
            name: 'plans-and-billing-settings',
            path: 'billing',
            component: PlansAndBillingTab,
            meta: {
              title: 'Plans and billing'
            }
          },
          {
            name: 'invite-friends',
            path: 'invite',
            component: InviteFriendsTab,
            meta: {
              title: 'Invite friends'
            }
          },
          {
            name: 'import-links',
            path: 'links',
            component: () => import('@/views/settings/Links.vue'),
            meta: {
              title: 'Broker links'
            }
          },
        ]
      }, {
        name: 'subscribe',
        path: 'subscribe',
        component: Subscribe,
        meta: {
          parent: 'plans-and-billing-settings',
          title: 'Subscribe'
        }
      }]
    },
    {
      name: 'terms',
      path: 'terms',
      component: () => import('@/views/settings/Terms.vue')
    }
  ]
};

export default SettingsRoutes;
