<script setup lang="ts">
import ValueWithArrow from '@/components/shared/ValueWithArrow.vue';
import { currencyFormatter } from '@/const';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useReportStore } from '@/stores/reportStore';
import { watch, type Ref } from 'vue';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ValueChart from '@/components/ValueChart.vue';
import ButtonToggle from '@/components/inputs/ButtonToggle.vue';
import HelpIcon from '@/components/HelpIcon.vue';
import { usePerformanceFilter } from '@/filter.helper';
import HideBalancesButton from '@/components/HideBalancesButton.vue';
import AssetAllocation from './AssetAllocation.vue';
import Performance from './Performance.vue';
import FutureIncome from './FutureIncome.vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/authStore';
import SettingsSidebar from '@/components/shared/SettingsSidebar.vue';
import { whenever } from '@vueuse/core';
import { useLazyFetch } from '@/utils/reactivity.utils';
import DownloadButton from '@/components/shared/DownloadButton.vue';

const { t } = useI18n();
const portfolioStore = usePortfolioStore();
const reportStore = useReportStore();
const router = useRouter();
const route = useRoute();

const assetAllocationChart = ref<typeof AssetAllocation | null>(null);

const valueIs = ref('value');
const authStore = useAuthStore();

const { summaryPeriod, summaryRange, summaryGroupBy, summaryChartType } = usePerformanceFilter()
const portfolioId = computed(() => portfolioStore.selectedPortfolioId)

whenever(() => route.params.portfolioId, id => portfolioStore.select(Number(id)), { immediate: true });

whenever(() => portfolioStore.selectedPortfolio, portfolio => {
  if (portfolio.hasAccounts || portfolio.hasAccounts === undefined) return;
  router.push({ name: 'onboarding', params: { portfolioId: portfolio.id } });
}, { immediate: true })

const { data: summary, loading: isLoading } = useLazyFetch(async () => {
  if (!portfolioId) return {};
  return await portfolioStore.getSummary(
    portfolioStore.selectedPortfolioId,
    summaryPeriod.value,
    summaryRange.value,
    summaryGroupBy.value
  )
}, []);

const { data: netWorth, loading: isNetworthLoading } = useLazyFetch(async () => {
  if (!portfolioId) return [];
  return await reportStore.getNetworthReport(portfolioStore.selectedPortfolioId, {
    period: summaryPeriod.value,
    ...summaryRange.value,
    groupBy: summaryGroupBy.value,
  })
}, []);

const cards: Ref<Record<string, any>> = ref({
  assetAllocationChart: {
    cols: authStore.getUserPreference(`dashboard.assetAllocationChart.cols`, 6, portfolioId.value),
    order: authStore.getUserPreference(`dashboard.assetAllocationChart.order`, 0, portfolioId.value),
    key: 'assetAllocationChart',
  },
  performanceChart: {
    cols: authStore.getUserPreference(`dashboard.performanceChart.cols`, 6, portfolioId.value),
    order: authStore.getUserPreference(`dashboard.performanceChart.order`, 1, portfolioId.value),
    key: 'performanceChart',
  },
  futureIncome: {
    cols: authStore.getUserPreference(`dashboard.futureIncome.cols`, 12, portfolioId.value),
    order: authStore.getUserPreference(`dashboard.futureIncome.order`, 2, portfolioId.value),
    key: 'futureIncome',
  }
})

const order = (card: any, direction: number) => {
  const sortedList = Object.values(cards.value).sort((a, b) => a.order - b.order);
  const index = sortedList.indexOf(card);
  const targetIndex = index + direction;

  if (targetIndex < 0 || targetIndex >= sortedList.length) return;

  // Swap positions in the array
  [sortedList[index], sortedList[targetIndex]] = [sortedList[targetIndex], sortedList[index]];

  // Reassign order values based on new positions
  sortedList.forEach((c, i) => (c.order = i));
};

const reportCards = computed(() => {
  return [
    {
      component: AssetAllocation,
      refName: 'assetAllocationChart',
      ref: assetAllocationChart,
      cols: cards.value.assetAllocationChart.cols,
      order: cards.value.assetAllocationChart.order,
      props: {
        report: summary.value,
        isLoading: isLoading.value,
        title: t('label.asset_allocation'),
      }
    },
    {
      component: Performance,
      refName: 'performanceChart',
      cols: cards.value.performanceChart.cols,
      order: cards.value.performanceChart.order,
      props: {
        report: summary.value,
        isLoading: isLoading.value,
      }
    },
    {
      component: FutureIncome,
      refName: 'futureIncome',
      cols: cards.value.futureIncome.cols,
      order: cards.value.futureIncome.order,
      props: {
        isLoading: isLoading.value,
      }
    }
  ].sort((a, b) => a.order - b.order)
})

watch(cards, (value) => {
  Object.entries(value).forEach(([key, card]) => {
    authStore.setUserPreference(`dashboard.${key}.cols`, card.cols, portfolioId.value);
    authStore.setUserPreference(`dashboard.${key}.order`, card.order, portfolioId.value);
  });
}, { deep: true });
</script>

<template>
  <template v-if="portfolioId">
    <v-row class="mt-n5">
      <v-col cols="12" v-if="summary.isPartial">
        <PartialReportAlert />
      </v-col>
      <v-col cols="12" md="12">
        <div class="d-flex flex-row flex-md-row align-center justify-space-between">
          <div>
            <h1 style="font-size: 2.5em;letter-spacing:-0.03em;" class="font-weight-black text-darkprimary d-flex align-center">
              <span v-if="!isLoading" class="balance-lg">{{ currencyFormatter(summary?.value, summary?.currencyCode) }}</span>
              <span class="balance-lg" v-else><v-progress-circular size="24" indeterminate color="primary" class="mr-2" /></span>
              <HideBalancesButton />
            </h1>
            <div class="d-flex ga-1 text-darkprimary text-body-1 mt-n1 align-center">
              <ValueWithArrow :value="summary?.openAndClosedPositions?.totalReturn ?? 0" :arrow="true" :color-text="false" />
              <HelpIcon color="darkprimary" :help-text="$t('help.performance')" />
              <span class="balance">({{ currencyFormatter(summary?.openAndClosedPositions?.totalGain, summary?.currencyCode) }})</span>
            </div>
          </div>
          <div>
            <SettingsSidebar buttonClass="mt-n4">
              <PerformanceFilters
                v-model="summaryPeriod"
                v-model:groupByValue="summaryGroupBy"
                v-model:range="summaryRange"
                v-model:chartTypeValue="summaryChartType"
                :is-summary="true"
                :show-chart-type="true"
                narrow
              />
            </SettingsSidebar>
          </div>
        </div>
        <ValueChart
          :values="netWorth"
          :is-loading="isNetworthLoading"
          :currency-code="summary?.baseCurrencyCode"
          :value-key="valueIs"
          :chart-type="summaryChartType"
          :portfolio="portfolioStore.selectedPortfolio"
          :round="false"
        />
        <div class="mt-5"></div>
      </v-col>
      <v-col v-for="card in reportCards" :cols="12" :md="card.cols" class="d-flex align-stretch" :id="card.refName">
        <component
          :is="card.component"
          :ref="card.refName"
          v-bind="card.props"
        >
          <template #configuration>
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn data-html2canvas-ignore="true" class="pa-0 ml-n4 mr-n5" icon="mdi-dots-vertical" variant="plain" v-bind="props" :ripple="false" slim></v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <ButtonToggle
                    v-model="cards[card.refName].cols"
                    mode="icon"
                    density="compact"
                    :options="[{
                      title: 'mdi-size-m',
                      value: 6
                    }, {
                      title: 'mdi-size-xl',
                      value: 12
                    }]"
                    no-columns
                  />
                </v-list-item>
                <v-list-item>
                  <div class="d-flex ga-2">
                    <v-btn icon="mdi-menu-up-outline" tile variant="flat" @click="order(cards[card.refName], -1)"></v-btn>
                    <v-btn icon="mdi-menu-down-outline" tile variant="flat" @click="order(cards[card.refName], +1)"></v-btn>
                  </div>
                </v-list-item>
                <v-list-item>
                  <DownloadButton 
                    :element-id="card.refName"
                    :file-name="card.refName"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </component>
      </v-col>
  </v-row>
</template>
<div v-else>
  <v-row>
    <v-col cols="12">
      <v-progress-linear indeterminate color="secondary" />
    </v-col>
  </v-row>
</div>
</template>
