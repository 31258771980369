<script setup lang="ts">
import { relatedAccountUtils } from '@/utils/account.utils';
import { computed, ref, watch } from 'vue';
import Logo from '@/components/logos/Logo.vue';
import AccountForm from '@/views/account/AccountForm.vue';
import { useAccountStore } from '@/stores/accountStore';
import { currencyFormatter } from '@/const';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['update:modelValue', 'update:modelObject']);

const accountStore = useAccountStore();
const showCreateCashAccountModal = ref(false);
const obj = ref(null);
const balanceResponse = ref(null);
const { t } = useI18n();

const props = defineProps({
  modelValue: null,
  modelObject: null,
  disabled: {
    type: Boolean,
    default: false
  },
  fieldDesign: {
    type: Object,
    default: () => ({})
  },
  label: {
    type: String,
    default: () => ''
  },
  showCreate: {
    type: Boolean,
    default: () => true
  },
  currencyCode: {
    type: String,
    required: false
  },
  hint: {
    type: String,
    required: false
  },
  sourceAccount: {
    required: false
  },
  suggest: {
    required: false,
    type: Number,
  }
});

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const { relatedAccounts, isLoading: isRelatedAccountsLoading } = relatedAccountUtils(props.sourceAccount)


const valueObject = computed({
  get() {
    return props.modelObject || obj.value || relatedAccounts.value.find((account: any) => account.id === value.value || account.childAccounts?.find((child: any) => child.id === value.value));
  },
  set(val) {
    obj.value = val
    emit('update:modelObject', val)
    emit('update:modelValue', val?.id || value.value)
  }
});

watch(() => [props.modelValue, relatedAccounts], ([newValue]) => {
  if (newValue) {
    valueObject.value = relatedAccounts.value.find((account: any) => account.id === newValue);
  }
}, { immediate: true });

watch(() => [props.modelValue, props.currencyCode], async ([newValue, currencyCode]) => {
  if (newValue && currencyCode) {
    balanceResponse.value = await accountStore.getBalance(newValue, currencyCode)
  } else {
    balanceResponse.value = null;
  }
}, { immediate: true });

const onClear = () => {
  valueObject.value = null;
  value.value = null;
}

const createCashAccount = () => {
  showCreateCashAccountModal.value = true;
}

const onCashAccountCreated = (account: any) => {
  showCreateCashAccountModal.value = false;
  valueObject.value = account;
}

const computedHint = computed(() => {
  return false;
});

const helpText = computed(() => {
  return props.hint;
});

const balance = computed(() => {
  if (!props.currencyCode) {
    return undefined;
  }
  return balanceResponse.value?.balance;
});

const showBalance = computed(() => {
  return valueObject.value && balance.value !== undefined
});

const showSuggestion = computed(() => {
  return props.suggest && !valueObject.value;
});

const suggestedAccount = computed(() => {
  return relatedAccounts.value.find((account: any) => account.id === props.suggest || account.childAccounts?.find((child: any) => child.id === props.suggest));
});

const applySuggestion = () => {
  value.value = props.suggest;
};
</script>

<template>
  <v-autocomplete
    v-bind="(fieldDesign as any)"
    v-model="valueObject"
    :items="relatedAccounts.filter((account) => !account.isArchived)"
    item-title="name"
    item-value="id"
    :label="label || $t('label.account')"
    return-object
    clearable
    :disabled="disabled"
    @click:clear="onClear"
    :hint="computedHint || undefined"
  >
    <template v-slot:prepend-inner v-if="valueObject">
      <Logo avatar :broker="valueObject?.providerId" :name="valueObject?.name" />
    </template>
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :title="''">
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
        <template v-slot:prepend>
          <Logo avatar :broker="item.raw.providerId" :name="item.title" />
        </template>
      </v-list-item>
    </template>
    <template v-slot:append-inner>
      <v-progress-circular
        v-show="isRelatedAccountsLoading"
        indeterminate
        color="primary"
      />
    </template>
    <template v-slot:append-item v-if="showCreate">
      <v-divider />
      <div class="px-3 pt-3 pb-1">
        <v-btn @click="createCashAccount" color="secondary">
          <v-icon>mdi-plus</v-icon> {{ $t('label.create_account') }}
        </v-btn>
      </div>
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          {{ $t('label.no_accounts') }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:append v-if="helpText">
      <HoverHelpIcon size="30" slot="append" :helpText="helpText" />
    </template>
  </v-autocomplete>
  <div class="d-flex" v-if="showBalance">
    <div class="font-weight-light text-caption mt-1" style="line-height: 1.1;">
      {{ $t('label.balance') }}: {{ currencyFormatter(balance, currencyCode) }}
    </div>
  </div>
  <div class="d-flex" v-if="showSuggestion">
    <div @click="applySuggestion" class="font-weight-light text-caption mt-1 d-flex align-center ga-1" style="line-height: 1.1;">
      {{ $t('label.suggestion') }}:
      <div class="d-flex align-center ga-1">
        <Logo :size="16" avatar :broker="suggestedAccount?.providerId" :name="suggestedAccount?.name" />
        <a href="#" class="text-primary font-weight-medium">
          {{ suggestedAccount?.name }}
        </a>
      </div>
    </div>
  </div>
  <v-dialog :max-width="900" v-model="showCreateCashAccountModal" :scrollable="true">
    <AccountForm action="create" location="modal" @created="onCashAccountCreated" />
  </v-dialog>
</template>