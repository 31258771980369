<script setup lang="ts">
import { useAuthStore } from '@/stores/authStore';
import { computed } from 'vue';
import { ref } from 'vue';
import { SubscriptionTier } from '../../../backend/src/enums';
import { useI18n } from 'vue-i18n';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { currencyFormatter } from '@/const';
import { useTheme, useDisplay } from 'vuetify';
import ButtonSelect from './inputs/ButtonSelect.vue';

const emit = defineEmits([
  'update:isAnnual'
]);

const props = defineProps({
  variant: {
    type: String,
    default: 'full'
  },
  isAnnual: {
    type: Boolean,
    default: true
  }
});

const authStore = useAuthStore();
const theme = useTheme();
const display = useDisplay();

const isAnnual = computed({
  get() {
    return props.isAnnual
  },
  set(value) {
    emit('update:isAnnual', value)
  }
});

const isLoading = ref(false);
const me = computed(() => authStore.user);
const { t } = useI18n();
const isCompact = computed(() => props.variant === 'compact');

const pricingInfo = authStore.pricingInfo;

const discount = computed(() => {
  if (pricingInfo.data?.discount?.percentOff) {
    return t('subscription.discount_description_percentage_off_next_purchase', {
      discount: pricingInfo.data.discount.percentOff
    })
  } else if (pricingInfo.data?.discount?.amountOff) {
    return t('subscription.discount_description_amount_off_next_purchase', {
      discount: currencyFormatter(pricingInfo.data?.discount.amountOff, 'EUR', 2, 0)
    })
  }
});

const plans = computed(() => ([
  {
    tier: SubscriptionTier.Free,
    color: 'primary-lighten-5',
    colorLight: 'primary-lighten-5',
    button: {},
    active: false,
    title: t('plans.free.title'),
    price: 0,
    priceMonthly: 0,
    oldPrice: 0,
    items: [
      t('subscription.features.portfolios', 1, { count: 1 }),
      t('subscription.features.investments', 10, { count: 10 }),
      t('subscription.features.bank_connections', 0, { count: 0 }),
      t('subscription.features.custom_groups', 1, { count: 1 }),
      !isCompact ? t('subscription.features.reports_some') : undefined,
      !isCompact ? t('subscription.features.support_some') : undefined
    ],
    permission: [0, 1],
    isComingSoon: false,
    trial: false,
  },
  {
    tier: SubscriptionTier.Starter,
    color: 'primary-lighten-4',
    colorLight: 'primary-lighten-4',
    active: false,
    title: t('plans.starter.title'),
    price: 6,
    priceMonthly: 7,
    oldPrice: 0,
    items: [
      t('subscription.features.portfolios', 2, { count: 2 }),
      t('subscription.features.investments', 30, { count: 30 }),
      t('subscription.features.bank_connections', 4, { count: 4 }),
      t('subscription.features.custom_groups', 3, { count: 3 }),
      !isCompact.value ? t('subscription.features.reports_some') : undefined,
      !isCompact.value ? t('subscription.features.support_regular') : undefined
    ],
    permission: [0, 1, 2, 3],
    button: {},
    isComingSoon: false,
    trial: pricingInfo.data?.trialAllowed
  },
  {
    tier: SubscriptionTier.Investor,
    color: 'primary-lighten-2',
    colorLight: 'primary-lighten-3',
    active: true,
    title: t('plans.investor.title'),
    price: 12,
    priceMonthly: 14,
    oldPrice: 0,
    items: [
      t('subscription.features.portfolios', 4, { count: 4 }),
      t('subscription.features.investments_unlimited'),
      t('subscription.features.bank_connections', 8, { count: 8 }),
      t('subscription.features.custom_groups', 5, { count: 5 }),
      !isCompact.value ? t('subscription.features.reports_most') : undefined,
      !isCompact.value ? t('subscription.features.support_regular') : undefined,
      !isCompact.value ? t('subscription.features.consolidated_views') : undefined
    ],
    permission: [0, 1, 2, 3, 5],
    button: {},
    isComingSoon: false,
    isPopular: true,
    trial: pricingInfo.data?.trialAllowed
  },
  {
    tier: SubscriptionTier.Expert,
    color: 'blue-grey-darken-2',
    colorLight: theme.current.value.dark ? 'primary-lighten-1' : 'blue-grey-lighten-3',
    active: false,
    title: t('plans.expert.title'),
    items: [
      t('subscription.features.portfolios', 10, { count: 10 }),
      t('subscription.features.investments_unlimited'),
      t('subscription.features.bank_connections', 16, { count: 16 }),
      t('subscription.features.custom_groups', 10, { count: 10 }),
      !isCompact.value ? t('subscription.features.reports_all') : undefined,
      !isCompact.value ? t('subscription.features.support_priority') : undefined,
      !isCompact.value ? t('subscription.features.consolidated_views') : undefined
    ],
    price: 25,
    priceMonthly: 29,
    oldPrice: 0,
    permission: [0, 1, 2, 3, 5],
    button: {},
    isComingSoon: false,
    trial: pricingInfo.data?.trialAllowed
  }
].map((plan) => {
  const isActive = me.value.subscription.tier === plan.tier;
  let buttonText = t('subscription.order');

  if (isActive) {
    buttonText = t('subscription.active');
  } else if (plan.price <= 0) {
    buttonText = t('subscription.choose');
  } else if (plan.trial) {
    buttonText = t('subscription.try');
  }

  plan.button = {
    text: buttonText,
    disabled: isActive,
  }

  const config = pricingInfo.data?.pricing?.[plan.tier] || {}

  if (plan.tier !== SubscriptionTier.Free && config?.original) {
    const hasDiscount = config.original.monthlyPrice !== config.monthlyPrice
    if (hasDiscount) {
      plan.oldPrice = props.isAnnual ? config.original.annualPerMonthPrice : config.original.monthlyPrice;
    }
    plan.price = config.annualPerMonthPrice
    plan.priceMonthly = config.monthlyPrice
  }

  plan.colorLight = isMobile.value ? 'surface' : plan.colorLight

  return plan;
})));

const upgrade = (plan: any) => {
  isLoading.value = true;
  fetchWrapper.post(`/api/v1/stripe/checkout?plan=${plan.tier}&annual=${props.isAnnual}&code=${pricingInfo.data.discount?.promoCode ?? ''}`)
    .then((response) => {
      if (response.url) {
        document.location.href = response.url;
      }
    })
    .catch(async (err) => {
      if (err?.code === 'invalid_coupon') {
        authStore.setPromoCode(null)
        await pricingInfo.refresh()
      }
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const isMobile = computed(() => display.xs.value);
const itemProps = computed(() => {
  if (isMobile.value) {
    return {
      
    }
  } else {
    return {
      cols: 12,
      sm: 6,
      md: 3,
      class: 'd-flex align-stretch'
    }
  }
});

const tier = ref(0);

</script>

<template>
  <v-btn-toggle
    v-if="isMobile"
    v-model="tier"
    color="deep-purple-accent-3"
    rounded="md"
    group
    mandatory
    class="d-flex justify-center mb-4 elevation-2"
  >
    <v-btn v-for="(o,i) in plans" :value="i" :key="i" class="flex-grow-1">
      {{ o.title }}
    </v-btn>
  </v-btn-toggle>

  <component :is="!isMobile ? 'v-row' : 'v-carousel'" v-model="tier" height="auto" hide-delimiters :show-arrows="false" style="overflow: visible;">
    <component :is="!isMobile ? 'v-col' : 'v-carousel-item'" v-for="(plan, index) in plans" :key="index" v-bind="itemProps">
      <div class="pt-3"></div>
      <DisplayCard :bg='`bg-${plan.colorLight}`' :class="{popular: plan.isPopular, 'is-mobile': isMobile}" :show-divider="false" style="overflow:hidden;">
        <v-chip class="popular-label" color="green" v-if="plan.isPopular">
          {{ $t('subscription.popular') }}
        </v-chip>
        <v-card-text class="text-center d-flex flex-column stretch justify-space-between pb-2" :class="{'px-2': isCompact}" style="height:100%;overflow: hidden;">
          <div>
            <div :style="`min-height:${isMobile ? '1' : (discount ? '110' : '70')}px;`">
              <h1 class="PlanTitle">{{ plan.title }}</h1>
              <div v-if="discount && plan.tier !== SubscriptionTier.Free" class="pt-4 pb-4">
                <v-chip variant="flat" color="amber-lighten-2" class="font-weight-medium" density="compact">
                  <v-icon class="mr-1" >mdi-sale</v-icon>
                  {{ discount }}
                </v-chip>
              </div>
            </div>
            <div :style="`min-height:${isCompact ? '100' : '120'}px;`" v-if="!isMobile">
              <div class="d-flex flex-column align-center">
                <h2 class="PriceFont">
                  <span v-if="plan.oldPrice" class="text-h3 text-decoration-line-through text-medium-emphasis">{{ currencyFormatter(plan.oldPrice, 'EUR', 2, 0) }}</span>
                  {{ currencyFormatter(isAnnual ? plan.price : plan.priceMonthly, 'EUR', 2, 0) }}
                </h2>
              </div>
              <div class="text-center text-caption mb-4 pl-1">
                <template v-if="isAnnual && plan.tier !== SubscriptionTier.Free">
                  <div>{{ $t('subscription.paid_monthly_monthly') }}</div>
                  <div class="text-medium-emphasis">{{ currencyFormatter(plan.price * 12, 'EUR', 2, 0) }} / {{ $t('subscription.per_year')}}</div>
                </template>
                <template v-if="!isAnnual && plan.tier !== SubscriptionTier.Free">
                  {{ $t('subscription.paid_monthly') }}
                </template>
                <template v-else-if="isAnnual && plan.price > 0">
                 
                </template>
                <template v-else-if="plan.tier === SubscriptionTier.Free">
                  <div>{{ $t('subscription.free_forever') }}</div>
                </template>
              </div>
            </div>
            <v-btn
              v-if="!isMobile"
              variant="flat"
              :disabled="plan.button.disabled || isLoading"
              :color="`blue`"
              @click="upgrade(plan)"
              :loading="isLoading"
              size="large"
              style="min-width: 150px;"
              class="mt-4">
                <span class="text-uppercase">{{ plan.button.text }}</span>
            </v-btn>
            <div class="text-caption mt-3" v-if="plan.trial">
              <strong>
                {{ $t('subscription.trial', {
                  count: pricingInfo.data?.trialDays,
                }) }}
              </strong> {{ $t('subscription.trial_description') }}
            </div>
            <div class="text-caption mt-3" v-else-if="pricingInfo.data?.trialAllowed">
              <br />
            </div>
            <v-divider v-if="!isMobile" :class="`mt-5 ml-n5 mr-n5 bg-${plan.color}`"></v-divider>
            <v-list class="text-left" :class="`bg-${plan.colorLight}`">
              <v-list-item
                v-for="(item, index) in plan.items.filter(item => !!item)"
                :key="index"
                class="d-flex align-center px-0 font-weight-medium"
                style="min-height: auto;"
                density="compact"
              >
                <v-icon :size="isCompact ? 16 : 24">mdi-check</v-icon>
                {{ item }}
              </v-list-item>
            </v-list>
          </div>
          <div v-if="isMobile">
            <ButtonSelect
              v-if="plan.tier !== SubscriptionTier.Free"
              class="mb-8 mt-4"
              v-model="isAnnual"
              selected-color="success"
              :options="[{
                value: true,
                period: `1 ${$t('label.year')}`,
                middle: `${currencyFormatter(plan.price, 'EUR', 2, 0)} / ${$t('label.month')}`,
                price: `${currencyFormatter(plan.price * 12, 'EUR', 2, 0)} / ${$t('label.year')}`,
                chip: $t('subscription.save')
              }, {
                value: false,
                period: `1 ${$t('label.month')}`,
                price: `${currencyFormatter(plan.priceMonthly, 'EUR', 2, 0)} / ${$t('label.month')}`,
              }]"
            >
              <template #default="{option}">
                <div class="d-flex justify-space-between w-100 text-h4 align-center">
                  <span>{{ option.period }}</span>
                  <span class="text-caption">{{ option.middle }}</span>
                  <span>{{ option.price }}</span>
                </div>
              </template>
            </ButtonSelect>

            <v-btn
              variant="flat"
              block
              :disabled="plan.button.disabled || isLoading"
              :color="`blue`"
              @click="upgrade(plan)"
              :loading="isLoading"
              size="large"
              class="mt-4 mb-6">
                <span class="text-uppercase">{{ plan.button.text }}</span>
            </v-btn>
          </div>
        </v-card-text>
      </DisplayCard>
    </component>
  </component>
</template>

<style scoped lang="scss">
.PlanTitle {
  position: relative;
  font-size: 1.8rem;
}
.PriceFont {
  font-size: 3.5rem;
  line-height: 3rem;
  letter-spacing: -0.3rem;
}
.withBorder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.circleIcon {
  background-color: rgb(var(--v-theme-lightprimary));
  width: 80px;
  height: 80px;
  margin: 0 auto;
  line-height: 80px;
  color: rgb(var(--v-theme-primary));
  border-radius: 100%;
  margin-bottom: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.popular {
  background: linear-gradient(rgb(var(--v-theme-primary-lighten-3)), rgb(var(--v-theme-primary-lighten-3))) padding-box, linear-gradient(to right, #3699ff, #8950fc) border-box !important;
  border: 2px solid transparent !important;
  overflow: visible !important;

  &.is-mobile {
    background: linear-gradient(rgb(var(--v-theme-surface)), rgb(var(--v-theme-surface))) padding-box, linear-gradient(to right, #3699ff, #8950fc) border-box !important;
  }
}

.popular-label {
  background: linear-gradient(to right, #3699ff, #8950fc) padding-box!important;
  color: #fff !important;
  font-weight: 600;
  font-size: 12px;
  border-radius: 5px;
  height: auto;
  padding: 5px;
  position: absolute;
  top: -14px;
  right: 5%;
}

:deep(.v-carousel-item>.v-img) {
  overflow: visible !important;
}
</style>
